import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//COMPONENTS
import FullGreenButton from '../global/FullGreenButton';

//REDUX SELECTORS
import { streetParkingCostAllTimeSelector } from './state/streetParkingCostAllTimeSelector';
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'
import { torchButtonSelector } from '../torchButton/state/torchButtonSelector';
import { lvzInfoSelector } from '../detailsPanel/state/lvzInfoSelector'
import { aroundMeModeSelector } from "../global/state/aroundMeModeSelector";

//REDUX ACTIONS
import { toggleItineraryMode } from '../global/state/itineraryModeAction';
import { hideDetailPanelContainer } from '../detailsPanel/state/displayDetailsPanelContainerAction';
import { displayTorchButton } from '../torchButton/state/torchButtonAction';
import { toggleExpandedDetailPanel } from '../detailsPanel/state/detailsPanelExpandedAction';
import { toggleResearchMode } from '../global/state/researchModeAction'
import { hideCocoSearchMarker } from '../markers/state/cocoSearchMarkerAction'
import { resetPathToDestination } from '../markers/state/directionToDestinationAction'
import { toggleInteractiveBox } from '../cocosupporter/state/displayInteractiveBoxAction';
import { resetSparkedOnStreetSuccess } from '../itinerary/state/displaySparkedOnStreetSuccessAction';

//UTILS
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//STYLE
import './OnstreetCard.css'

function OnstreetCard() {

    const dispatch = useDispatch()

    const streetParkingCostAllTime = useSelector(streetParkingCostAllTimeSelector)
    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)
    const torchButton = useSelector(torchButtonSelector)
    const lvzInfo = useSelector(lvzInfoSelector)
    const aroundMeMode = useSelector(aroundMeModeSelector)


    const [isAroundMeModeActive, setIsAroundMeModeActive] = useState(aroundMeMode)
    const [isLvzAroundResearch, setIsLvzAroundResearch] = useState(lvzInfo.lvzExists)

    //to launch itinerary mode
    function displayTorchItinerary(){
        dispatch(toggleInteractiveBox({value: true, category: "torch", messageType: "loading"}))
        dispatch(displayTorchButton(false))
        dispatch(toggleItineraryMode(true))
        dispatch(hideDetailPanelContainer())
        dispatch(toggleExpandedDetailPanel(false))
        dispatch(toggleResearchMode({value: false}))
        dispatch(hideCocoSearchMarker())
        dispatch(resetPathToDestination())
        dispatch(resetSparkedOnStreetSuccess())
    }

    let positionClassName = "infoCirclePositionImgDestination"
    let positionCaption = "Autour de votre destination :"
    // to update the picto next to "Autour de" when aroundMeModeActive is changing
    if(isAroundMeModeActive){
        positionClassName = "infoCirclePosition"
        positionCaption = "Autour de votre position :"
    }

    return (
        <div id="firstRR" className="firstCard" onClick={()=> addActionToDbBasedOnElement("firstRR", trackingIdAndAnonymousLocal, trackingSessionId)}>
            <div className="card-image"><img src="./images/parkingFeatures/parkingspot-white-right.png" alt=""/>DANS LA RUE</div>
            <div style={{margin: "0px 5px 0px 5px"}}>
                {isLvzAroundResearch ? 
                    <div className='flex-space-around-inline' style={{margin: "5px"}}>
                        <p className='onstreet-card-position-caption'>{positionCaption}</p>
                        <div className={positionClassName} style={{margin: "0px"}}></div>
                    </div>
                    :<FullGreenButton
                        text = "Chercher une place"
                        imgSrc = "images/itinerary/flashlight-white.png" 
                        imgAlt = "logo torch" 
                        imgSize = "15px"
                        isDisabled={!torchButton}
                        onClickFunction={()=> {displayTorchItinerary(); addActionToDbBasedOnElement("revealSlotsButton", trackingIdAndAnonymousLocal, trackingSessionId)}}
                    />
                }
            </div>
            <div className="flex-center-column" style={{marginTop: "0px", marginBottom: "10px"}}>
                <div className="grid-container-parking-card">
                    <div className="grid-item-parking-card">
                        <span className="card-time">1h</span>
                    </div>
                    <div className="grid-item-parking-card">
                        <span className="euroPrice detailsPanelText">{streetParkingCostAllTime.streetParkingCostAllTime ? streetParkingCostAllTime.streetParkingCostAllTime["60"] : "-"}€</span>
                    </div>
                    <div className="grid-item-parking-card">
                        <span className="card-time">3h</span>
                    </div>
                    <div className="grid-item-parking-card">
                        <span className="euroPrice detailsPanelText">{streetParkingCostAllTime.streetParkingCostAllTime ? streetParkingCostAllTime.streetParkingCostAllTime["180"] : "-"}€</span>
                    </div>
                    <div className="grid-item-parking-card">
                        <span className="card-time">6h</span>
                    </div>
                    <div className="grid-item-parking-card">
                        <span className="euroPrice detailsPanelText">{streetParkingCostAllTime.streetParkingCostAllTime ? streetParkingCostAllTime.streetParkingCostAllTime["360"] : "-"}€</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OnstreetCard
