import { UPDATE_ITINERARY_PATH, RESET_ITINERARY_PATH, DONE_ITINERARY_PATH} from "./itineraryPathReducer"

export const updateItineraryPath = (array) => ({
    type: UPDATE_ITINERARY_PATH,
    payload: array
})

export const resetItineraryPath = () => ({
    type: RESET_ITINERARY_PATH,
})

export const doneLoadingItineraryPath = () => ({
    type: DONE_ITINERARY_PATH,
})