import React, {useRef, useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Marker, OverlayView, Polygon } from '@react-google-maps/api'


//COMPONENTS
import WalkingCircleMarker from '../markers/WalkingCircleMarker';
import TorchMarkerSinglePolyline from './TorchMarkerSinglePolyline';


//REDUX SELECTORS
import { aroundMeModeSelector } from '../global/state/aroundMeModeSelector';
import { userLocationSelector } from '../markers/state/userLocationSelector';
import { torchJsonSelector } from './state/torchJsonSelector';
import { lastTorchGeolocationSelector } from './state/lastTorchGeolocationSelector'


//REDUX ACTIONS
import { updateNbOfSlotsPassed } from '../itinerary/state/nbOfSlotsPassedAction';
import { updatePathToDestination } from '../markers/state/directionToDestinationAction';
import { toggleDestinationNotReachYet } from './state/displayDestinationNotReachYetAction';
import { updateItineraryOverviewData } from '../itinerary/state/displayItineraryOverviewAction';
import { updateLastTorch } from './state/lastTorchGeolocationAction';
import { updateItineraryPath, doneLoadingItineraryPath } from './state/itineraryPathAction';
import { updateActualItinerarySegment } from './state/actualItineraryPathSegmentAction'
import { resetItineraryOverviewData } from './state/displayItineraryOverviewAction';
import { toggleTorchErrorPopup } from '../popup/state/displayTorchErrorPopupAction'


//UTILS
import { drawArc , rotateImageCSSToAdd, updateOngoingKeyPoints} from '../global/ItineraryUtils'
import { calculateOptimalItinerary , getClassicRouting} from '../api/itineraryApi'
import { getDistance, geocodeLatLng } from '../global/MapUtils'


function TorchMarkerItinerary(props) {

    const dispatch = useDispatch()

    let torchAngleSize = 90; // 90° sector to show with torch
    let radiusFive = 300
    let image = {
        url: "images/markers/FreedSpot-mkr.png",
        scaledSize: new window.google.maps.Size(35, 45), // scaled size
    };
    let torchPolygonOptions = {
        strokeColor: "white",
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: "#1faa00",
        fillOpacity: 0.35,
    }
    let currentPolylineItinerary
    let currentPolylineSlotsCount
    let totalNbOfSlotsOnPolyline
    let optimalItineraryDurationMaxSeenSpots
    let ongoingKeyPoints = {
        "KP0": "",
        "KP1": "",
        "KP2": "",
        "startIndex": 0,
    }

    const lastTorchMore500mGeolocation = useRef(null)
    const destinationRef = useRef(null)
    const userLocationRef = useRef(null)
    const centerResearchMarker = useRef(null)
    const mountedRef = useRef(false) //to know is component still exist
    const aroundMeModeAtStart = useRef(null) //to store the aroundMeMode status of the position from where user launched the itinerary


    const aroundMeMode = useSelector(aroundMeModeSelector)
    const userLocation = useSelector(userLocationSelector)
    const torchJson = useSelector(torchJsonSelector)
    const lastTorchGeolocation = useSelector(lastTorchGeolocationSelector)


    const [torchMarkerCenter, setTorchMarkerCenter] = useState({lat: 0, lng: 0})
    const [displayWalkingCircle, setDisplayWalkingCircle] = useState(false)
    const [displayRevealSlotsPolygon, setDisplayRevealSlotsPolygon] = useState(true)
    const [torchStyle, setTorchStyle] = useState({})
    const [torchPath, setTorchPath] = useState([])


    function revealSlotsHere(loc = "default"){
        
        let currentCenter = props.map.getCenter().toJSON()
        props.map.setZoom(16)
        
        if(loc !== "default"){
            props.map.panTo(loc);
            setTorchMarkerCenter(loc)
            lastTorchMore500mGeolocation.current = loc;
            dispatch(updateLastTorch(loc))
        } else {
            if(aroundMeMode || aroundMeModeAtStart.current){
                props.map.panTo(userLocation)
                setTorchMarkerCenter(userLocation)
                dispatch(updateLastTorch(userLocation))
            } else {
                props.map.panTo(currentCenter)
                lastTorchMore500mGeolocation.current = currentCenter
                setTorchMarkerCenter(currentCenter)
                setDisplayWalkingCircle(true)
                dispatch(updateLastTorch(currentCenter))
            }
        }

        //calculate optimal Itinerary loader and interactive message
        launchOptimalItinerary(loc);
    }


    function addRevealSlotsPolygon(){
        let bestAngle = torchJson['best_torch']['angle'];
        let currentCenter = props.map.getCenter();
        
        //to get the polygon path
        let arcPts = drawArc(currentCenter, bestAngle, bestAngle + torchAngleSize, radiusFive);
        setTorchPath(arcPts)
        
        //rotate torch image
        setTorchStyle({
            width: "45px",
            transform: "translate(-50%, -50%) " + rotateImageCSSToAdd(bestAngle)
        })
    }


    /**
     * Simply launch calculation of optimal itinerary loop
     * with torch and showOptimalItineraryOnMap if successful
     *
     */
     function launchOptimalItinerary(loc = "default"){
        return new Promise(async (resolve) => {

            let option;

            if(loc !== "default"){
                centerResearchMarker.current = loc;
                option = "goToDestinationFirst";
                destinationRef.current = loc
                userLocationRef.current = userLocation
            }else{
                if(aroundMeMode || aroundMeModeAtStart.current){
                    centerResearchMarker.current = userLocation;
                    option = "atDestinationAlready";
                }else{
                    centerResearchMarker.current = props.map.getCenter().toJSON();
                    option = "goToDestinationFirst";
                    destinationRef.current = centerResearchMarker.current
                    userLocationRef.current = userLocation
                }
            }

            await calculateOptimalItinerary( centerResearchMarker.current , option)
            .then(function(response){
                if(response['results'] != null){
                    currentPolylineItinerary = response['results']['max_seen_spots']['itinerary']
                    currentPolylineSlotsCount = response['results']['max_seen_spots']['nb_spots_per_segment']
                    totalNbOfSlotsOnPolyline = response['results']['max_seen_spots']['nb_spots']
                    optimalItineraryDurationMaxSeenSpots = response['results']['max_seen_spots']['duration']
                    dispatch(updateActualItinerarySegment(currentPolylineItinerary.length))
                    updateOngoingKeyPoints(0, currentPolylineItinerary, ongoingKeyPoints)
                    //remove Torch and show itinerary
                    setTimeout(function(){
                        setDisplayRevealSlotsPolygon(false)
                    }, 4 * 1000);
                    setTimeout(function(){
                        showOptimalItineraryOnMap(option, currentPolylineItinerary)
                    }, 4*1000)
                }else{
                    dispatch(toggleTorchErrorPopup(true))
                }
            });
        })
    }


    /**
     * Draw the itinerary
     *
     * @param {string} option - tracks if atDestinationAlready or needs to goToDestinationFirst
     * @returns {string} - confirmation "done" or error
     *
     */
    function showOptimalItineraryOnMap(option, currentPolylineItinerary){
        let bounds = new window.google.maps.LatLngBounds()
        currentPolylineItinerary.forEach((item, x) => {
            drawWithDelay(x, bounds, option);
        })
    }


    function drawWithDelay(x, bounds, option){
        setTimeout(async function(){
            //to draw polyline only if the component is still mounted
            if(mountedRef.current){
                if(x < currentPolylineItinerary.length - 1 ){
                    var subPolyline = [];
                    subPolyline.push(currentPolylineItinerary[x])
                    subPolyline.push(currentPolylineItinerary[x+1])
                    // adjust bounds
                    bounds.extend(currentPolylineItinerary[x])
                    bounds.extend(currentPolylineItinerary[x+1])
                    dispatch(updateItineraryPath([currentPolylineItinerary[x]]))
                    calculateNbOfSlotsPassed(currentPolylineSlotsCount, x)
                }
                x = x+1;
                if(x === currentPolylineItinerary.length){
                    props.map.fitBounds(bounds)
                    dispatch(updateNbOfSlotsPassed("GO"))
                    dispatch(doneLoadingItineraryPath())
                    setTimeout(function(){
                        //launch step by step guide
                        if(option === "atDestinationAlready"){
                            setTimeout(function(){
                                dispatch(updateItineraryOverviewData({
                                    value: true,
                                    nbTotalOfSlots: totalNbOfSlotsOnPolyline,
                                    estimatedTime: Math.round(optimalItineraryDurationMaxSeenSpots/60),
                                    centerResearchMarker: centerResearchMarker.current
                                }))
                            }, 500);
                        }else{
                            setTimeout(async function(){
                                gotoDestinationThenItinerary()
                            }, 600);
                        }
                    }, 2000)
                }
            }
        }, 85 * x)
    }


    /**
     * Updates front box with nb of slots passed on itinerary based on polyline
     *
     * @param {Array} polylineSlotsCount - array of count of slots per segment
     * @param {int} lastIndex - last index passed
     *
     */
    function calculateNbOfSlotsPassed(polylineSlotsCount, lastIndex){
        var count = 0;
        var x = 0;
        while( x <= lastIndex - 1 ){
            count = count + polylineSlotsCount[x]
            x = x + 1
            if(x === lastIndex){
                dispatch(updateNbOfSlotsPassed(count))
            }
        }
    }


    async function gotoDestinationThenItinerary(){
        if(mountedRef.current){
            let distanceToDestination = getDistance(userLocationRef.current, destinationRef.current);
            let resp = await getClassicRouting(userLocationRef.current, destinationRef.current)
            if( resp.results !== -1){
                //to draw a polyline from userLocation to destination
                dispatch(updatePathToDestination({path: resp.results.itinerary, style: "red"}))
                //to reset slotsPassed on itineraryPanel
                dispatch(updateNbOfSlotsPassed(0))
                if(distanceToDestination >= 500){
                    putInLsLastTorchMore500mGeolocation();
                    dispatch(toggleDestinationNotReachYet(true))
                }
            } else{
                putInLsLastTorchMore500mGeolocation();
                dispatch(toggleDestinationNotReachYet(true))
            }
        }
    }


    /**
     * Put in local storage the geolocation, address and  date of the last time the user used the torch more than 500m away from him
     */
    async function putInLsLastTorchMore500mGeolocation(){
        await geocodeLatLng(lastTorchMore500mGeolocation.current)
        .then(function(response){
            let workingData = JSON.parse(localStorage.getItem('workingData'));
            let lastTorchData = {
                "torchAddress" : lastTorchMore500mGeolocation.current,
                "torchAddressName" : response.fulladdress,
                "torchTime" : Date.now(),
                "alreadyDisplayed" : 0
            }
            if(workingData != null){
                workingData['lastTorchData'] = lastTorchData
                localStorage.setItem('workingData', JSON.stringify(workingData));
            } else {
                localStorage.setItem('workingData', JSON.stringify({
                    lastTorchData: lastTorchData
                }));
            }
        });
    }


    useEffect(() => {
        mountedRef.current = true
        aroundMeModeAtStart.current = aroundMeMode
        revealSlotsHere()
        // light up the right part as an overlay on the map
        addRevealSlotsPolygon()
        return () => {
            mountedRef.current = false;
            dispatch(resetItineraryOverviewData())
            dispatch(toggleDestinationNotReachYet(false))
        };
    }, [])

    useEffect(() => {
        //used to relaunch itinerary each time displayItinerary is changing (each time user click on "RECALCULER L'ITINERAIRE")
        if(props.displayItinerary !== 0){
            if(aroundMeModeAtStart.current){
                revealSlotsHere()
            } else {
                revealSlotsHere(lastTorchGeolocation)
            }
            setDisplayRevealSlotsPolygon(true)
            // light up the right part as an overlay on the map
            addRevealSlotsPolygon()
        }
    }, [props.displayItinerary])


    return (
        <div>
            {displayWalkingCircle ?
                <WalkingCircleMarker
                        info={{
                            position: torchMarkerCenter,
                            label: "5min",
                            radius: 300
                        }}
                />:null
            }
            <Marker 
                position={torchMarkerCenter}
                icon={image}
                zIndex={20}
            ></Marker>
            {displayRevealSlotsPolygon ?
                <>
                    <OverlayView 
                        position={torchMarkerCenter}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                        <img style={torchStyle}src="images/itinerary/flashlight-green.png" alt="torch"/>
                    </OverlayView> 
                    <Polygon
                        paths={torchPath}
                        options={torchPolygonOptions}
                    />
                </>:null
            }
            
            <TorchMarkerSinglePolyline/> 
            
        </div>
    )
}

export default TorchMarkerItinerary
