import { TOGGLE_SPARKED_ONSTREET_SUCCESS, RESET_SPARKED_ONSTREET_SUCCESS } from "./displaySparkedOnStreetSuccessReducer"

export const toggleSparkedOnStreetSuccess = (data) => ({
    type: TOGGLE_SPARKED_ONSTREET_SUCCESS,
    payload: data
})

export const resetSparkedOnStreetSuccess = () => ({
    type: RESET_SPARKED_ONSTREET_SUCCESS,
})

