
const initialState = 0

export const UPDATE_FRONT_STYLE_EVH = 'UPDATE_FRONT_STYLE_EVH'

export function frontStyleEvhReducer (state = initialState, action) {
    switch (action.type){
        case UPDATE_FRONT_STYLE_EVH:
            return action.payload
        default: return state
    }
}