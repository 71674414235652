import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

//REDUX SELECTORS
import { displayCityInformationsDetailsPanelSelector } from '../detailsPanel/state/displayCityInformationsDetailsPanelSelector';
import { showTrafficSelector } from '../map/state/showTrafficSelector'
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'

//REDUX ACTIONS
import { toggleCityInformationDetailsPanel } from '../detailsPanel/state/displayCityInformationsDetailsPanelAction'
import { toggleTrafficOnMap } from '../map/state/showTrafficAction'

//UTILS
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//STYLE
import './NavigationSideBar.css'
import {LayersOutlined, InfoOutlined, EuroOutlined} from '@material-ui/icons';

function NavigationSideBar() {

    const displayCityInformationsDetailsPanel = useSelector(displayCityInformationsDetailsPanelSelector)
    const showTraffic = useSelector(showTrafficSelector)
    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)
    const config = useSelector((state) => state.config)

    const dispatch = useDispatch()

    function togglePanel(){
        dispatch(toggleCityInformationDetailsPanel())
    }

    function toogleTraffic(){
        dispatch(toggleTrafficOnMap())
    }

    return (
        <div className="navigation-bar">
            {config.info === true ?
                <button className="hover-button button-filter" onClick={()=> {togglePanel(); addActionToDbBasedOnElement("infoStreetParking", trackingIdAndAnonymousLocal, trackingSessionId)}}>
                    <InfoOutlined className="button-filter-icon" style={{color: displayCityInformationsDetailsPanel ? "var(--cocoparksGreen)" : "grey"}}/>
                </button>
            :null}
            <button className="hover-button button-filter" onClick={()=> {toogleTraffic(); addActionToDbBasedOnElement("traffic_layer", trackingIdAndAnonymousLocal, trackingSessionId)}}>
                <LayersOutlined className="button-filter-icon"  style={{color: showTraffic ? "var(--cocoparksGreen)" : "grey"}}/>
            </button>
        </div>
    )
}

export default NavigationSideBar
