import { handleResponse } from "./utilsApi"
import * as url from './routes'

/**
  * Create a new User Tracking Session
  * @param {boolean} isAnonymousLocal - True : the user is anonymous / False : the user is authentified
  * @param {number} id - User id
  * @param {String} appVersion - app version used by the user 
  * @returns {Promise}
  */
export function createUserSessionInDB(isAnonymousLocal, id, appVersion){
    let dataToSend = {"isAnonymousLocal":isAnonymousLocal, "id": id, "app_version": appVersion}
    let response = fetch(url.createUserSession,
    {
        method: 'post',
        body: JSON.stringify(dataToSend)
    })
    .then(handleResponse)
    .then(data => {
        return data
    })
    .catch((error) => console.log('error: ' + error))
    return response
}


/**
  * Add the data made by the user to the database. (User Tracking)
  * @param {string} type - Type of the data
  * @param {boolean} isAnonymousLocal - True : the user is anonymous / False : the user is authentified
  * @param {number} id - User id
  * @param {string} sessionName - User session name
  * @param {(Object|string)} value - Value of the data made by the user
  * @returns {Promise}
  */
export function addDataToUserSessionInDB(type,isAnonymousLocal, id, sessionName, value){
    let dataToSend = {"type":type, "isAnonymousLocal":isAnonymousLocal, "id": id, "sessionName":sessionName, "value":value }
    let response = fetch(url.addActionToUserSession,
    {
        method: 'post',
        body: JSON.stringify(dataToSend)
    })
    .then(handleResponse)
    .then(data => {
        return data
    })
    .catch((error) => console.log('error: ' + error))
    return response
}

/**
  * Add the User document app_installed:true if the user downloaded the app (User Tracking)
  * @param {number} id - User id
  * @param {boolean} isAnonymousLocal - True : the user is anonymous / False : the user is authentified
  */
export function addNewAppInstallInDbUser(id, isAnonymousLocal){
    let dataToSend = {
        "id":id, 
        "isAnonymousLocal":isAnonymousLocal
    }
    let response = fetch(url.newAppInstallUser,
    {
        method: 'post',
        body: JSON.stringify(dataToSend)
    })
    .then(handleResponse)
    .then(data => {
        return data
    })
    .catch((error) => console.log('error: ' + error))
    return response
}

/**
  * Add to the App_installed collection the list of users who downloaded the app (User Tracking)
  * @param {number} id - User id
  * @returns {Promise}
  */
export function addNewAppInstallInDB(id){
    let dataToSend = {
        "id":id, 
    }
    let response = fetch(url.newAppInstall,
    {
        method: 'post',
        body: JSON.stringify(dataToSend)
    })
    .then(handleResponse)
    .then(data => {
        return data
    })
    .catch((error) => console.log('error: ' + error))
    return response
}
