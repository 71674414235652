import { OverlayView } from '@react-google-maps/api'
import React from 'react'
import { useSelector } from 'react-redux'

//REDUX SELECTORS
import { frontStylePmrSelector } from './state/frontStylePmrSelector';
import { pmrMarkerDataSelector } from './state/pmrMarkerDataSelector';
import { itineraryModeSelector } from '../global/state/itineraryModeSelector';


//JSON
import jsonZoomUIStatuses from '../zoom/jsonZoomUIStatuses.json'

export const PmrMarker = React.memo((props) => {

    let displayMarker = "hidden"
    
    const frontStylePmr = useSelector(frontStylePmrSelector)
    const pmrMarkerData = useSelector(pmrMarkerDataSelector)
    const itineraryMode = useSelector(itineraryModeSelector)

    
    //allocated the right image path and className to marker based on jsonZoomUIStatuses.json
    let imgPath = jsonZoomUIStatuses["pmr"].icons[frontStylePmr].imgSrc
    let className = jsonZoomUIStatuses["pmr"].icons[frontStylePmr].className


    //to display pmr markers only if itineraryMode (torch) isn't active
    if(!itineraryMode){
        //to display marker if there are in the surroundingCityZone
        if(pmrMarkerData.surroundingCityZone.find(id => id === props.info.zoneId)){
            displayMarker = "visible"
        }
    }


    return (
        <div>
            <OverlayView 
                position={{lat : props.location.lat, lng : props.location.lng}}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={(width, height) => ({
                    x: -(width / 2),
                    y: -(height / 2)
                })}
            >
                <div>
                    <img style={{visibility: displayMarker}} className={className} src={imgPath} alt="Logo"/>
                </div>                    
            </OverlayView> 
        </div>
    )
})

export default PmrMarker
