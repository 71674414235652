import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//COMPONENTS
import ButtonGoPopup from "../popup/ButtonGoPopup";
import ParkingRealTimeLegendPopup from "../popup/ParkingRealTimeLegendPopup";
import SpecificDetailsPanelCaptionPopup from "../popup/SpecificDetailsPanelCaptionPopup";
import LiveIndic from "./LiveIndic";
import PlaceholderParkingSpecificDetailsPanel from "./PlaceholderParkingSpecificDetailsPanel";

//REDUX SELECTORS
import { liveParkingAvailabilitiesSelector } from "../global/state/liveParkingAvailabilitiesSelector";
import { specificParkingDataSelector } from "./state/specificParkingDataSelector";
import { researchModeSelector } from "../global/state/researchModeSelector";
import { trackingIdAndAnonymousLocalSelector } from "../userTracking/state/trackingIdAndAnonymousLocalSelector";
import { trackingSessionIdSelector } from "../userTracking/state/trackingSessionIdSelector";
import { userUidSelector } from "../global/state/userUidSelector";

//REDUX ACTIONS
import { toggleParkingSpecificDetailPanel } from "./state/displayParkingSpecificDetailPanelAction";
import { showDetailPanelContainer } from "../detailsPanel/state/displayDetailsPanelContainerAction";
import { resetPathToDestination } from "../markers/state/directionToDestinationAction";
import { resetSelectedParkingMarker } from "../markers/state/selectedParkingMarkerAction";
import { toggleSparkedMode } from "../global/state/sparkedModeAction";
import { showSparkedMarker } from "../markers/state/sparkedMarkerAction";
import { addTtpInfo } from "../sparkedMode/state/ttpInfoAction";
import { addSparkedParkingData } from "../sparkedMode/state/sparkedParkingDataAction";
import {
  activateLiveIndicAnimation,
  deactivateLiveIndicAnimation,
} from "./state/liveIndicAction";
import { toggleExpandedDetailPanel } from "./state/detailsPanelExpandedAction";
import { resetSpecificParkingData } from "./state/specificParkingDataAction";
import { toggleMustLoginScreen } from "../userMenu/state/displayMustLoginScreenAction";

//UTILS
import { addActionToDbBasedOnElement } from "../global/UserTrackingUtils";
import { addDataToUserSessionInDB } from "../api/userTrackingApi.js";

//STYLE
import * as IoIcon from "react-icons/io5";
import * as MdIcon from "react-icons/md";
import "./ParkingSpecificDetailsPanel.css";

function ParkingSpecificDetailsPanel(props) {
  const dispatch = useDispatch();

  let realTime;
  let specificMessages = [];
  let backgroundColorRealTime;
  let fontSizeRealTime;
  let imgFeatureOption = [];
  let arrayIcons = ["openHours", "booking", "evh", "wc", "carwash", "pmr"];

  //to hide/show parking features popup
  const [showPopupParkingLegend, setShowPopupParkingLegend] = useState(false);
  //to hide/show parking real time legend popup
  const [showPopupParkingRealtime, setShowPopupParkingRealtime] =
    useState(false);
  //to hide/show popup to open itinerary with gmapor waze
  const [showPopupButtonGo, setShowPopupButtonGo] = useState(false);
  //to know which popup type legend to display (time, price, features)
  const [parkingLegendType, setParkingLegendType] = useState("");
  //to display detail panel placeholder
  const [parkingDetailPanelPlaceholder, setParkingDetailPanelPlaceholder] =
    useState(true);

  const liveParkingsAvailabilities = useSelector(
    liveParkingAvailabilitiesSelector,
  );
  const specificParkingData = useSelector(specificParkingDataSelector);
  const researchMode = useSelector(researchModeSelector);
  const trackingIdAndAnonymousLocal = useSelector(
    trackingIdAndAnonymousLocalSelector,
  );
  const trackingSessionId = useSelector(trackingSessionIdSelector);
  const userUid = useSelector(userUidSelector);

  //to update real time info based on liveParkingAvailabilities
  if (
    liveParkingsAvailabilities !== undefined &&
    specificParkingData !== null
  ) {
    if (
      specificParkingData.data.specific_message !== undefined &&
      specificParkingData.data.specific_message !== null &&
      specificParkingData.data.specific_message.is_active === true
    ) {
      // IF threre specif message to display
      realTime = null;
      specificMessages = specificParkingData.data.specific_message.messages;
      backgroundColorRealTime = "var(--cocoparks-grey)";
      fontSizeRealTime = "9px";
    } else if (specificParkingData.data.isOpenNow) {
      // IF The parking is open now.
      if (
        liveParkingsAvailabilities[specificParkingData.data.index] !== undefined
      ) {
        let remainingSpots =
          liveParkingsAvailabilities[specificParkingData.data.index][
            "remaining_spots"
          ];
        let fillingRatio =
          liveParkingsAvailabilities[specificParkingData.data.index][
            "filling_ratio"
          ];
        if (fillingRatio >= 0.85 || remainingSpots <= 2) {
          backgroundColorRealTime = "var(--filling-red)";
        } else if (fillingRatio >= 0.75 && fillingRatio < 0.85) {
          backgroundColorRealTime = "var(--filling-orange)";
        } else {
          backgroundColorRealTime = "var(--cocoparksGreen)";
        }
        if (remainingSpots === 0) {
          realTime = "COMPLET";
        } else {
          realTime = remainingSpots + " PLACES";
        }
        fontSizeRealTime = "12px";
      } else {
        realTime = "PAS DE TEMPS REEL";
        backgroundColorRealTime = "var(--cocoparks-grey)";
        fontSizeRealTime = "9px";
      }
    } else {
      realTime = "FERME";
      backgroundColorRealTime = "var(--cocoparks-grey)";
      fontSizeRealTime = "9px";
    }
  }

  if (specificParkingData) {
    //to update feature parking panel (to display blue or grey image based on parking feature)
    if (specificParkingData.data.parkingInfo) {
      specificParkingData.data.parkingInfo.forEach((feature, index) => {
        let imgPath;
        if (feature === false || feature === null) {
          imgPath = "images/parkingFeatures/" + arrayIcons[index] + ".png";
          imgFeatureOption.push(
            <img
              src={imgPath}
              style={{ width: "24px" }}
              alt="Logo"
              key={index}
            />,
          );
        } else {
          imgPath = "images/parkingFeatures/" + arrayIcons[index] + "-blue.png";
          imgFeatureOption.push(
            <img
              src={imgPath}
              style={{ width: "24px" }}
              alt="Logo"
              key={index}
            />,
          );
        }
      });
      imgFeatureOption.splice(
        3,
        0,
        <div className="break" key={"break"}></div>,
      );
    }
  }

  function deactivateDetailPanel() {
    dispatch(toggleParkingSpecificDetailPanel(false));
    dispatch(showDetailPanelContainer());
    dispatch(resetPathToDestination());
    dispatch(resetSelectedParkingMarker());
    if (researchMode.value) {
      props.map.panTo(researchMode.location);
    }
  }

  //to switch the app to sparked mode (+ display sparker marker)
  //used when user click on "garé ici"
  function startSparkedMode() {
    let sparkedTime = Date.now();
    dispatch(toggleSparkedMode(true, "offstreet"));
    dispatch(showSparkedMarker(specificParkingData.data.location));
    let TTPinfo = {
      position: specificParkingData.data.location,
      ttp: null,
      start: sparkedTime,
    };
    dispatch(addTtpInfo(TTPinfo));
    let sparkedParkingData = {
      name: specificParkingData.data.name,
      price: specificParkingData.data.price,
      location: specificParkingData.data.location,
    };
    dispatch(addSparkedParkingData(sparkedParkingData));
    dispatch(toggleExpandedDetailPanel(false));
    deactivateDetailPanel();
    //to add informations about offstreet sparked position in DB
    let dataTracking = {
      lat: specificParkingData.data.location.lat,
      lng: specificParkingData.data.location.lng,
      ptype: "offstreet",
      pname: specificParkingData.data.name,
    };
    addDataToUserSessionInDB(
      "actions",
      trackingIdAndAnonymousLocal[1],
      trackingIdAndAnonymousLocal[0],
      trackingSessionId,
      dataTracking,
    );
  }

  //to display a specific legend popup based on which div has been clicked (time, price, features)
  function displayLegend(type) {
    //to set the type of the legend to display
    setParkingLegendType(type);
    //to display the popup
    setShowPopupParkingLegend(true);
  }

  //to animate liveindic
  function animatedLiveIndic() {
    dispatch(activateLiveIndicAnimation());
    setTimeout(() => {
      dispatch(deactivateLiveIndicAnimation());
    }, 3000);
  }

  function bookParking() {
    //redirect to parking reservation page if user is logged in
    //else display userMustLoginScreen
    if (userUid !== null) {
      window.open(specificParkingData.data.reservation);
    } else {
      dispatch(toggleMustLoginScreen(true));
    }
  }

  //to animate liveIndoc each time real time changing
  useEffect(() => {
    animatedLiveIndic();
  }, [realTime]);

  useEffect(() => {
    if (specificParkingData) {
      setParkingDetailPanelPlaceholder(false);
    }
  }, [specificParkingData]);

  useEffect(() => {
    return () => {
      dispatch(resetSpecificParkingData());
      dispatch(resetSelectedParkingMarker());
    };
  }, []);

  if (parkingDetailPanelPlaceholder) {
    return <PlaceholderParkingSpecificDetailsPanel />;
  } else {
    let realTimeRender =
      realTime !== null ? (
        <p
          style={{
            margin: "0px",
            lineHeight: "1",
            fontSize: fontSizeRealTime,
            fontWeight: "bold",
            color: "white",
          }}
        >
          {realTime}
        </p>
      ) : null;

    let specificMessageRender1 =
      specificMessages.length > 0 ? (
        <p
          style={{
            margin: "0px",
            lineHeight: "1",
            fontSize: fontSizeRealTime,
            fontWeight: "bold",
            color: "white",
          }}
        >
          {specificMessages[0]}
        </p>
      ) : null;

    let specificMessageRender2 =
      specificMessages.length > 1 ? (
        <p
          style={{
            margin: "0px",
            lineHeight: "1",
            fontSize: fontSizeRealTime,
            fontWeight: "bold",
            color: "white",
          }}
        >
          {specificMessages[1]}
        </p>
      ) : null;

    return (
      <>
        <div className="specific-detail-panel-container flex-center-column">
          {researchMode.value ? (
            <div
              className="close-parking-overview-div"
              onClick={() => {
                deactivateDetailPanel();
                addActionToDbBasedOnElement(
                  "closeParkingOverviewButton",
                  trackingIdAndAnonymousLocal,
                  trackingSessionId,
                );
              }}
            >
              <button className="city-map-button">
                <div className="city-map-button-container">
                  <span>Retourner aux résultats</span>
                  <IoIcon.IoChevronForwardSharp
                    style={{ color: "white", height: "24px", width: "24px" }}
                  />
                </div>
              </button>
            </div>
          ) : null}
          {/* div croix verte */}
          <div
            onClick={() => {
              deactivateDetailPanel();
              addActionToDbBasedOnElement(
                "closeParkingOverview",
                trackingIdAndAnonymousLocal,
                trackingSessionId,
              );
            }}
          >
            <IoIcon.IoClose
              style={{ color: "var(--blue)", width: "20px", height: "20px" }}
            />
          </div>
          {/* div parking title + real time */}
          <div className="flex-space-between-inline" style={{ width: "100%" }}>
            {/* div parking title + adress */}
            <div className="text-parking-div">
              <p className="blue-title">
                PARKING {specificParkingData.data.name}
              </p>
              <p className="grey-subtitle">
                {specificParkingData.data.address}
              </p>
            </div>
            {/* div real time + operator */}
            <div
              className="flex-flex-end-column"
              style={{ position: "relative" }}
            >
              {realTime !== null &&
              realTime !== "PAS DE TEMPS REEL" &&
              realTime !== "FERME" ? (
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    position: "absolute",
                    left: "-15px",
                    top: "-15px",
                  }}
                >
                  <LiveIndic />
                </div>
              ) : null}

              {/* Render RealTime */}
              {realTimeRender !== null ? (
                <div
                  style={{
                    backgroundColor: backgroundColorRealTime,
                    height: "30px",
                    padding: "10px",
                    borderRadius: "15px",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                  }}
                  onClick={() => setShowPopupParkingRealtime(true)}
                >
                  {realTimeRender}
                </div>
              ) : null}

              {/* Render specificMessageRender1 */}
              {specificMessageRender1 !== null ? (
                <div
                  style={{
                    backgroundColor: backgroundColorRealTime,
                    height: "30px",
                    padding: "10px",
                    borderRadius: "15px",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                  }}
                  onClick={() => setShowPopupParkingRealtime(true)}
                >
                  {specificMessageRender1}
                </div>
              ) : null}

              {/* Render specificMessageRender2 */}
              {specificMessageRender2 !== null ? (
                <div
                  style={{
                    backgroundColor: backgroundColorRealTime,
                    height: "30px",
                    padding: "10px",
                    borderRadius: "15px",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    marginTop: "5px",
                  }}
                  onClick={() => setShowPopupParkingRealtime(true)}
                >
                  {specificMessageRender2}
                </div>
              ) : null}

              <p className="grey-subtitle">
                {specificParkingData.data.reservationOnly
                  ? "SUR RESERVATION"
                  : specificParkingData.data.operator
                  ? specificParkingData.data.operator.toUpperCase()
                  : ""}
              </p>
            </div>
          </div>
          {/* div info parking (time, cost, option) */}
          <div
            className="flex-space-between-inline pinfo_parkings"
            style={{ width: "100%", height: "15vh" }}
          >
            {/* div time */}
            <div
              className="flex-center-column pinfo_container"
              onClick={() => {
                displayLegend("time");
                addActionToDbBasedOnElement(
                  "pinfo1",
                  trackingIdAndAnonymousLocal,
                  trackingSessionId,
                );
              }}
            >
              <div className="grid-container">
                <div className="grid-item">
                  <MdIcon.MdDriveEta
                    style={{
                      color: "var(--grey)",
                      width: "25px",
                      height: "25px",
                    }}
                  />
                </div>
                <div className="grid-item">
                  <span className="blue-text">
                    {specificParkingData.drivingTime}min
                  </span>
                </div>
                <div className="grid-item">
                  <MdIcon.MdDirectionsWalk
                    style={{
                      color: "var(--grey)",
                      width: "25px",
                      height: "25px",
                    }}
                  />
                </div>
                <div className="grid-item">
                  <span className="blue-text">
                    {specificParkingData.walkingTime}min
                  </span>
                </div>
              </div>
            </div>
            {/* div cost */}
            <div
              className="flex-center-column pinfo_container"
              onClick={() => {
                displayLegend("price");
                addActionToDbBasedOnElement(
                  "pinfo2",
                  trackingIdAndAnonymousLocal,
                  trackingSessionId,
                );
              }}
            >
              <div className="flex-center-inline">
                <p className="grey-text">1h:</p>
                <p className="blue-text">
                  {specificParkingData.data.price.price1
                    ? specificParkingData.data.price.price1
                    : "-"}
                  €
                </p>
              </div>
              <div className="flex-center-inline">
                <p className="grey-text">3h:</p>
                <p className="blue-text">
                  {specificParkingData.data.price.price3
                    ? specificParkingData.data.price.price3
                    : "-"}
                  €
                </p>
              </div>
              <div className="flex-center-inline">
                <p className="grey-text">24h:</p>
                <p className="blue-text">
                  {specificParkingData.data.price.price24
                    ? specificParkingData.data.price.price24
                    : "-"}
                  €
                </p>
              </div>
            </div>
            {/* div option */}
            <div
              className="pinfo_container featuresDiv"
              onClick={() => {
                displayLegend("features");
                addActionToDbBasedOnElement(
                  "pinfo3",
                  trackingIdAndAnonymousLocal,
                  trackingSessionId,
                );
              }}
            >
              <div>{imgFeatureOption}</div>
            </div>
          </div>
          {/* div button */}
          <div className="flex-space-around-inline pactions-parkings">
            <button
              className="pactions-button"
              onClick={() => {
                setShowPopupButtonGo(true);
                addActionToDbBasedOnElement(
                  "buttonGo",
                  trackingIdAndAnonymousLocal,
                  trackingSessionId,
                );
              }}
            >
              <img
                style={{
                  cursor: "pointer",
                  width: "25px",
                  verticalAlign: "middle",
                }}
                src="https://img.icons8.com/color/96/000000/google-maps.png"
                alt=""
              />
              <img
                style={{
                  cursor: "pointer",
                  width: "25px",
                  verticalAlign: "middle",
                }}
                src="https://img.icons8.com/color/96/000000/waze.png"
                alt=""
              />
              Go !
            </button>
            {specificParkingData.data.reservation ? (
              <button className="pactions-button" onClick={() => bookParking()}>
                <img
                  style={{ cursor: "pointer", width: "26px" }}
                  src="images/parkingFeatures/booking-white.png"
                  alt=""
                />
                Réserver
              </button>
            ) : null}
            <button
              className="pactions-button button-spark"
              onClick={() => {
                startSparkedMode();
                addActionToDbBasedOnElement(
                  "sparkedAtParking",
                  trackingIdAndAnonymousLocal,
                  trackingSessionId,
                );
              }}
            >
              Garé ici
            </button>
          </div>
        </div>
        {showPopupParkingLegend ? (
          <SpecificDetailsPanelCaptionPopup
            setTrigger={setShowPopupParkingLegend}
            type={parkingLegendType}
            entityType={"parking"}
          />
        ) : null}
        {showPopupButtonGo ? (
          <ButtonGoPopup
            setTrigger={setShowPopupButtonGo}
            destination={specificParkingData.data.location}
          />
        ) : null}
        {showPopupParkingRealtime ? (
          <ParkingRealTimeLegendPopup
            setTrigger={setShowPopupParkingRealtime}
          />
        ) : null}
      </>
    );
  }
}

export default ParkingSpecificDetailsPanel;
