import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//COMPONENTS
import CocoLoader from '../userIntroduction/CocoLoader';
import GreenCheck from '../global/GreenCheck';

//REDUX SELECTORS
import { userLocationSelector } from '../markers/state/userLocationSelector'
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'
import { cocoLoaderStateSelector } from '../userIntroduction/state/cocoLoaderStateSelector';

//REDUX ACTIONS
import { toggleUserResearchChoice } from './state/displayUserResearchChoiceAction';
import { updateTorchJson } from '../itinerary/state/torchJsonAction';
import { toggleItineraryMode } from '../global/state/itineraryModeAction';
import { hideDetailPanelContainer } from '../detailsPanel/state/displayDetailsPanelContainerAction';
import { toggleTorchNotAvailableScreen } from '../itinerary/state/displayTorchNotAvailableScreenAction';
import { toggleParkingResearchFromIntro } from './state/displayParkingResearchFromIntroAction'
import { toggleInteractiveBox } from '../cocosupporter/state/displayInteractiveBoxAction';

//UTILS
import { getRevealSlots } from '../api/itineraryApi'
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//STYLE
import "./UserResearchChoice.css"
import * as IoIcon from 'react-icons/io5';

function UserResearchChoice() {

    const dispatch = useDispatch()

    const userLocation = useSelector(userLocationSelector)
    const trackingIdAndAnonymousLocal = useSelector(trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)
    const cocoLoaderState = useSelector(cocoLoaderStateSelector)


    //to hide or display "Ne plus afficher cette fenêtre"
    const [ displayUserResearchChoiceMessage, setDisplayUserResearchChoiceMessage ] = useState(false)
    const [ displayLoader, setDisplayLoader ] = useState(true)
    const [ dataLoaded, setDataLoaded ] = useState(false)
    const [ showMessage, setShowMessage ] = useState(false)

    /**
     * Closes the introUserPath window and doesn't show it again
     */
    function stopDisplayingIntroUserPath(){
        var workingData = JSON.parse(localStorage.getItem('workingData'));
        workingData.stopDisplayingIntroUserPath = 1;
        localStorage.setItem('workingData', JSON.stringify(workingData));
        closeUserResearchChoiceContainer()
    }

    /**
     * Increment a variable in the localStorage each time the function is called
     * Maximum value = 3
     */
    function incrementUserResearchChoiceDisplayingMessage(){
        var workingData = JSON.parse(localStorage.getItem('workingData'));
        if(workingData !== null){
            if(workingData.hideIntroUserPathStopDisplayingMsg < 3 && workingData.hideIntroUserPathStopDisplayingMsg !== undefined) {
                workingData.hideIntroUserPathStopDisplayingMsg += 1;
                localStorage.setItem('workingData', JSON.stringify(workingData));
            } else if(workingData.hideIntroUserPathStopDisplayingMsg === undefined){
                workingData.hideIntroUserPathStopDisplayingMsg = 1
                localStorage.setItem('workingData', JSON.stringify(workingData));
            } else {
                setDisplayUserResearchChoiceMessage(true)
            }
        } else {
            localStorage.setItem('workingData', JSON.stringify({hideIntroUserPathStopDisplayingMsg: 1}));
        }
    }

    //to display the component used to research a parking
    function showParkingResearch(){
        if(dataLoaded){
            dispatch(toggleParkingResearchFromIntro(true))
            closeUserResearchChoiceContainer()
        } else {
            setShowMessage(true)
        }
    }

    //to hide the actual component
    function closeUserResearchChoiceContainer(){
        dispatch(toggleUserResearchChoice(false))
    }

    //to check to torch is available on userLocation
    async function checkIfTorchIsAvailable(){
        if(dataLoaded){
            await getRevealSlots({lat: userLocation.lat, lng: userLocation.lng})
            .then(function(resp){
                //torch available
                if(resp.results !== -1 || resp.results.total_number_of_spots > 0){
                    dispatch(toggleInteractiveBox({value: true, category: "torch", messageType: "loading"}))
                    startTorch(resp.results)
                    closeUserResearchChoiceContainer()
                } else {
                    dispatch(toggleTorchNotAvailableScreen(true))
                    closeUserResearchChoiceContainer()
                }
            })
        } else {
            setShowMessage(true)
        }
    }

    function startTorch(data){
        //to update torchJson based on last research position
        dispatch(updateTorchJson(data))
        //to hide details panel because it's suppose to be displayed by default when opening the app
        dispatch(hideDetailPanelContainer())
        //to update itinerary mode 
        dispatch(toggleItineraryMode(true))
    }


    function checkLoader(array){
        for(const element of array){
            if(!element){
                return false
            }
        }
        return true
    }


    useEffect(() => {
        incrementUserResearchChoiceDisplayingMessage()
    }, [])

    
    useEffect(() => {
        let check = checkLoader(cocoLoaderState)
        setDataLoaded(check)
        if(check){
            setTimeout(function(){
                setShowMessage(false)
                setDisplayLoader(false)
            }, 500)
        }
    }, [cocoLoaderState]);
    

    return (
        <div className="freeze-background-time-to-park time-to-park-div flex-center-column">
            <IoIcon.IoClose 
                className='menu-bars' 
                style={{position: "absolute", top: "15px", right: "15px"}}
                onClick={()=> {closeUserResearchChoiceContainer(); addActionToDbBasedOnElement("introUserPath_close", trackingIdAndAnonymousLocal, trackingSessionId)}}
            />
            <div className='cocoloader-div'>
                {displayLoader ?
                <div className='cocoloader'>
                    <CocoLoader/>
                </div>
                :<GreenCheck/>}
                {showMessage ?
                    <p className='no-margin title' style={{color: "white"}}>Patience Coco, ça charge !</p>
                :null}
            </div>
            <div className="time-park-button-div">
                <button className="green-button-time-to-park" onClick={()=>{checkIfTorchIsAvailable(); addActionToDbBasedOnElement("introUserPath_lookingForStreetSpot", trackingIdAndAnonymousLocal, trackingSessionId)}}><p className="no-margin">JE CHERCHE UNE PLACE<br/> DANS LA RUE</p></button>
            </div>
            <div className="time-park-button-div">
                <button className="green-button-time-to-park" onClick={()=>{showParkingResearch(); addActionToDbBasedOnElement("introUserPath_lookingForParking", trackingIdAndAnonymousLocal, trackingSessionId)}}><p className="no-margin">JE CHERCHE UN PARKING</p></button>
            </div>
            {displayUserResearchChoiceMessage ? 
            <div className="stop-displaying-window" onClick={()=>{ stopDisplayingIntroUserPath(); addActionToDbBasedOnElement("introUserPath_stopDisplayingWindow", trackingIdAndAnonymousLocal, trackingSessionId)}}>
                <span >Ne plus afficher cette fenêtre</span>
            </div>
            :null}
        </div>
    )
}

export default UserResearchChoice
