import React, {useState, useEffect} from 'react'
import {OverlayView, Circle} from '@react-google-maps/api'

//UTILS
import { calculateLabelPosition} from '../global/MapUtils';

//STYLE
import * as FaIcon from 'react-icons/fa';


function WalkingCircleMarker(props) {

    const [labelPosition, setLabelPosition] = useState()
    
    useEffect(() => {
        //to find the position of the walking label based on the  user location and the circle radius
        var result = calculateLabelPosition(props.info.position, props.info)
        setLabelPosition(result)
    }, [props.info])
    
    return (
        <div>
            {/* marker to display walking circle label */}
            <OverlayView
                position={labelPosition}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={(width, height) => ({
                    x: -(width / 2),
                    y: -(height / 2)
                })}
            >
            <div>
                <FaIcon.FaWalking style={{color: "#383838", height: "20px", width: "20px"}} />
                <p style={{color: "#383838"}}>{props.info.label}</p>
            </div>
            </OverlayView>
            {/* marker to display walking circle. its size is based on zoom level */}
            <Circle
                center={props.info.position}
                options={{
                    strokeColor: '#383838',
                    strokeOpacity: 0.5,
                    strokeWeight: 2,
                    fillOpacity: 0,
                    clickable: false,
                    draggable: false,
                    editable: false,
                    visible: true,
                    radius: props.info.radius,
                    zIndex: 1
                }}
            />
        </div>
    )
}

export default WalkingCircleMarker
