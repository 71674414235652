import React, { useRef } from "react";
import { useSelector } from 'react-redux'
import { GoogleMap } from "@react-google-maps/api";

//REDUX SELECTOR
import { displayInstallationLegendIphoneSelector } from '../installation/state/displayInstallationLegendIphoneSelector';

//COMPONENTS
import NavBar from '../navBar/NavBar'
import NavMenu from '../navBar/NavMenu'
import UserMenu from '../userMenu/UserMenu'
import GeolocationActivationBanner from "./GeolocationActivationBanner";
import InstallationLegendIphone from "../installation/InstallationLegendIphone";


function EmptyMap(props) {

    const mapRef = useRef(null) //to store the map

    const config = useSelector((state) => state.config);
    const  displayInstallationLegendIphone = useSelector( displayInstallationLegendIphoneSelector)

    //map options
    let options = {
        gestureHandling: "greedy",
        disableDefaultUI: true,
    };

    //function called after loading the map
    async function handleLoad(map) {
        //to store the current map
        mapRef.current = map;
    }

    let mapCenter = config.geolocalisation_searchbar
        ? { lat: 48.8566, lng:  2.3522 }
        : config.map_center

    return (
        <div style={{position: "relative", overflow: 'hidden', height: "100vh"}}>  

        {/* display or not viewfinder based on aroundMeMode state. The viewfinder is positioned above the map in absolute position */}
            
            <GoogleMap
                options={options}
                onLoad={handleLoad}
                mapContainerStyle={{
                    width: '100vw',
                    height: '100vh',
                }}
                zoom={15}
                center={mapCenter}
                id="map"
            >
                
            </GoogleMap>

            <NavBar 
                map={mapRef.current}
                geolocationActive={props.geolocationActive}
            />

            <NavMenu/>

            {props.geolocationActive || !config.geolocalisation_searchbar
                ? null
                :<GeolocationActivationBanner/>
            }

            {displayInstallationLegendIphone ? 
                <InstallationLegendIphone/>
            :null}
            
        </div>
   );
}

export default EmptyMap

