import firebase from "firebase/app";
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import "firebase/auth"
import 'firebase/storage';  // <----

let firebaseConfig

if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    firebaseConfig = {
        apiKey: "AIzaSyAHSFgHyUPcB0GGnROXZ0Z0Qq25zTxBuzU",
        authDomain: "database-dev-fcdce.firebaseapp.com",
        databaseURL: "https://database-dev-fcdce.firebaseio.com",
        projectId: "database-dev-fcdce",
        storageBucket: "database-dev-fcdce.appspot.com",
        messagingSenderId: "803927640163",
        appId: "1:803927640163:web:015f9cb37ea666ab1d9056",
        measurementId: "G-2SLDTB9TWG"
    }
} else if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    firebaseConfig = {
        apiKey: "AIzaSyBRc2STtwL7UU9xYE1F4S2CF8LrcjX6YMU",
        authDomain: "database-cocoparks.firebaseapp.com",
        databaseURL: "https://database-cocoparks.firebaseio.com",
        projectId: "database-cocoparks",
        storageBucket: "database-cocoparks.appspot.com",
        messagingSenderId: "208142225663",
        appId: "1:208142225663:web:ecfc89c6350e4213b03cca",
        measurementId: "G-M4B81BMCP5"
    };
} else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
    firebaseConfig = {
        apiKey: "AIzaSyDJWbn-rMIYMKC8tIVHTjML6Dnzgw4oHKU",
        authDomain: "cocoparks-staging.firebaseapp.com",
        databaseURL: "https://cocoparks-staging.firebaseio.com",
        projectId: "cocoparks-staging",
        storageBucket: "cocoparks-staging.appspot.com",
        messagingSenderId: "614281028249",
        appId: "1:614281028249:web:d81d409aaad831f3c24e08",
        measurementId: "G-0K4DRNFQET"
    };
}
//TO DO HANDLE CASE WHERE ENVIRONMENT ISN'T DEV OR PROD


// This is our firebaseui configuration object
const uiConfig = ({
    callbacks: {
        signInSuccessWithAuthResult: function () {
            return false; // or true --> redirect
        },
    },
    signInFlow: 'popup', //redirect
    signInSuccessUrl: '/', //url_to_main
    signInOptions: [
        // GOOGLE
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // FACEBOOK
        {
            provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            // Whether the display name should be displayed in the Sign Up page.
            requireDisplayName: true,
        },
    ],
    tosUrl: function () {
        window.location.assign('https://cocoparks.io/terms');
    },
    privacyPolicyUrl: function () {
        window.location.assign('https://cocoparks.io/privacy');
    }
})

//initialize Firebase
firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth()
export const storageRef = firebase.storage().ref();
export const storage = firebase.storage();


const ui = new firebaseui.auth.AuthUI(firebase.auth())
// This adds firebaseui to the page
// It does everything else on its own
export const startFirebaseUI = function (elementId) {
    ui.start(elementId, uiConfig)
}

export default firebase
