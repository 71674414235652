const initialState = {
    parkingTime: "-",
    parkingCost: "-",
}
export const STORE_CLOSEST_PARKING_INFO = 'STORE_CLOSEST_PARKING_INFO'

export function firstClosestParkingInfoReducer (state = initialState, action) {
    switch (action.type){
        case STORE_CLOSEST_PARKING_INFO:
            return action.payload
        default: return state
    }
}