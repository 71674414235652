import { handleResponse } from "./utilsApi"
import * as url from './routes'

/**
 * Getting postcode from position
 * @param {object} position - {lat: number, lng: number} position to get postcode from
 */
 export function checkIfUserExistOnDB(id){
    let dataToSend = {'id': id, 'isAnonymousLocal': false}
    let response = fetch(url.checkUserExistInDB, 
    {
        method: 'post',
        body: JSON.stringify(dataToSend)
    })
    .then(handleResponse)
    .then(data => {
        return data
    })
    .catch(error => console.log(error))
    return response
}


export function getUser(id){
    let response = fetch(url.getUser + id)
    .then(handleResponse)
    .then(data => {
        let resp = JSON.parse(data)
        return resp
    })
    .catch((error) => console.log('error: ' + error))
    return response
}


export function getAnonymousUser(id){
    let response = fetch(url.getAnonymousUser + id)
    .then(handleResponse)
    .then(data => {
        return data
    })
    .catch((error) => console.log('error: ' + error))
    return response
}


export function updateUserInDB(data){
    let response = fetch(url.updateUser, 
    {
        method: 'post',
        body: JSON.stringify(data)
    })
    .then(handleResponse)
    .then(data => {
        return data
    })
    .catch(error => console.log(error))
    return response
}

/**
 * creates user when not registered in DB
 * @param {string} id - user id from firebase
 * @param {json} profileData - data json from with fields to be filled in
 * returns id of user: created for anonymous, firebase uid for non anonymous
 */
export function createUserInDB(id, profileData){
    let dataToSend = {"id": id, "profileData": profileData}
    let response = fetch(url.createUser, 
    {
        method: 'post',
        body: JSON.stringify(dataToSend)
    })
    .then(handleResponse)
    .then(data => {
        return data
    })
    .catch(error => console.log(error))
    return response
} 