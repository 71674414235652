import AccordionHelp from './AccordionHelp'

import { HelpData } from './HelpData'

function Help() {

    return (
        <div style={{backgroundColor: "white", width: "100vw", height: "100vh"}}>
            <div className="flex-align-top-inline">
                <h3 className="title">Aide et tutoriel</h3>
            </div>
            <div style={{width: "100%"}} className="flex-center-column">
                <div style={{width: "90%"}}>
                    {HelpData.map((section)=> {
                        var content
                        if(section.type === 'text'){
                            content = section.content
                        }else {
                            content = section.tutorialSteps
                        }
                        return (
                            <AccordionHelp 
                                key={section.key} 
                                title={section.title} 
                                content={content} 
                                section={section.key} 
                                type={section.type}
                                id={section.id}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Help
