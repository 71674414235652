import usingCocoparks1 from '../images/Tutorials/howToUseCocoparks/1.png';
import usingCocoparks2 from '../images/Tutorials/howToUseCocoparks/2.png';
import usingCocoparks3 from '../images/Tutorials/howToUseCocoparks/3.png';
import usingCocoparks4 from '../images/Tutorials/howToUseCocoparks/4.png';
import usingCocoparks5 from '../images/Tutorials/howToUseCocoparks/5.png';
import usingCocoparks6 from '../images/Tutorials/howToUseCocoparks/6.png';

import intro1 from '../images/Tutorials/intro/1.png';
import intro2 from '../images/Tutorials/intro/2.png';
import intro3 from '../images/Tutorials/intro/3.png';
import intro4 from '../images/Tutorials/intro/4.png';


export const HelpData = [
  
    {
        title: 'Comment utiliser Cocoparks ?',
        key: 'using-cocoparks', 
        id: "howToUseCocoparks",
        type: "tuto", 
        tutorialSteps: [
            {
                label: "1. Votre compagnon pour se garer en ville", 
                legend: "Faites les bons choix pour optimiser votre temps et vos dépenses de stationnement, en temps réel et grâce à la communauté",
                imgPath: usingCocoparks1
            },
            {
                label: "2. Faites les bons choix pour économiser et gagner du temps !", 
                legend: "Le parking payant est parfois moins cher que le stationnement dans la rue ! Comparez, à tout instant et où que vous soyez, le coût du stationnement et le temps pour accéder à une place entre la voirie et le parking le plus proche",
                imgPath: usingCocoparks2
            },
            {
                label: "3. Infos pratiques !", 
                legend: "Retrouvez toutes les infos pratiques sur le stationnement dans votre ville: prix, réglementation, FPS, etc...",
                imgPath: usingCocoparks3
            },
            {
                label: "4. Évitez les zones rouges, priviligiez les zones vertes !", 
                legend: "Pour vous aider à vous garer dans la rue, tous les Coco vous indiquent le temps qu'ils ont mis pour trouver une place dans la rue.",
                imgPath: usingCocoparks4
            },
            {
                label: "5. Toutes les caractéristiques et prix des parkings", 
                legend: "Trouvez le meilleur parking autour de vous avec les infos détaillées et à jour sur son coût et ses services.",
                imgPath: usingCocoparks5
            },
            {
                label: "6. Payez votre stationnement et retrouvez facilement votre véhicule", 
                legend: "Cocoparks vous permet de payer avec les applications disponibles dans votre ville. Partagez et retrouvez à tout instant où et depuis combien de temps vous êtes garés, ainsi que le prix du stationnement",
                imgPath: usingCocoparks6
            }
        ]
    },
    {
        title: "Comment installer l'appli Cocoparks ?",
        key: 'install-cocoparks', 
        id: "howToDownloadCocoparks",
        type: "text", 
        content: ["Il vous suffit d'accéder à l'appli, puis de cliquer sur Ajouter à l'ecran d'accueil.", ]

    },
    {
        title: "Revoir l'introduction",
        key: 'see-cocoparks-introduction', 
        id: "seeIntroAgain",
        type: "tuto", 
        tutorialSteps: [
            {
                label: "Préparez votre stationnement", 
                legend: "Comparez à tout instant les prix du stationnement dans la rue et dans les parkings payant autour de votre destination",
                imgPath: intro1
            },
            {
            label: "Garez vous plus rapidement", 
            legend: "Dirigez vous dans les zones indiquées par la torche et augmentez fortement vos chances de trouver un place !",
            imgPath: intro2
            },
            {
            label: "...avec l'aide des cocos !", 
            legend: "Evitez les zones rouges, privilégiez les zones vertes. Les cocos vous indiquent le temps pour trouver une place dans chaque zone.",
            imgPath: intro3
            },
            {
            label: "Et une fois garé ?", 
            legend: "Enregistrez votre stationnement, et contribuez à votre tour en indiquant à la communauté s'il a été difficile de vous garer ou non",
            imgPath: intro4
            }
        ]
    },
    {
        title: 'Pourquoi Cocoparks ?',
        key: 'why-cocoparks', 
        id: "whyCocoparks",
        type: "text", 
        content: ["Le stationnement est un cauchemar: temps perdu, pollution de la ville, dépenses de carburant augmentées, et effets induits négatifs sur le reste de la circulation. Chez Cocoparks, nous améliorons l'expérience de stationnement car nous croyons qu’il s’agit là d’un des leviers les plus puissants pour gagner du temps, réduire les dépenses et les émissions de CO2."]
    },
    {
        title: "Nous contacter",
        key: 'contact-us', 
        id: "contactUs",
        type: "text", 
        content : ["N'hésitez pas à échanger avec la Team Cocoparks. Pour nous contacter, à ce stade, merci d'utiliser la section 'feedback' dans le menu"]
    }
];