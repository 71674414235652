import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Marker } from '@react-google-maps/api'

//REDUX SELECTOR
import { frontStyleLvzSelector } from './state/frontStyleLvzSelector'
import { filterModeActiveSelector } from '../filters/state/filterModeActiveSelector';
import { mapFilterTrackersSelector } from '../filters/state/mapFilterTrackersSelector';

//REDUX ACTIONS
import { showLvzInfoWindow } from './state/lvzInfoWindowAction';

//UTILS
import * as global from "../global/globalVariable"
import { findStringInArray } from "../global/GlobalUtils"

export const LiveViewZoneSlotMarker = React.memo(({position, isFree, isActivated, slotType, legend }) => {

    const dispatch = useDispatch()

    const frontStyleLvz = useSelector(frontStyleLvzSelector)
    const filterModeActive = useSelector(filterModeActiveSelector)
    const mapFilterTrackers = useSelector(mapFilterTrackersSelector)

    let slotTypeArray = global.slotTypeArray
    let filterTrackersIdentification = global.filterTrackersIdentification
    let imgPathSlotType = ""
    let imgPath = "images/lvzMarker/circle-red.png"
    let specialTypeOfMarker = slotTypeArray.indexOf(slotType)
    let visible = true

    //to check if slot has a special type. All specials types are listed in slotTypeArray on globalVariable
    if(specialTypeOfMarker !== -1){
        imgPathSlotType = "images/lvzMarker/lvz-" + slotType + ".png"
    }
    
    //display colored circle only if slot is active
    //then display green or red circle based on is_free status
    if(isActivated){
        if(isFree){
            imgPath = "images/lvzMarker/circle-green.png"
        }
    } else{
        imgPath = "images/lvzMarker/circle-grey.png"
        imgPathSlotType = ""
    }

    function displayInfoWindow(location, legend ){
        dispatch(showLvzInfoWindow({location: location, legend: legend}))
    }

    let imageCircle = {
        url: imgPath,
        scaledSize: new window.google.maps.Size(30, 30)
    };

    let imageSlotType = {
        url: imgPathSlotType,
        scaledSize: new window.google.maps.Size(30, 30)
    };

    if(frontStyleLvz > 3){
        visible = true
    } else {
        visible = false
    }


    //if filterMode is active, slots from LVZ are displayed only if there slot type matches one of the filter
    if(filterModeActive){
        let activeFilter = []
        for(const [index, status] of mapFilterTrackers.entries()){
            let isFilterForLvz = global.filterToExcludedFromLvz.find(e => e === filterTrackersIdentification[index])
            //Push only filters corresponding to lvz in activeFilter
            if(status && !isFilterForLvz){
                activeFilter.push(filterTrackersIdentification[index])
            }
        }
        //hide slot only if at least one filter is selected
        if(!findStringInArray(activeFilter, slotType) && activeFilter.length > 0){
            visible = false
        }
    }

    return (
        <>
            <Marker
                position={position}
                icon={imageCircle}
                clickable={false}
                zIndex={10}
                visible={visible}
            />  
            {imgPathSlotType !== "" ?
                <Marker
                    position={position}
                    icon={imageSlotType}
                    onClick={()=> displayInfoWindow(position, legend )}
                    zIndex={100}
                    visible={visible}
                />
            :null}          
        </>
    )
})

export default LiveViewZoneSlotMarker
