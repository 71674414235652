import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { InfoWindow } from '@react-google-maps/api'

//REDUX SELECTORS
import { lvzInfoWindowSelector } from './state/lvzInfoWindowSelector'
import { frontStyleLvzSelector } from './state/frontStyleLvzSelector'

//REDUX ACTIONS
import { hideLvzInfoWindow } from './state/lvzInfoWindowAction'

//UTILS
import './LvzInfoWindow.css'

function LvhInfoWindow() {

    const lvzInfoWindow = useSelector(lvzInfoWindowSelector)
    const frontStyleLvz = useSelector(frontStyleLvzSelector)

    let infoWindowContent = lvzInfoWindow.legend
    let options = {
        disableAutoPan: true,
        pixelOffset: new window.google.maps.Size(0,-30)
    }

    const dispatch = useDispatch()
    
    //to reset the component when user click on infowindow's close button 
    function closeInfoWindow(){
        dispatch(hideLvzInfoWindow())
    }

    useEffect(() => {
        if(frontStyleLvz !== 4){
            closeInfoWindow()
        }
    }, [frontStyleLvz])

    return (
        <div>
            <InfoWindow
                position={lvzInfoWindow.position}
                onCloseClick={closeInfoWindow}
                options={options}
            >
            <div>
                <p className="no-margin lvz-infowindow-text">{infoWindowContent}</p>
            </div>
            </InfoWindow>
        </div>
    )
}

export default LvhInfoWindow
