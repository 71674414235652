import React, { useState } from 'react'
import { useSelector } from 'react-redux'

//REDUX SELECTOR
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'

//UTILS
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//STYLE
import * as IoIcon from 'react-icons/io5';
import './LegendContainer.css'


function LegendContainer() {

    let legendItems = [
        {imagePath: "images/Markers/cocomrkr.png", legend: "Parking payant", alt: "parking legend - parking"},
        {imagePath: "images/Markers/cocomrkrP-booking.png", legend: "Parking payant sur réservation uniquement", alt: "parking legend - parking reservation"},
        {imagePath: "images/legend/lvzLegend.svg", legend: "Cocozone : zone temps réel avec 5 places en voirie disponible", alt: "parking legend - cocozone"},
        {imagePath: "images/legend/offLvzLegend.svg", legend: "Cocozone existante mais infos temps réel indisponibles", alt: "parking legend - cocozone no real time"},
        {imagePath: "images/Markers/pmrstation.png", legend: "Place pour Personne à Mobilité Réduite", alt: "parking legend - parking pmr"},
        {imagePath: "images/Markers/evhstation.png", legend: "Place avec recharge électrique", alt: "parking legend - parking evh charge"},        
        {imagePath: "images/Markers/evhstation-nocharge.png", legend: "Place pour véhicule électrique sans recharge", alt: "parking legend - parking evh no charge"},        
        {imagePath: "images/legend/normal_place.png", legend: "Emplacement classique en voirie", alt: "parking legend - parking onstreet"},        
        {imagePath: "images/legend/delivery_place.png", legend: "Emplacement pour livraison accessible à certains créneaux horaires", alt: "parking legend - parking delivery"},        
    ]

    const [displayLegend, setDisplayLegend] = useState(false)

    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)

    const transformLegend = {
        transform: displayLegend ? 'translateX(0%)' : 'translateX(-80%)', 
        transition: 'transform 300ms ease', // smooth transition
    }

    const transformLegendDiv = {
        zIndex: displayLegend ? "20" : "0"
    }

    return (
        <>
            <div className="legend-master" style={transformLegendDiv}>
                <div className="legend" style={transformLegend}>
                    <div className="legend-all-images">
                        <div style={{height:"100vh"}} className="flex-center-column">
                            <div className="legend-title">LEGENDE</div>
                            <div className="legend-subtitle"> <p>cliquez, zoomez sur la carte pour découvrir les informations </p></div>
                            <div className="table-div">
                                <table className="legend-table">
                                    <tbody>
                                        {legendItems.map((item, index)=>{
                                            return(
                                                <tr key={index}>
                                                    <td className="legend-td-img"><img className="legend-img" src={item.imagePath} alt={item.alt}/></td>
                                                    <td className="legend-info">{item.legend}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="legend-button-div" onClick={()=> {setDisplayLegend(!displayLegend); addActionToDbBasedOnElement("legendButton", trackingIdAndAnonymousLocal, trackingSessionId)}}>
                        <div id="legend-button">
                            {displayLegend ?
                                <IoIcon.IoChevronBack 
                                style={{color: "black"}} 
                                size={20} 
                                /> 
                                :<IoIcon.IoChevronForward 
                                    style={{color: "black"}} 
                                    size={20} 
                                /> 
                            }
                        </div>
                    </div>
                </div>
            </div>
            {displayLegend ? <div className="freeze-background active" style={{zIndex: "15"}} onClick={()=>{setDisplayLegend(false)}}></div> : <div className="freeze-background"></div>} 
        </>
    )
}

export default LegendContainer
