import React, { useState, useEffect, useRef } from 'react';
import { OverlayView , Circle} from "@react-google-maps/api";
import { useSelector } from 'react-redux'

//REDUX SELECTORS
import { userLocationSelector } from '../markers/state/userLocationSelector'
import { walkingCircleSelector } from '../markers/state/walkingCircleSelector'
import { labelPositionSelector } from '../markers/state/labelPositionSelector'
import { researchModeSelector } from '../global/state/researchModeSelector';
import { aroundMeModeSelector } from '../global/state/aroundMeModeSelector';

//STYLE
import * as FaIcon from 'react-icons/fa';
import './UserLocationMarker.css';

export default function UserLocationMarker(){

    
    const userLocation = useSelector(userLocationSelector)
    const walkingCircle = useSelector(walkingCircleSelector)  
    const labelPosition = useSelector(labelPositionSelector)  
    const researchMode = useSelector(researchModeSelector)
    const aroundMeMode = useSelector(aroundMeModeSelector)

    const [displayWalkingCircle, setDisplayWalkingCircle] = useState(true)

    const aroundModeRef = useRef()

    //use to access aroundMode when ResearchMode changes
    //without updating setDisplayWalkingCircle when aroundMeMode changes
    useEffect(() => {
        aroundModeRef.current = aroundMeMode
    }, [aroundMeMode])

    
    useEffect(() => {

        if(!researchMode.value){
            setDisplayWalkingCircle(true)
        } else if(researchMode.value && !aroundModeRef.current){
            setDisplayWalkingCircle(false)
        } else{
            setDisplayWalkingCircle(true)
        }

    }, [researchMode])
    

    if(!userLocation.lastKnowUserLocation){
        return (
            <div>
                {/* marker to display user location */}
                <OverlayView
                    position={userLocation}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                    <div className="marker"/>
                </OverlayView> 
          
                {/* marker to display walking circle label */}
                {displayWalkingCircle ?
                    <OverlayView
                        position={labelPosition}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        getPixelPositionOffset={(width, height) => ({
                            x: -(width / 2),
                            y: -height
                        })}
                    >
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "10%"}}>
                            <FaIcon.FaWalking style={{color: "#383838", height: "20px", width: "20px"}} /><p style={{color: "#383838", fontSize: "16px"}} className="no-margin">{walkingCircle.label}</p>
                        </div>
                    </OverlayView> : null
                }
                {/* marker to display walking circle. its size is based on zoom level */}
                {displayWalkingCircle ?
                    <Circle
                        center={userLocation}
                        options={{
                            strokeColor: '#383838',
                            strokeOpacity: 0.5,
                            strokeWeight: 2,
                            fillOpacity: 0,
                            clickable: false,
                            draggable: false,
                            editable: false,
                            visible: true,
                            radius: walkingCircle.radius,
                            zIndex: 1
                        }}
                    /> : null
                }
            </div>
        );
    }else {
        return (
            <OverlayView
                position={userLocation}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}   
            >
                <div className="marker-disabled"/>
            </OverlayView> 
        )
    }
}  
