import React from 'react'
import { Marker, OverlayView} from '@react-google-maps/api'
import { useSelector } from 'react-redux'

//REDUX SELECTORS
import { sparkedMarkerSelector } from './state/sparkedMarkerSelector';
import { parkingTagTableSelector } from '../sparkedMode/state/parkingTagTableSelector'

//STYLE
import * as MdIcon from 'react-icons/md';
import './SparkedMarker.css'


function SparkedMarker() {

    const sparkedMarker = useSelector(sparkedMarkerSelector)
    const parkingTagTable = useSelector(parkingTagTableSelector)

    if(sparkedMarker.visible === true){
        let position = {lat : sparkedMarker.position.lat, lng : sparkedMarker.position.lng}
        let image = {
            url: "images/markers/LOGO-marker.png",
            scaledSize: new window.google.maps.Size(30, 60), // scaled size
        };

    
        return (
            <>
                <Marker 
                    position={position}
                    icon={image}
                    zIndex={20}
                ></Marker>
                {parkingTagTable.visible ? 
                    <OverlayView 
                        position={{lat : position.lat, lng : position.lng}}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        getPixelPositionOffset={(width, height) => ({
                            x: -(width / 2),
                            y: 10
                        })}
                    >
                        <div className="flex-center-column direction-info-container">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <MdIcon.MdDriveEta 
                                                style={{color: "var(--cocoparksGreen)", width: "25px", height: "25px"}}
                                            />
                                        </td>
                                        <td>
                                            <p className="green-text">{parkingTagTable.driveTime ? parkingTagTable.driveTime + "min" : "-"}</p>
                                        </td>
                                        <td>
                                            <p className="green-text">{parkingTagTable.driveDist ? parkingTagTable.driveDist : "-"}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <MdIcon.MdDirectionsWalk 
                                                style={{color: "var(--cocoparksGreen)", width: "25px", height: "25px"}}
                                            />
                                        </td>
                                        <td>
                                            <p className="green-text">{parkingTagTable.walkTime ? parkingTagTable.walkTime + "min": "-"}</p>
                                        </td>
                                        <td>
                                            <p className="green-text">{parkingTagTable.walkDist ? parkingTagTable.walkDist : "-"}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>                
                    </OverlayView> 
                :null}
            </>
        )
    } else {
        return <></>
    }
    
}

export default SparkedMarker
