
const initalState = {data: "", surroundingCityZone: ""}

export const UPDATE_PMR_MARKER_DATA = 'UPDATE_PMR_MARKER_DATA'

export function pmrMarkerDataReducer (state = initalState, action) {
    switch (action.type){
        case UPDATE_PMR_MARKER_DATA:
            return action.payload
        default: return state
    }
}