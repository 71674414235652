export const HIDE_FAVORITES_ADDRESSES_DIV = 'HIDE_FAVORITES_ADDRESSES_DIV'
export const SHOW_FAVORITES_ADDRESSES_DIV = 'SHOW_FAVORITES_ADDRESSES_DIV'

export function displayFavoriteAndRecentAddressesReducer (state = false, action) {
    switch (action.type){
        case HIDE_FAVORITES_ADDRESSES_DIV:
            return false
        case SHOW_FAVORITES_ADDRESSES_DIV:
            return true
        default: return state
    }
}