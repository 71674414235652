//to transform the response from the server based on its type
export function handleResponse (response) {
    let contentType = response.headers.get('content-type')
    if (contentType.includes('application/json')) {
        return handleJSONResponse(response)
    } else if (contentType.includes('text/html')) {
        return handleTextResponse(response)
    } else {
        // Other response types as necessary. I haven't found a need for them yet though.
        throw new Error(`Sorry, content-type ${contentType} not supported`)
    }
}
  

//to return the json if status is ok. Otherwise return a readable error (json type)
export function handleJSONResponse (response) {
    return response.json()
        .then(json => {
            if (response.ok) {
                return json
            } else {
                return Promise.reject(Object.assign({}, json, {
                    status: response.status,
                    statusText: response.statusText
            }))
        }
    })
}
  
 
//to return the json if status is ok. Otherwise return a readable error (text/html type)
export function handleTextResponse (response) {
    return response.text()
        .then(text => {
            if (response.ok) {
                return text
            } else {
                return Promise.reject({
                    status: response.status,
                    statusText: response.statusText,
                    err: text
                })
            }
    })
}


//to transfrom an object into a query parameters to be used in the fetching url (ex: key1=value1?key2=value2)
export function transfromObjectToQueryString(obj) {
    const keyValuePairs = [];
    for (const key in obj) {
        if(obj[key].length>0){
            obj[key].forEach((item)=>{
                keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(item));
            })
        } else {
            keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
        }
    }
    const queryString = "?" + keyValuePairs.join('&')
    return queryString;
}