import { UPDATE_LVZ_MARKER_REAL_TIME, ADD_LVZ_MARKER_REAL_TIME } from "./lvzMarkerRealTimeReducer"

export const updateLvzMarkerRealTime = (info) => ({
        type: UPDATE_LVZ_MARKER_REAL_TIME,
        payload: info
})


export const addLvzMarkerRealTime = (info) => async (dispatch) => {
    dispatch({
        type: ADD_LVZ_MARKER_REAL_TIME,
        payload: info
    })
}