const initialState = {
    value: false,
    nbTotalOfSlots: "",
    estimatedTime: "",
    centerResearchMarker: { lat: 0, lng: 0}
}

export const UPDATE_ITINERARY_OVERVIEW_DATA = 'UPDATE_ITINERARY_OVERVIEW_DATA'
export const RESET_ITINERARY_OVERVIEW_DATA = 'RESET_ITINERARY_OVERVIEW_DATA'

export function displayItineraryOverviewReducer(state = initialState, action) {
    switch (action.type){
        case UPDATE_ITINERARY_OVERVIEW_DATA:
            return action.payload
        case RESET_ITINERARY_OVERVIEW_DATA:
            return initialState
        default: return state
    }
}