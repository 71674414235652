import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Circle , OverlayView} from '@react-google-maps/api'

//REDUX SELECTORS
import { frontStyleSparkedCocoSelector } from './state/frontStyleSparkedCocoSelector';
import { itineraryModeSelector } from '../global/state/itineraryModeSelector'


//STYLE
import './SparkedCocoMarker.css'


//JSON
import jsonZoomUIStatuses from '../zoom/jsonZoomUIStatuses.json'


export const SparkedCocoMarker = React.memo((props) => {

    let cocoTTPMaxLifetime = 12*60*1000;
    let circleColor
    let radiusSize
    let label
    let delay

    if(props.info.start != null){
        let start = props.info.start;
        let currentTime = Date.now();
        let remainingTime = cocoTTPMaxLifetime - (currentTime - start);
        delay = remainingTime
    } else {
        delay = cocoTTPMaxLifetime
    }
    
    const [showSparkedCocoMarker, setShowSparkedCocoMarker] = useState(true)
    const frontStyleSparkedCoco = useSelector(frontStyleSparkedCocoSelector)
    const itineraryMode = useSelector(itineraryModeSelector)
    
    //allocated the right image path and className to marker based on jsonZoomUIStatuses.json
    let imgPath = jsonZoomUIStatuses["sparkedCoco"].icons[frontStyleSparkedCoco].imgSrc
    let className = jsonZoomUIStatuses["sparkedCoco"].icons[frontStyleSparkedCoco].className

    //to hide sparked coco marker after 30sec
    setTimeout(() => { 
        setShowSparkedCocoMarker(false)
    }, delay)

    //update sparkedCocoMarker stype based on ttp from props
    if(props.info.ttp === "15"){
        circleColor = "red"
        radiusSize = 300
        imgPath = imgPath + "-red.png"
        label = "+15min"
    } else if (props.info.ttp === "10"){
        circleColor = "#FFA500"
        radiusSize = 200
        imgPath = imgPath + "-orange.png"
        label = "10min"
    } else {
        circleColor = "#1faa00"
        radiusSize = 250
        imgPath = imgPath + ".png"
        label = "-5min"
    }

    const options = {
        strokeColor: circleColor,
        strokeOpacity: 0,
        strokeWeight: 2,
        fillColor: circleColor,
        fillOpacity: 0.5,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: radiusSize,
        zIndex: 1
    }

    if(showSparkedCocoMarker && !itineraryMode){
        return (
            <div>
                <Circle 
                    center={{lat : props.location.lat, lng : props.location.lng}}
                    options={options}
                />
                { frontStyleSparkedCoco === 1 ? 
                    <div>
                        <OverlayView 
                            position={{lat : props.location.lat, lng : props.location.lng}}
                            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                            getPixelPositionOffset={(width, height) => ({
                                x: -(width / 2),
                                y: -(height / 2)
                            })}
                        >
                            <div>
                                <img className={className} src={imgPath} alt="Logo"/>
                            </div>
                        </OverlayView> 
                        <OverlayView
                            position={{lat : props.location.lat, lng : props.location.lng}}
                            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                            getPixelPositionOffset={(width, height) => ({
                                x: -(width / 2),
                                y: -(70)
                            })}
                        >
                            <div style={{backgroundColor: circleColor, borderRadius: "999px"}}>
                                <p className="labelText">Garé en {label}</p>
                            </div>
                        </OverlayView>
                    </div>
                : null }
            </div>
        )
    } else {
        return (
            <div></div>
        )
    }
})

export default SparkedCocoMarker
