const initialState = {lat: 0, lng: 0}

export const UPDATE_LABEL_POSITION = 'UPDATE_LABEL_POSITION'

export function labelPositionReducer (state = initialState, action) {
    switch (action.type){
        case UPDATE_LABEL_POSITION:
            return action.payload
        default: return state
    }
}