import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

//COMPONENTS
import GreenButton from '../global/GreenButton';

//REDUX SELECTOR
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'

//REDUX ACTION
import { toggleTimeToParkContainer } from './state/displayTimeToParkContainerAction'

//UTILS
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//STYLE
import {BeenhereOutlined } from '@material-ui/icons';

function SparkedButton() {

    const dispatch = useDispatch()

    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)

    const style = {
        marginRight: "10px", 
        width: "50px",
        height: "50px",
        zIndex: "1"
    }

    function displayTimeToParkContainer(){
        dispatch(toggleTimeToParkContainer(true))
    }

    return (
        <div style={style}>
            <GreenButton 
                icon={
                    <BeenhereOutlined style={{color: "white", fontSize: "30"}}
                        onClick={()=> {displayTimeToParkContainer(); addActionToDbBasedOnElement("sparkedButton", trackingIdAndAnonymousLocal, trackingSessionId)}}
                    />
                }
            /> 
        </div>
    )
    
}

export default SparkedButton
