import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import store from './store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { getCustomerConfig } from './api/configApi'
import { setConfig } from './redux/reducers/configReducer';
import { storeUserLocationInLS } from './global/GlobalUtils';

import 'bootstrap/dist/css/bootstrap.css';
// Put any other imports below so that CSS from your
// components takes precedence over default styles.

const fetchData = async () => {
    const customerFromUrl = new URL(window.location.href).searchParams.get('source');
    const customer = customerFromUrl == 'ampqrcode' ? 'amp' : 'default'
    return getCustomerConfig(customer)
}

const Loader = () => <div>Loading...</div>;

const Main = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadData = async () => {
            try {
                const result = await fetchData();
                if (!result.geolocalisation_searchbar) {
                    storeUserLocationInLS(result.map_center)
                } else {
                    storeUserLocationInLS({lat: 48.8575475, lng: 2.3513765}) // Paris
                }
                store.dispatch(setConfig(result));
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        loadData();
    }, []);

    if (isLoading) {
        return <Loader />;
    }

    if (error) {
        return <div>Erreur : {error}</div>;
    }

    return <App />;
}

ReactDOM.render(
//   <React.StrictMode>
    <Provider store={store}>
        <Main />
    </Provider>,
// </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
