const initialState = null

export const UPDATE_USER_LOCATION = 'UPDATE_USER_LOCATION'

export function userLocationReducer (state = initialState, action) {
    switch (action.type){
        case UPDATE_USER_LOCATION:
            return action.payload
        default: return state
    }
}