import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';

//FIREBASE
import { startFirebaseUI } from '../firebaseConfig'
import firebase from '../firebaseConfig';

//COMPONENTS
import UserAddressesContainer from './UserAddressesContainer';
import BadgeUserMenuPopup from '../popup/BadgeUserMenuPopup';

//REDUX SELECTOR
import { userInfoJsonSelector } from '../userMenu/state/userInfoJsonSelector';
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'

//REDUX ACTIONS
import { updateUserInfoJson } from '../userMenu/state/userInfoJsonAction'

//UTILS
import { postCarType, postFuelType } from '../api/userApi';
import { getUser } from '../api/authApi';
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//STYLES
import * as AiIcon from 'react-icons/ai';
import * as MdIcon from 'react-icons/md';
import * as ImIcon from 'react-icons/im';
import './MenuUserConnected.css'

function MenuUserConnected(props) {

    const dispatch = useDispatch()

    let vehiculeType = ["Citadine","S.U.V.","", "Berline", "Break"]
    let vehiculeTypeId = ["citadine","SUV", "breaker", "berline", "break"]
    let fuelType =[
        {imageGreenPath: "images/userPersonnalize/elec-green.png", imageWhitePath: "images/userPersonnalize/elec-white.png"},
        {imageGreenPath: "images/userPersonnalize/hybrid-green.png", imageWhitePath: "images/userPersonnalize/hybrid-white.png"},
        {imageGreenPath: "images/userPersonnalize/fuel-green.png", imageWhitePath: "images/userPersonnalize/fuel-white.png"},
    ]
    let fuelTypeName = ["electric", "hybrid", "fuel"] //used to match fuelType data from firebase with the images
    let imageProfilUrl = "images/userPersonnalize/empty-profil.png"

    const userInfoJson = useSelector(userInfoJsonSelector)
    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)

    //to hide/show badge legend popup
    const [showPopupPioneer, setShowPopupPioneer] = useState(false)

    //to display user profil picture if there is one
    if(userInfoJson.photoURL){
        imageProfilUrl=userInfoJson.photoURL
    }

    //to signOut from Firebase
    function signOutFromFirebase(){
        firebase.auth().signOut().then(() => {
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        });
    }

    async function updateCarType(type){
        await postCarType(userInfoJson.uid, type)
        updateFrontWithUpdatedData(userInfoJson.uid)
    }

    async function updateFuelType(type){
        await postFuelType(userInfoJson.uid, type)
        updateFrontWithUpdatedData(userInfoJson.uid)
    }

    async function updateFrontWithUpdatedData(uid){
        let userFromDb = await getUser(uid)
        dispatch(updateUserInfoJson({...userFromDb, uid: uid}))
    }

    useEffect(() => {
        return () => {
            //to relaunch firebase ui when component unmonted
            startFirebaseUI('#firebaseui')
        }
    }, [])

    return (
        <div className="flex-flex-start-column user-connected-container">
            <p className="welcome-message" style={{marginTop: "60px"}}>Good to see you Coco !</p>
            <p style={{fontSize: "14px", fontWeight: "bold"}}>{userInfoJson.displayName}</p>
            <img src={imageProfilUrl} alt="Logo" className="avatar"/>
            <p style={{fontSize: "16px", fontWeight: "bold", marginTop: "10px", marginBottom: "0px"}}>{userInfoJson.email}</p>
            {/* DIV MY PROFIL */}
            <div className="flex-center-column" style={{borderTop: "1px solid var(--cocoparksGreen)", width: "90%", margin: "20px"}}>
            </div>
            <p className="time-to-park-text" style={{color: "black", textAlign: "center"}}>Mon profil</p>
            <div className="badge-container"
                onClick={()=> {
                    setShowPopupPioneer(true); 
                    addActionToDbBasedOnElement("openPionnierLoginNav", trackingIdAndAnonymousLocal, trackingSessionId)
                }}
            >
                <AiIcon.AiFillStar style={{fontSize: "10px"}}/>
                <p className="badge-text">Pionnier</p>
                <AiIcon.AiFillStar style={{fontSize: "10px"}}/>
            </div>
            <div className="flex-center-inline title-user-connected">
                <MdIcon.MdDriveEta 
                    style={{color: "var(--cocoparksGreen)", width: "25px"}}
                />
                <p className="no-margin green-title-user-connected">Mon véhicule</p>
            </div>
            <div className="vehicule-type-container" onClick={()=>addActionToDbBasedOnElement("profileContainerVehicule", trackingIdAndAnonymousLocal, trackingSessionId)}>
                {vehiculeTypeId.map((type, index)=>{
                    //to check if type match user car type to display this button in green
                    if(type === userInfoJson.carType){
                        return(
                            <div className="flex-center-inline"  style={{width: '50%'}} key={index}>
                                <button className="green-button-user-connected-selected" >
                                    <p className="green-button-text">{vehiculeType[index]}</p>
                                </button>
                            </div>
                        )
                    } else {
                        if(type === "breaker"){
                            return(
                                <div key={index} style={{width: "100%"}}></div>
                            )
                        }else {
                            return(
                                <div className="flex-center-inline" style={{width: '50%'}} key={index}>
                                    <button className="green-button-user-connected" onClick={()=> updateCarType(type)}>
                                        <p className="green-button-text">{vehiculeType[index]}</p>
                                    </button>
                                </div>
                            )
                        }
                    }
                })}
            </div>
            <div className="flex-center-inline title-user-connected">
                <ImIcon.ImPower 
                    style={{color: "var(--cocoparksGreen)", width: "12px"}}
                />
                <p className="no-margin green-title-user-connected">Mon carburant</p>
            </div>
            <div className="fuel-type-container" onClick={()=>addActionToDbBasedOnElement("profileContainerFuel", trackingIdAndAnonymousLocal, trackingSessionId)}>
                {fuelTypeName.map((type, index)=>{
                    //to check if type match user fuel type to display this button in green
                    if(type === userInfoJson.fuelType){
                        return(
                            <button className="green-button-user-connected-selected" key={index}>
                                <img src={fuelType[index].imageWhitePath} alt=""/>
                            </button>
                        )
                    } else {
                        return(
                            <button className="green-button-user-connected" key={index} onClick={()=> updateFuelType(type)}>
                                <img src={fuelType[index].imageGreenPath} alt=""/>
                            </button>
                        )
                    }
                })}
            </div>
            {/* DIV ADDRESSES */}
            <div className="flex-center-column" style={{borderTop: "1px solid var(--cocoparksGreen)", width: "90%", margin: "20px"}}>
            </div>
            <p className="time-to-park-text" style={{color: "black", textAlign: "center"}}>Mes adresses</p>
            <UserAddressesContainer allowModification={true} map={props.map}/>
            {/* DIV END SESSION */}
            <div className="flex-center-column" style={{borderTop: "1px solid var(--cocoparksGreen)", width: "90%", margin: "20px"}}>
            </div>
            <button className="signout-button" onClick={()=> {signOutFromFirebase(); addActionToDbBasedOnElement("signout-section", trackingIdAndAnonymousLocal, trackingSessionId)}}>Fermer la session</button>
            {showPopupPioneer ? <BadgeUserMenuPopup setTrigger={setShowPopupPioneer}/> : null}
        </div>
    )
}

export default MenuUserConnected
