import React from 'react'
import { useSelector } from 'react-redux'

//REDUX SELECTORS
import { liveIndicSelector } from './state/liveIndicSelector'

function LiveIndic() {
    let liveIndicSvgClassNameFirst = ""
    let liveIndicSvgClassNameSecond = ""

    const liveIndic = useSelector(liveIndicSelector)

    if(liveIndic){
        liveIndicSvgClassNameFirst = "liveIndic_svg_first"
        liveIndicSvgClassNameSecond = "liveIndic_svg_second"
    } else {
        liveIndicSvgClassNameFirst = ""
        liveIndicSvgClassNameSecond = ""
    }
    return (
        <div className="liveIndic" style={{width: "15px"}}>
            <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><defs></defs>
                <path fill="#ffc107" className={liveIndicSvgClassNameFirst} d="M268.2,157a3,3,0,0,1-3,3,20.94,20.94,0,0,0-14.84,6.16l-.35.36c-.35.37-.68.74-1,1.13l-.48.59a20.79,20.79,0,0,0-4.32,12.47,2.81,2.81,0,0,0,0,.29,3,3,0,1,1-6,0,26.83,26.83,0,0,1,2.52-11.4,27.28,27.28,0,0,1,5.39-7.69,26.92,26.92,0,0,1,19.08-7.9A3,3,0,0,1,268.2,157Z" transform="translate(-238.21 -154.05)"/>
                <path fill="#ffc107" className={liveIndicSvgClassNameSecond} d="M268.21,169a3,3,0,0,1-3,3,9,9,0,0,0-9,9,3,3,0,1,1-6,0,15,15,0,0,1,15-15A3,3,0,0,1,268.21,169Z" transform="translate(-238.21 -154.05)"/>
                <path fill="#ffc107" className={liveIndicSvgClassNameSecond} d="M244.21,180.75a20.79,20.79,0,0,1,4.32-12.47A20.88,20.88,0,0,0,244.21,180.75Z" transform="translate(-238.21 -154.05)"/>
                <path fill="#ffc107" className={liveIndicSvgClassNameFirst} d="M249,167.69l-.48.59Z" transform="translate(-238.21 -154.05)"/>
            </svg>
        </div>
    )
}

export default LiveIndic
