import React from 'react';
import * as FiIcons from 'react-icons/fi';
import * as RiIcons from 'react-icons/ri';
import * as TiIcons from 'react-icons/ti';
import * as IoIcons from 'react-icons/io5';
import History from '../history/History';
import Feedback from '../feedback/Feedback';
import Help from '../help/Help';
import ShareApp from '../shareApp/ShareApp';

export const SidebarData = [
  
  {
    title: 'Map Live',
    key: 'maplive', 
    icon: <FiIcons.FiMap />,
  },
  {
    title: 'Mes stationnements',
    key: 'history', 
    icon: <RiIcons.RiMapPinTimeLine />,
    component: <History/>

  },
  {
    title: 'Aide',
    key: 'help', 
    icon: <FiIcons.FiHelpCircle />,
    component: <Help/>

  },
  {
    title: 'Feedback',
    key: 'feedback', 
    icon: <TiIcons.TiMessages />,
    component: <Feedback/>

  },
  {
    title: "Partager l'appli",
    key: 'share', 
    icon: <IoIcons.IoShareSocial />,
    component: <ShareApp/>

  }
  
];