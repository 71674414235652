import React from 'react'

//STYLE
import * as IoIcon from 'react-icons/io5';
import './ParkingRealTimeLegendPopup.css'


function HelpActivateGeolocPopup(props) {

    let linkGoogleSupport = "https://support.google.com/chrome/answer/114662?visit_id=637412957534986471-1967650892&rd=1&co=GENIE.Platform%3DAndroid&oco=1"

    return (
        <div className="popup">
            <div className="popup-inner"> 
                <div>
                    <div className="flex-space-between-inline alertDiv-title-realtime">
                        <p className="white-title">Activer la géolocalisations</p>
                        <IoIcon.IoClose 
                            className='menu-bars' 
                            style={{color: "white"}}
                            onClick={()=> props.setTrigger(false)}
                        />
                    </div>
                    <div className="alertDiv-content">
                        <p className="" style={{color: "var(--dark-grey)"}}>Pour activer la géolocalisation pour Cocoparks, suivez les instructions de votre navigateur puis actualisez l'application.</p>
                        <a href={linkGoogleSupport} target="_blank" rel="noreferrer" style={{fontStyle: "italic", color: "var(--dark-grey)", textDecoration: "underline"}}>Plus d'informations</a>
                        <button className="alertDiv-closeDiv" style={{marginBottom: "15px"}} 
                            onClick={()=> document.location.reload(true)
                            }>
                            <span>Actualiser</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HelpActivateGeolocPopup
