export const RECALCULATE_ITINERARY = 'RECALCULATE_ITINERARY'
export const RESET_ITINERARY = 'RESET_ITINERARY'

export function displayItineraryReducer(state = 0, action) {
    switch (action.type){
        case RECALCULATE_ITINERARY:
            return state + 1
        case RESET_ITINERARY:
            return 0
        default: return state
    }
}