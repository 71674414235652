import React from 'react'
import { useSelector } from 'react-redux'

//REDUX SELECTOR
import { sparkedModeSelector } from '../global/state/sparkedModeSelector'
import { sparkedAddressSelector} from '../sparkedMode/state/sparkedAddressSelector'
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'

//UTILS
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//STYLE
import './ShareApp.css'

//JSON
import shareAppMessageText from './shareAppMessageText.json'

//to display each app item with their correct name and link
function AppItem({name, imageSrc, url, id}){

    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)

    return(
        <div className="flex-center-column share-div" 
            onClick={()=>addActionToDbBasedOnElement(id, trackingIdAndAnonymousLocal, trackingSessionId)}
        >
            <a className="share-link" target="_blank" data-body="none" href={url} rel="noreferrer">
                <img className="share-img" src={imageSrc} alt="messenger"/>
                <p className="share-text">{name}</p>
            </a>
        </div>
    )
}

function ShareApp() {

    const sparkedMode = useSelector(sparkedModeSelector)
    const sparkedAddress = useSelector(sparkedAddressSelector)

    //to create the content of the message based on the actual sparked postion
    function createMessageContent(address){
        let messageToShare
        let linkToAddress = address.replace(/ /g, '+')
        linkToAddress = linkToAddress.replace(/,/g, '')
        linkToAddress = 'https://app.cocoparks.io/?address=' + linkToAddress
        messageToShare = 'Je suis garé au ' + address + '. Retrouve directement ce lieu en cliquant sur ce lien: ' + linkToAddress + '. Envoyé depuis Cocoparks.'
        messageToShare = encodeURIComponent(messageToShare)
        return messageToShare
    }

    let allAppItems = Object.keys(shareAppMessageText).map((app, index) => {
        let url = shareAppMessageText[app].shareAppUrl
        //check if sparked mode is active to share link from position instead of cocoparks app link
        if(sparkedMode.value){
            let message = createMessageContent(sparkedAddress.fulladdress)
            if(shareAppMessageText[app].name !== "Facebook" && shareAppMessageText[app].name !== "Messenger"){
                //to get the array to combine to create the link
                let linkArray = [...shareAppMessageText[app].sharePositionUrl]
                //push the message at the index 1 because some link from the json has a start and an endand the message has to be between them
                linkArray.splice(1, 0, message);
                url = linkArray.join('')
            }
        }
        return(
            <AppItem 
                name={shareAppMessageText[app].name} 
                imageSrc={shareAppMessageText[app].imageSrc} 
                url={url}
                key={index}
                id={shareAppMessageText[app].id}
            />)
        })

    return (
        <div style={{backgroundColor: "white", width: "100vw"}}>
            <div className="flex-align-top-inline">
                <h3 className="title">Partager l'application</h3>
            </div>
            <div className="flex-center-inline" style={{height: "50%", flexWrap: "wrap"}}>
                {allAppItems}
            </div>
        </div>
    )
}

export default ShareApp
