import React from 'react'
import './ParkingRealTimeLegendPopup.css'


function ParkingRealTimeLegendPopup(props) {
    return (
        <div className="popup" onClick={()=> props.setTrigger(false)}>
            <div className="popup-inner"> 
                <div>
                    <div className="flex-space-between-inline alertDiv-title-realtime">
                        <p className="white-title">Information temps réel des parkings</p>
                    </div>
                    <div className="alertDiv-content">
                        <p className="alertDiv-subtitle-realtime" style={{marginTop: "15px"}}>Cocoparks vous indique en temps réel la disponibilité des parkings de votre ville</p>
                        <div style={{marginTop: "30px", marginBottom: "30px"}}>
                            <div className="live-nb-parking-spot-legend-row">
                                <span className="live-nb-parking-spot-legend-color live-nb-parking-spot-grey"></span>
                                <p className="live-nb-parking-spot-legend-text">Ce parking ne fournit pas l'information temps réel</p>
                            </div>
                            <div className="live-nb-parking-spot-legend-row">
                                <span className="live-nb-parking-spot-legend-color live-nb-parking-spot-green"></span>
                                <p className="live-nb-parking-spot-legend-text">Disponible</p>
                            </div>
                            <div className="live-nb-parking-spot-legend-row">
                                <span className="live-nb-parking-spot-legend-color live-nb-parking-spot-orange"></span>
                                <p className="live-nb-parking-spot-legend-text">Reste moins de 10% des places</p>
                            </div>
                            <div className="live-nb-parking-spot-legend-row">
                                <span className="live-nb-parking-spot-legend-color live-nb-parking-spot-red"></span>
                                <p className="live-nb-parking-spot-legend-text">Parking complet</p>
                                </div>
                        </div>
                        <button className="alertDiv-closeDiv" style={{marginBottom: "15px"}} onClick={()=> props.setTrigger(false)}>
                            <span>Fermer</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ParkingRealTimeLegendPopup
