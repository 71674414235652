import React, { useEffect, useState } from 'react'
import { OverlayView, Marker} from '@react-google-maps/api'
import { useDispatch } from 'react-redux';


//REDUX ACTION
import { updateFreedSlotMarkerData } from './state/freedSpotMarkerDataAction'

//UTILS
import { updateDurationFreedSpot } from '../global/SparkedModeUtils'

//STYLE
import './FreedSpotMarker.css'


function FreedSpotMarker(props) {

    const dispatch = useDispatch()

    let freedSpotMaxLifetime = 5*60*1000 //5min 
    let position = {lat : props.position.lat, lng : props.position.lng}
    let image = {
        url: "images/markers/FreedSpot-mkr.png",
        scaledSize: new window.google.maps.Size(35, 45), // scaled size
    };

    const [durationFreedSpot, setDurationFreedSpot] = useState("A l'instant")

    useEffect(() => {
        let interval
        if(props.start !== null){
            //to update freed spot on overlay div
            interval = setInterval(() => {
                let start = new Date(props.start);
                let currentTime = new Date();
                //to calculate remainingTime time based on current time and starting time
                let remainingTime = freedSpotMaxLifetime - (currentTime - start);
                let resp = updateDurationFreedSpot(props.start)
                //to update actual time
                setDurationFreedSpot(resp)
                //to hide overlay if the slot has been freed for more than 5min
                if(remainingTime < 0){
                    clearInterval(interval)
                    dispatch(updateFreedSlotMarkerData(props.index))
                }
            }, 1000 * 5);
        }
        return () => clearInterval(interval);
    }, [])


    return (
        <>
            <Marker 
                position={position}
                icon={image}
                zIndex={20}
            ></Marker>
            <OverlayView
                position={position}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={(width, height) => ({
                    x: -(width / 2),
                    y: -90
                })}
            >
            <div>
                <div className="label-freed-spot">Libérée il y a:
                    <p className="actual-freed-time">{durationFreedSpot}</p>
                </div>
            </div>
            </OverlayView>
        </>
    )
    
}

export default FreedSpotMarker
