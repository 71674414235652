import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'

//COMPONENTS
import UserAddressesContainer from '../userMenu/UserAddressesContainer'

//REDUX SELECTORS
import { parkingMarkerIdSelector } from '../markers/state/parkingMarkerIdSelector'
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector'
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'
import { displayParkingSpecificDetailPanelSelector } from '../detailsPanel/state/displayParkingSpecificDetailPanelSelector'
import { displayLvzSpecificDetailPanelSelector } from '../detailsPanel/state/displayLvzSpecificDetailPanelSelector'

//REDUX ACTION
import { toggleExpandedDetailPanel } from '../detailsPanel/state/detailsPanelExpandedAction'
import { toggleResearchMode } from '../global/state/researchModeAction'
import { showCocoSearchMarker } from '../markers/state/cocoSearchMarkerAction'
import { hideFavoritesAddressesDiv } from './state/displayFavoriteAndRecentAddressesAction'
import { updateParkingMarkerData } from '../markers/state/parkingMarkerDataAction'
import { updateMarkerId } from "../markers/state/parkingMarkerIdAction";
import { storeLiveParkingsAvailabilities } from '../global/state/liveParkingAvailabilitiesAction'
import { toggleLvzSpecificDetailPanel } from '../detailsPanel/state/displayLvzSpecificDetailPanelAction'
import { toggleParkingSpecificDetailPanel } from '../detailsPanel/state/displayParkingSpecificDetailPanelAction'
import { showDetailPanelContainer } from '../detailsPanel/state/displayDetailsPanelContainerAction'
import { resetClosestLvzInfo } from '../detailsPanel/state/closestLvzInfoAction';
import { resetClosestParkingsInfo } from '../detailsPanel/state/closestParkingsInfoAction';
import { resetClosestLvzPaths } from '../detailsPanel/state/closestLvzPathsAction'

//UTILS
import { getParkingData, getParkingsSpotsAvailabilities } from '../api/mapApi';
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//STYLE
import './FavoriteAndRecentAdresses.css'
import * as MdIcon from 'react-icons/md';
import * as AiIcon from 'react-icons/ai';


function FavoriteAndRecentAdresses(props) {
    
    
    //to switch from favorites to recents
    const [switchFavoriteRecentAdresses, setSwitchFavoriteRecentAdresses] = useState("recent")
    //to store all recents Addresses
    const [allRecentsAdresses, setAllRecentsAdresses] = useState([])
    
    const dispatch = useDispatch()

    const parkingMarkerId = useSelector(parkingMarkerIdSelector)
    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)
    const displayParkingSpecificDetailPanel = useSelector(displayParkingSpecificDetailPanelSelector)
    const displayLvzSpecificDetailPanel = useSelector(displayLvzSpecificDetailPanelSelector)

    //to get last search from local storage each time user clicks on "recents" button
    useEffect(() => {
        if(switchFavoriteRecentAdresses === "recent"){
            var lssearch = JSON.parse(localStorage.getItem('searched'));
            let array = []
            let numberOfAdresses
            if(lssearch != null){
                if(lssearch.length >= 5){
                    numberOfAdresses = 5
                } else {
                    numberOfAdresses = lssearch.length
                }
                for(let i=0; i<numberOfAdresses; i++){
                    array.push(lssearch[i])
                }
                setAllRecentsAdresses(array)
            }
        }
    }, [switchFavoriteRecentAdresses])


    const fetchParkingData = async (center) => {
        await getParkingData(center)
        .then(async function(response){
            if(response !== undefined && response.results !== -1){
                //set markersIds with the id of all fetched parking
                await dispatch(updateParkingMarkerData(response.results.parkings))
                let array = parkingMarkerId
                response.results.parkings.forEach((parking)=>{
                    array.push(parking.index)
                })
                let uniqueArrayMarkersIds = array.filter(function(item, pos, self) {
                    return self.indexOf(item) === pos;
                })
                dispatch(updateMarkerId(uniqueArrayMarkersIds))
                await getParkingsSpotsAvailabilities(uniqueArrayMarkersIds)
                .then(function(response){
                    dispatch(storeLiveParkingsAvailabilities(response.results)) 
                })
            }
        })
    };


    //to update expanded detail panel based on item clicked
    async function updateExpandedDetailsPanel(location){
        dispatch(resetClosestLvzInfo())
        dispatch(resetClosestLvzPaths())
        dispatch(resetClosestParkingsInfo())
        props.map.panTo(location)
        await fetchParkingData(location)
        dispatch(showDetailPanelContainer())
        if(displayParkingSpecificDetailPanel){
            dispatch(toggleParkingSpecificDetailPanel(false))
        } 
        if(displayLvzSpecificDetailPanel.value){
            dispatch(toggleLvzSpecificDetailPanel({value: false, name: ""}))
        }
        dispatch(toggleResearchMode({value: true, location: location}))
        dispatch(toggleExpandedDetailPanel(true))
        dispatch(showCocoSearchMarker(location))
        dispatch(hideFavoritesAddressesDiv())
    }
    

    //to display list of recent Addresses
    function RecentItem ({address}){
        return (
            <li onClick={()=>{updateExpandedDetailsPanel(address.location)}}>
                <MdIcon.MdHistory className="history-button-icon-item"/>
                {address.address}
            </li>)
    }


    return (
        <div>
            <div className="flex-space-around-inline" style={{padding: "5px"}}>
                <button className={"fav-rec-Addresses-button" + (switchFavoriteRecentAdresses === "recent" ? " selected-button" : "")}
                    onClick={()=> {setSwitchFavoriteRecentAdresses("recent"); addActionToDbBasedOnElement("recentAddress", trackingIdAndAnonymousLocal, trackingSessionId)}}
                >
                    <MdIcon.MdHistory className="history-button-icon"/>
                    Récents
                </button>
                <button className={"fav-rec-Addresses-button" + (switchFavoriteRecentAdresses === "favorite" ? " selected-button" : "")}
                    onClick={()=> {setSwitchFavoriteRecentAdresses("favorite"); addActionToDbBasedOnElement("favoriteAddress", trackingIdAndAnonymousLocal, trackingSessionId)}}
                >
                    <AiIcon.AiFillStar className="history-button-icon star"/>
                    Favoris
                </button>
            </div>
            {switchFavoriteRecentAdresses === "recent" ? 
                <div className="recent-Addresses-list">
                    <ul>
                        {allRecentsAdresses.map((address, index) =>{ 
                            return (
                                <RecentItem address={address} key={index}></RecentItem>
                            )}
                        )}
                    </ul>
                </div> :
                <UserAddressesContainer allowModification={false} map={props.map}/>
            }
        </div>
    )
}



export default FavoriteAndRecentAdresses
