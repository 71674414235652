import { useEffect, useState } from 'react'
import './FullGreenButton.css'

function FullGreenButton({text, imgSrc, imgAlt, imgSize, isDisabled, onClickFunction}) {

    useEffect(() => {
        setButtonDisabled(isDisabled)
    }, [])

    const [buttonDisabled, setButtonDisabled] = useState(false)

    return (
        <button className="full-green-button flex-space-around-inline" disabled={buttonDisabled}
            onClick={onClickFunction}
        >
            <p>{text}</p>
            <img 
                style={{width: imgSize}} 
                src={imgSrc} 
                alt={imgAlt}
            />
        </button>
    )
}

export default FullGreenButton
