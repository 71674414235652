//to save searched in local storage
export function saveSearchedAddressToLS(address, location){
    //console.log("save searched address")
    var lssearch = JSON.parse(localStorage.getItem('searched'));
    if(lssearch !== null && lssearch !== ""){
        //to remove the last element of the array if there are already 5
        if(lssearch.length === 50){
            lssearch.pop()
        }
        //to store the new adresse at the start of the array
        lssearch.unshift({address: address, location: location});
        localStorage.setItem('searched', JSON.stringify(lssearch));
    }else{
        var addressArray = [];
        addressArray.push({address: address, location: location});
        localStorage.setItem('searched', JSON.stringify(addressArray));
    }
}