const SET_CONFIG = 'SET_CONFIG';
const UPDATE_CONFIG = 'UPDATE_CONFIG';

const initialState = {
  customer: 'default',
  onboarding_step_1: true,
  onboarding_step_2: true,
  onboarding_step_3: true,
  onboarding_step_4: true,
  geolocalisation_searchbar: true,
  map_center: {
    lat: 43.44606160702613,
    lng: 5.222949337545013,
  },
  current_use_case_request: false,
  app_install_push: false,
  survey: true,
  parking_list: true,
  filters: {
    pmr: false,
    carwash: false,
    booking: false,
    evh: false,
    delivery: false,
  },
  zoom: 15,
  info : false,

  loading: false,
  error: null,
}

export const setConfig = (config) => ({
  type: SET_CONFIG,
  payload: config,
});

export const updateConfig = (field, value) => ({
  type: UPDATE_CONFIG,
  payload: { field, value },
});

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONFIG:
      return {
        ...state,  // Garder l'état existant
        ...action.payload,
      };
      
    case UPDATE_CONFIG:
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
      
    default:
      return state;
  }
};

export default configReducer;
