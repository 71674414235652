import { useSelector } from 'react-redux'

//REDUX SELECTOR
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector';
import { userLocationSelector } from '../markers/state/userLocationSelector';

//UTILS
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//STYLE
import './BackToUserLocationButton.css'
import * as BiIcon from 'react-icons/bi';

function BackToUserLocationButton(props) {

    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)
    const userLocation = useSelector(userLocationSelector)

    const style = {
        marginRight: "10px", 
        width: "50px",
        height: "50px",
        zIndex: "1"
    }

    return (
        <div style={style}>
            <button className="target-button flex-center-inline" 
                onClick={()=> {props.map.panTo(userLocation); addActionToDbBasedOnElement("researchButton", trackingIdAndAnonymousLocal, trackingSessionId)}}
                >
                <BiIcon.BiTargetLock 
                    style={{color: "white"}}
                    size={35}
                />
            </button>
        </div>
    )
}

export default BackToUserLocationButton
