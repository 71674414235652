function stringToHHcomasMM(str) {
    var sec_num = parseInt(str, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+ hours;}
    // if (minutes < 10) {minutes = minutes;}
    if (seconds < 10) {
        if(minutes >= 1){
            seconds = "0"+seconds;
        }
        // else{
        //     seconds = seconds;
        // }
    }

    var response;
    if(minutes < 1){
        response = seconds +'s';
    }else{
        response = minutes+"'"+seconds;
    }
    return response;
}

export function drawArc(center, initial, final, radius){
    var spherical = window.google.maps.geometry.spherical;
    // initialize array
    var res = [];
    res.push(center);

    var points = 30;
    var deltaAngle = (final - initial)/points;
    var i = 0;
    var angle = initial;
    while(i < 30){
        angle = initial + i*deltaAngle;
        var newpoint = spherical.computeOffset(center, radius, angle);
        res.push(newpoint);
        i = i+1;
    }
    return res
}


//to rotate torch based on bestAngle from back
export function rotateImageCSSToAdd(angle) {
    var rotateString = 'rotate(' + angle.toString() + 'deg)';
    return rotateString;
}

/**
 * To track current segment and the next one during the itinerary
 * @param {int} startIndex - last passed index
 *
 */
export function updateOngoingKeyPoints(startIndex, currentPolylineItinerary, ongoingKeyPoints){
    ongoingKeyPoints['KP0'] = currentPolylineItinerary[startIndex];
    ongoingKeyPoints['KP1'] = currentPolylineItinerary[startIndex+1];
    ongoingKeyPoints['KP2'] = currentPolylineItinerary[startIndex+2];
}

/**
 *
 * @param {time} start - absolute value Date.now()
 */
export function updateDurationHHMM(start){
    var duration = (Date.now() - start)/1000;
    var str_duration = duration.toString();
    var str = stringToHHcomasMM(str_duration);
    return str
}