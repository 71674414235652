const initialState = {status: false, key: ""}

export const SELECT_PARKING_MARKER = 'SELECT_PARKING_MARKER'
export const RESET_PARKING_MARKER = 'RESET_PARKING_MARKER'

export function selectedParkingMarkerReducer (state = initialState, action) {
    switch (action.type){
        case SELECT_PARKING_MARKER:
            return action.payload
        case RESET_PARKING_MARKER:
            return initialState
        default: return state
    }
}