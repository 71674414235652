import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//REDUX SELECTOR
import { nbOfSlotsPassedSelector } from './state/nbOfSlotsPassedSelector';
import { aroundMeModeSelector } from '../global/state/aroundMeModeSelector';

//REDUX ACTIONS
import { toggleConfirmationLeavingItinerary } from '../itinerary/state/displayConfirmationLeavingItineraryAction';
import { updateStartOfLastItinerarySearch } from '../itinerary/state/startOfLastItinerarySearchAction';
import { toggleGoingToCloseParking } from '../itinerary/state/displayGoingToCloseParkingAction'

//UTILS
import { updateDurationHHMM } from '../global/ItineraryUtils'

//STYLE
import './ItineraryPanel.css'
import * as IoIcon from 'react-icons/io5';


function ItineraryPanel() {

    const dispatch = useDispatch()

    const nbOfSlotsPassed = useSelector(nbOfSlotsPassedSelector)
    const aroundMeMode = useSelector(aroundMeModeSelector)

    const [itinerarySearchDuration, setItinerarySearchDuration] = useState("-")

    // to deactivate itinerary mode
    function deactivateTorch(){
        dispatch(toggleConfirmationLeavingItinerary(true))
    }


    //to display the window asking to go parking
    function displayGoingToClosestParking(){
        dispatch(toggleGoingToCloseParking(true))
    }


    useEffect(() => {
        if(aroundMeMode){
            setItinerarySearchDuration("0s")
        }
        let start = Date.now();
        dispatch(updateStartOfLastItinerarySearch(start))
        let interval = setInterval(() => {
            let resp = updateDurationHHMM(start)
            //to update actual time
            setItinerarySearchDuration(resp)
        }, 20 * 1000);
        return () => clearInterval(interval);
    }, [])

    return ( 
        <>
            <div id="itinerary-panel">
                <div className="close-button">
                    <button className="white-button flex-center-inline" onClick={()=> deactivateTorch()}>
                        <IoIcon.IoClose style={{color: "var(--cocoparksGreen)", height: "40px", width: "40px"}}/>
                    </button>
                </div>
                <div className="itinerary-panel-divs">
                    <div className="itinerary-panel-elapsed-time itinerary-panel-div">
                        <div className="ip-div-title"> TEMPS ECOULE </div>
                        <div className="ip-div-main-content">{itinerarySearchDuration}</div>
                        <div className="ip-div-secondary-content"></div>
                    </div>
                    <div className="itinerary-panel-past-slots itinerary-panel-div">
                        <div className="ip-div-title"> # PLACES VUES </div>
                        <div className="ip-div-main-content"> {nbOfSlotsPassed} </div>
                        <div className="ip-div-secondary-content"> - </div>
                    </div>
                    <div className="itinerary-panel-parking itinerary-panel-div" onClick={()=> displayGoingToClosestParking()}>
                        <div className="ip-div-title"> PARKING</div>
                        <div className="ip-div-main-content">
                            <img src="images/itinerary/parking_arrow.png" alt=""/> 
                        </div>
                        <div className="ip-div-secondary-content"> </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ItineraryPanel
