const initialState = {visible: false, position: {lat: 0, lng: 0}}

export const SHOW_EVH_INFOWINDOW = 'SHOW_EVH_INFOWINDOW'
export const HIDE_EVH_INFOWINDOW = 'HIDE_EVH_INFOWINDOW'

export function evhInfoWindowReducer (state = initialState, action) {
    switch (action.type){
        case SHOW_EVH_INFOWINDOW:
            let displayEvhInfo = {
                visible: true,
                position: {
                    lat: action.payload.location.lat,
                    lng: action.payload.location.lng
                },
                type: action.payload.type
            }
            return displayEvhInfo
        case HIDE_EVH_INFOWINDOW:
            let hideEvhInfo = {
                visible: false,
            }
            return hideEvhInfo
        default: return state
    }
}