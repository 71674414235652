import { STORE_STREET_PARKING_ALL_TIME_INFO, RESET_STREET_PARKING_ALL_TIME_INFO } from "./streetParkingCostAllTimeReducer"

export const storeStreetParkingAllTimeInfo = (streetParkingTime, streetParkingCostAllTime) => ({
    type: STORE_STREET_PARKING_ALL_TIME_INFO,
    payload: {
        streetParkingTime: streetParkingTime,
        streetParkingCostAllTime: streetParkingCostAllTime
    }
})

export const resetStreetParkingAllTimeInfo = () => ({
    type: RESET_STREET_PARKING_ALL_TIME_INFO,
})