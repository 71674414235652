const initialState = { label: "5min", radius: 300 }

export const UPDATE_WALKING_CIRCLE = 'UPDATE_WALKING_CIRCLE'

export function walkingCircleReducer (state = initialState, action) {
    switch (action.type){
        case UPDATE_WALKING_CIRCLE:
            return action.payload
        default: return state
    }
}