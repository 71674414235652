import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

//REDUX SELECTORS
import { filterModeActiveSelector } from "../filters/state/filterModeActiveSelector";
import { mapFilterTrackersSelector } from './state/mapFilterTrackersSelector';
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'

//REDUX ACTIONS
import { updateFilter } from './state/mapFilterTrackersAction'

//UTILS
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

function FilterButton(props) {

    const filterModeActive = useSelector(filterModeActiveSelector)
    const mapFilterTrackers = useSelector(mapFilterTrackersSelector)
    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)

    const dispatch = useDispatch()
    
    let imagePath

    if(props.info.selected){
        imagePath =  "images/parkingFeatures/" + props.info.feature + "-blue.png"
    } else {
        imagePath =  "images/parkingFeatures/" + props.info.feature + ".png"
    }
    
    
    function filterOnFeature(feature){
        dispatch(updateFilter(feature)) 
        if(filterModeActive){
            if(feature === "pmr" && mapFilterTrackers[3]=== false){
                props.info.map.setZoom(16)
            }
            if(feature === "evh" && mapFilterTrackers[2]=== false ){
                props.info.map.setZoom(16)
            }
        } 
    }

    return (
        <button className="hover-button button-filter" onClick={()=>{filterOnFeature(props.info.feature); addActionToDbBasedOnElement(props.info.feature + "Filter", trackingIdAndAnonymousLocal, trackingSessionId)}}>
            <img src={imagePath} alt="Logo"/>
        </button>
    )
}

export default FilterButton
