const initialeState = {
    carType: "",
    fuelType: "",
    savedAddresses: {
        home: null,
        work: null,
        first: null,
        second: null,
        third: null
    }
}

export const UPDATE_USER_INFO_JSON = 'UPDATE_USER_INFO_JSON'
export const RESET_USER_INFO_JSON = "RESET_USER_INFO_JSON"

export function userInfoJsonReducer (state = initialeState, action) {
    switch (action.type){
        case UPDATE_USER_INFO_JSON:
            return action.payload
        case RESET_USER_INFO_JSON:
            return initialeState
        default: return state
    }
}