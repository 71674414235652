import React, {useRef, useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';

//REDUX SELECTOR
import { screenshotImageSpotSelector } from '../sparkedMode/state/screenshotImageSpotSelector';
import { userUidSelector } from '../global/state/userUidSelector';

//REDUX ACTION
import { updateSpotScreenshot } from '../sparkedMode/state/screenshotImageSpotAction'

//STYLE
import './OpenCameraPopup.css'
import * as IoIcon from 'react-icons/io5';


function OpenCameraPopup(props) {

    const dispatch = useDispatch()

    const canvasRef = useRef(null)
    const videoRef = useRef(null)
    const cameraStreamRef = useRef(null)

    const screenshotImageSpot = useSelector(screenshotImageSpotSelector)
    const userUid = useSelector(userUidSelector)

    const [displayImage, setDisplayImage] = useState(false)
    const [displayVideo, setDisplayVideo] = useState(true)
    const [displayErrorPopup, setDisplayErrorPopup] = useState(false)
    const [imageSource, setImageSource] = useState("")
    const [screenshotSource, setScreenshotSource] = useState("")
    const [flashClassname, setFlashClassname] = useState("video-div")

    let facingMode = { exact: "environment" };
    let constraints = {
        audio: false,
        video: {
            facingMode: facingMode
            }
    }


    //checking if user has camera
    function hasGetUserMedia() {
        return !!(navigator.mediaDevices &&
          navigator.mediaDevices.getUserMedia);
    }


    //to display actual view from camera
    async function startCamera(){
        setDisplayVideo(true)
        if(videoRef.current != null){
            videoRef.current.setAttribute('autoplay', '');
            videoRef.current.setAttribute('muted', '');
            videoRef.current.setAttribute('playsinline', '');
        }
        let res = hasGetUserMedia()
        if(res){
            navigator.mediaDevices.getUserMedia(constraints)
            .then(handleSuccess)
            .catch(handleError);
        }
    }


    function takingPicture(){
        
        //to display a flash effect 
        setFlashClassname("fade-in video-div")
        
        //delay to wait for the end of the "flash" to display the new screenshot
        setTimeout(function(){
            setFlashClassname("video-div")
            canvasRef.current.width = videoRef.current.videoWidth;
            canvasRef.current.height = videoRef.current.videoHeight;
            canvasRef.current.getContext('2d').drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
            let image_data_url = canvasRef.current.toDataURL('image/jpeg');
    
            setImageSource(image_data_url)
            setDisplayImage(true)
    
            //get sparked data from LS
            let lsuid = JSON.parse(localStorage.getItem(userUid))
            if(lsuid != null){
                lsuid.lastSparkedData = {...lsuid.lastSparkedData, picture: image_data_url}
                //store image in local storage
                localStorage.setItem(userUid, JSON.stringify(lsuid));
            }
            //update state to display new image
            dispatch(updateSpotScreenshot(image_data_url))
        }, 500)

    }


    //to close the popup and the camera
    function closeScreenshot() {
        if(displayVideo){
            if(videoRef.current != null && cameraStreamRef.current!= null){
                videoRef.current.pause();
                videoRef.current.src = null;
                var i = cameraStreamRef.current.getVideoTracks().length;
                var j = 0;
                while( j <= i){
                    cameraStreamRef.current.getVideoTracks()[0].stop();
                    j = j+1;
                };
            }
        }
    }


    function handleSuccess(stream) {
        videoRef.current.srcObject = stream;
        cameraStreamRef.current = stream;
    }


    function handleError(error) {
        setDisplayErrorPopup(true)
    }


    useEffect(() => {
        //to display the last screenshot if there is one
        //else showing the camera
        if(screenshotImageSpot !== ""){
            setScreenshotSource(screenshotImageSpot)
            setDisplayVideo(false)
        } else {
            startCamera()
        }
    }, [])

    //to display taking picture popup
    //else displaying error popup
    if(!displayErrorPopup){
        return (
            <div className="popup">
                <div className="popup-inner" style={{width: "85%",height: "80%"}}> 
                    <div className = "screenshot">
                        <button className="close-screenshot-button" onClick={()=>{props.setTrigger(false); closeScreenshot()}}>
                            <IoIcon.IoClose 
                                style={{color: "white", width: "30px", height: "30px"}}
                            />
                        </button>
                        <p style={{color: "#1faa00", textAlign:"center", margin: "0px"}}><strong>Prendre votre n° de place</strong><br/>
                        <span style={{color: "grey", fontSize:"10px"}}>La photo sera sauvegardée automatiquement</span></p>
                        <div className={flashClassname}>
                            <video className="video-stream" style={{display: displayVideo ? "" : "none"}} ref={videoRef} autoPlay></video>
                            <canvas className="canvas-stream" style={{display: displayVideo ? "" : "none"}} ref={canvasRef}></canvas>
                            <img className="screenshot-img-displayed" style={{display: displayVideo ? "none" : ""}} src={screenshotSource} alt=""/>
                        </div>
                        {displayVideo ?
                        <button className="screenshot-button" disabled="" onClick={()=> takingPicture()}>Prendre une photo !</button>:
                        <button className="screenshot-button" disabled="" onClick={()=> startCamera()}>Prendre une photo !</button>
                        }
                        {displayImage ? 
                            <div style={{minWidth: "70px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <img className="screenshot-img" src={imageSource} alt=""/> 
                            </div>
                        :null}
                    </div>
                </div>
            </div>
        )
    } else {
        return(
            <div className="popup">
                <div className="popup-inner" style={{width: "80%"}}> 
                    <div className="flex-space-between-inline alertDiv-title-realtime">
                        <p className="white-title">Problème photo</p>
                    </div>
                    <div className="alertDiv-content">
                        <p className="alertDiv-subtitle-realtime" style={{marginTop: "15px"}}>There has been an error when trying to access the camera of the device !</p>
                        <button className="alertDiv-closeDiv" style={{marginBottom: "15px"}} onClick={()=> props.setTrigger(false)}>
                            <span>Fermer</span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }


}

export default OpenCameraPopup
