import React from 'react'
import { useSelector } from 'react-redux'
import { Polyline } from '@react-google-maps/api'

//REDUX SELECTORS
import { directionToDestinationSelector } from './state/directionToDestinationSelector'
import { itineraryModeSelector } from '../global/state/itineraryModeSelector'

function DirectionToDestinationPolyline() {

    let options = {};

    const directionToDestination = useSelector(directionToDestinationSelector)
    const itineraryMode = useSelector(itineraryModeSelector)


    //all polylines options

    if(directionToDestination.style === "dot"){
        var lineSymbol = {
            path: window.google.maps.SymbolPath.CIRCLE,
            strokeColor: "blue",
            strokeOpacity: 0.7,
            strokeWeight: 3,
            fillColor: "blue",
            fillOpacity: 0.4,
            scale: 5,
        };

        options =  {
            strokeOpacity: 0,
            icons: [{
                icon: lineSymbol,
                offset: '0', 
                repeat: '17px'
            }],
        };
    }  else if(directionToDestination.style === "line"){
        options = {
            strokeColor: "blue",
            strokeOpacity: 0.5,
            strokeWeight: 10,
            icons: []
        };
    } else if (directionToDestination.style === "red" && itineraryMode) {
        options = {
            strokeColor: "red",
            strokeOpacity: 0.8,
            strokeWeight: 11,
        }
    } else {
        options = {
            strokeOpacity: 0,
        }; 
    }


    if(directionToDestination.path.length !== 0){
        return (
            <div>
                <Polyline
                    path={directionToDestination.path}
                    options={options}
                />      
            </div>
        )
    } else {
        return(<></>)
    }

}

export default DirectionToDestinationPolyline
