import React from 'react'
import { useDispatch } from 'react-redux'

//REDUX ACTION
import { displayParkingInProgressPopup } from '../popup/state/showParkingInProgressPopUpAction'

function ParkingInProgressPopup() {

    const dispatch = useDispatch()

    function closePopUp(){
        dispatch(displayParkingInProgressPopup(false))
    }
    
    return (
        <div className="popup">
            <div className="popup-inner"> 
                <div>
                    <div className="flex-space-between-inline alertDiv-title-realtime">
                        <p className="white-title">Stationnement en cours</p>
                    </div>
                    <div className="alertDiv-content">
                        <p className="alertDiv-subtitle-realtime" style={{marginTop: "15px"}}>Pour reprendre la navigation sur la carte, vous ne devez pas avoir de stationnement en cours.</p>
                        <button className="alertDiv-closeDiv" style={{marginBottom: "15px"}} onClick={()=> closePopUp()}>
                            <span>Fermer</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ParkingInProgressPopup
