const initialState = 0

export const UPDATE_FRONT_STYLE_ONSTREET_COMMON = 'UPDATE_FRONT_STYLE_ONSTREET_COMMON'

export function frontStyleOnstreetCommonReducer (state = initialState, action) {
    switch (action.type){
        case UPDATE_FRONT_STYLE_ONSTREET_COMMON:
            return action.payload
        default: return state
    }
}