import React, { useState, useEffect} from 'react'
import { useDispatch, useSelector} from 'react-redux';

//COMPONENT
import SparkedOnStreetSuccessScreen from './SparkedOnStreetSuccessScreen';

//REDUX SELECTOR
import { lastTorchGeolocationSelector } from './state/lastTorchGeolocationSelector';
import { itineraryPathSelector } from './state/itineraryPathSelector';
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'

//REDUX ACTIONS
import { toggleItineraryMode } from '../global/state/itineraryModeAction';
import { showDetailPanelContainer } from '../detailsPanel/state/displayDetailsPanelContainerAction';
import { toggleConfirmationLeavingItinerary } from '../itinerary/state/displayConfirmationLeavingItineraryAction';
import { updateNbOfSlotsPassed } from './state/nbOfSlotsPassedAction';
import { resetPathToDestination } from '../markers/state/directionToDestinationAction';
import { resetItineraryPath } from './state/itineraryPathAction';
import { recalculateItinerary, resetItinerary } from '../itinerary/state/displayItineraryAction'
import { toggleTimeToParkContainer } from '../sparkedMode/state/displayTimeToParkContainerAction'
import { resetSparkedOnStreetSuccess } from './state/displaySparkedOnStreetSuccessAction';

//UTILS
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//STYLE
import * as IoIcon from 'react-icons/io5';

function ConfirmationLeavingItinerary(props) {

    const [recalculateButtonDisabled, setRecalculateButtonDisabled] = useState(true)

    const lastTorchGeolocation = useSelector(lastTorchGeolocationSelector)
    const itineraryPath = useSelector(itineraryPathSelector)
    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)

    const dispatch = useDispatch()

    function confirmLeavingItinerary(){
        dispatch(toggleItineraryMode(false))
        dispatch(showDetailPanelContainer())
        dispatch(toggleConfirmationLeavingItinerary(false))
        dispatch(updateNbOfSlotsPassed(0))
        dispatch(resetPathToDestination())
        dispatch(resetItineraryPath())
        dispatch(resetItinerary())
    }


    async function recalcultateItinerary(){
        props.map.panTo(lastTorchGeolocation)
        props.map.setZoom(16)
        dispatch(toggleConfirmationLeavingItinerary(false))
        dispatch(updateNbOfSlotsPassed(0))
        dispatch(resetPathToDestination())
        dispatch(resetItineraryPath())
        dispatch(recalculateItinerary())
    }

    function sparkedOnStreetSuccess(){
        dispatch(toggleTimeToParkContainer(true))
        confirmLeavingItinerary()
    }


    useEffect(() => {
        //to let user click on button once every is loaded
        if(itineraryPath.loading === "done"){
            setRecalculateButtonDisabled(false)
        }
    }, [itineraryPath.loading])


    return (
        <div className="freeze-background-time-to-park time-to-park-div flex-center-column">
            <>
                <IoIcon.IoClose 
                    className='menu-bars' 
                    style={{position: "absolute", top: "15px", right: "15px", color: "white"}}
                    onClick={()=> {dispatch(toggleConfirmationLeavingItinerary(false)); addActionToDbBasedOnElement("leaveItineraryBtnScreen_close", trackingIdAndAnonymousLocal, trackingSessionId)}}
                />
                <div className="time-park-button-div">
                    <button className="green-button-time-to-park" onClick={()=> {confirmLeavingItinerary(); addActionToDbBasedOnElement("leaveItineraryBtnScreen_quitItinerary", trackingIdAndAnonymousLocal, trackingSessionId)}}><p className="no-margin">QUITTER L'ITINERAIRE</p></button>
                </div>
                <div className="time-park-button-div">
                    <button className="green-button-time-to-park" disabled={recalculateButtonDisabled} onClick={()=> {recalcultateItinerary(); addActionToDbBasedOnElement("leaveItineraryBtnScreen_recalculate", trackingIdAndAnonymousLocal, trackingSessionId)}} ><p className="no-margin">RECALCULER</p></button>
                </div>
                <div className="time-park-button-div">
                    <button className="green-button-time-to-park" onClick={()=> {sparkedOnStreetSuccess(); addActionToDbBasedOnElement("leaveItineraryBtnScreen_foundSpot", trackingIdAndAnonymousLocal, trackingSessionId)}}><p className="no-margin">J'AI TROUVE UNE PLACE</p></button>
                </div>
            </>
        </div>
    )
}

export default ConfirmationLeavingItinerary
