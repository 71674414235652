import { handleResponse, transfromObjectToQueryString } from "./utilsApi"
import * as url from './routes'

/**
 * Detect if data about revvealing slots is available 
 *
 * @param {json} position - lat lng
 *
 */
export async function getRevealSlots(position){

    let  dataToSend = transfromObjectToQueryString({
        lat: position.lat,
        lng: position.lng,
        radius: 300
    })

    
    var response = fetch(url.revealSlots + dataToSend)
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(error => console.log(error))
    return response
};


/**
 * Detect if data about revealing slots is available 
 *
 * @param {object} position - {lat: nb, lng: nb}
 *
 */
export async function calculateOptimalItinerary(position){

    let  dataToSend = transfromObjectToQueryString({
        origin: [position.lat, position.lng],
        destination: [position.lat, position.lng],
    })

    var response = fetch(url.optimalItinerary + dataToSend)
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(error => console.log(error))
    return response
};


/**
 * Get itinerary from between two point
 *
 * @param {object} position - {lat: nb, lng: nb}
 * @param {object} destination - {lat: nb, lng: nb}
 *
 */
 export async function getClassicRouting(position, destination){

    let  dataToSend = transfromObjectToQueryString({
        origin: [position.lat, position.lng],
        destination: [destination.lat, destination.lng],
    })

    var response = fetch(url.classicRouting + dataToSend)
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(error => console.log(error))
    return response
};