const initialState = {visible: false, position: {lat: 0, lng: 0}}

export const SHOW_COCO_SEARCH_MARKER = 'SHOW_COCO_SEARCH_MARKER'
export const HIDE_COCO_SEARCH_MARKER = 'HIDE_COCO_SEARCH_MARKER'

export function cocoSearchMarkerReducer (state = initialState, action) {
    switch (action.type){
        case SHOW_COCO_SEARCH_MARKER:
            let displayCocoInfo = {
                visible: true,
                position: {
                    lat: action.payload.lat,
                    lng: action.payload.lng
                }
            }
            return displayCocoInfo
        case HIDE_COCO_SEARCH_MARKER:
            let hideCocoInfo = {
                visible: false,
                position: {
                    lat: 0,
                    lng: 0
                }
            }
            return hideCocoInfo
        default: return state
    }
}