import { handleResponse } from "./utilsApi"
import * as url from './routes'

/**
 * Saving feedback sent by user in DB
 * @param {string} name - user name from form
 * @param {string} email - user email from form
 * @param {string} subject - feedback subject from form
 * @param {string} message - feedback message from form
 * @param {time} time - sending date of the feedback
 * @param {string} uid - user uid
 */
export function storeFeedBackInDb(name, email, subject, message, time, uid){
    let dataToSend
    if(uid){
        dataToSend = {
            'name': name, 
            'email': email, 
            "subject": subject, 
            "message": message, 
            "time": time, 
            "uid": uid
        }
    } else {
        dataToSend = {
            'name': name, 
            'email': email, 
            "subject": subject, 
            "message": message, 
            "time": time, 
        } 
    }
    let response = fetch(url.storeFeedback, 
    {
        method: 'post',
        body: JSON.stringify(dataToSend)
    })
    .then(handleResponse)
    .then(data => {
        return data
    })
    .catch(error => console.log(error))
    return response
}


export function sendFeedBack(firstName, object, message, email){
    let dataToSend = {
        firstName: firstName,
        object: object,
        message: message,
        email: email
    }
    let response = fetch("https://europe-west1-database-dev-fcdce.cloudfunctions.net/sendFeedback", 
    {
        method: 'post',
        body: JSON.stringify(dataToSend),
    })
    .then(handleResponse)
    .then(data => {
        return data
    })
    .catch(error => console.log("error", error))
    return response
}