import React from 'react';

//STYLE
import './ParkingLoader.css'

function ParkingLoader() {
    return(
        <div id="lds-ellipsis">                
            <div className="lds-ellipsis">
                <div></div><div></div><div></div><div></div>
            </div>
        </div>
    );
}

export default ParkingLoader;
