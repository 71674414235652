const initialState = {
    visible: false,
    driveTime: "",
    driveDist: "",
    walkTime: "",
    walkDist: ""
}

export const ADD_PARKING_TABLE_INFO = 'ADD_PARKING_TABLE_INFO'
export const RESET_PARKING_TABLE_INFO = 'RESET_PARKING_TABLE_INFO'

export function parkingTagTableReducer (state = initialState, action) {
    switch (action.type){
        case ADD_PARKING_TABLE_INFO:
            return action.payload
        case RESET_PARKING_TABLE_INFO:
            return initialState
        default: return state
    }
}