import React from 'react'
import { useSelector } from 'react-redux'
import { Polyline } from '@react-google-maps/api'

//REDUX SELECTOR
import { frontStyleOnstreetCommonSelector } from './state/frontStyleOnstreetCommonSelector';
import { onstreetCommonMarkerDataSelector } from './state/onstreetCommonMarkerDataSelector'
import { itineraryModeSelector } from '../global/state/itineraryModeSelector'


export const OnstreetCommonMarker = React.memo((props) => {

    const frontStyleOnstreetCommon = useSelector(frontStyleOnstreetCommonSelector)
    const onstreetCommonMarkerData = useSelector(onstreetCommonMarkerDataSelector)
    const itineraryMode = useSelector(itineraryModeSelector)

    let visible = false

    //to display markers only if itineraryMode isn't active (torch)
    if(!itineraryMode){
        //to show marker on map if the zoom level is the correct one
        //to display marker if there are in the surroundingCityZone
        if(onstreetCommonMarkerData.surroundingCityZone.find(id => id === props.info.zoneId) && frontStyleOnstreetCommon === 1){
            visible = true
        } else {
            visible = false
        }
    }

    //all polylines options
    const options = {
        strokeColor: "#606060",
        strokeOpacity: 1,
        strokeWeight: 2,
        clickable: false,
        draggable: false,
        editable: false,
        visible: visible,
    };

    return (
        <div>
            <Polyline
                path={props.info.path}
                options={options}
            />      
        </div>
    )
})

export default OnstreetCommonMarker
