import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

//REDUX SELECTOR
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'
import { userInfoJsonSelector } from '../userMenu/state/userInfoJsonSelector'
import { userUidSelector } from '../global/state/userUidSelector'

//REDUX ACTION
import { toggleSectionDrawer } from '../navBar/state/navBarAction'

//UTILS
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'
import { sendFeedBack, storeFeedBackInDb } from '../api/feedbackApi'

//STYLE
import './Feedback.css'

function Feedback() {

    const dispatch = useDispatch()

    let msg = {}
    let regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const [firstName, setFirstName] = useState('')
    const [email, setEmail] = useState('')
    const [object, setObject] = useState('')
    const [message, setMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState("")
    const [validationMessage, setValidationMessage] = useState("")

    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)
    const userInfoJson = useSelector(userInfoJsonSelector)
    const userUid = useSelector( userUidSelector)

    useEffect(() => {
        //to display user name and email in form if user is connected
        if(userInfoJson.displayName){
            setFirstName(userInfoJson.displayName)
        }
        if(userInfoJson.email){
            setEmail(userInfoJson.email)
        }
    }, [])


    async function sendFeedback(firstName, email, object, message){
        //to validate that there isn't any empty field
        await validateFieldNotEmpty([firstName, email, object, message])
            .then(async function(response){
                //if there isn't any empty, check the correct format of the email
                if(!response){
                    await checkEmailRegEx(email)
                        .then(async function (response){
                            //if the email is correct, sending the message using Sendgrid
                            if(response){
                                sendFeedBack(firstName, object, message, email)
                                .then((resp)=>{  
                                        if(resp[0].statusCode === 200 || resp[0].statusCode == 202){
                                            //to reset field if msg send
                                            setFirstName('')
                                            setEmail('')
                                            setObject('')
                                            setMessage('')
                                            setErrorMessage("")
                                            setValidationMessage("Votre message a bien été envoyé.<br>Nous vous remercions et vous répondrons au plus vite.<br> Cocoparks, let's spark !")
                                            //to close component after 3sec if the message has been send
                                            setTimeout(function(){
                                                dispatch(toggleSectionDrawer("feedback", false))
                                            }, 3*1000)
                                        } else {
                                            setErrorMessage("Il y a eu un souci, vous pouvez contacter directement raph@cocoparks.io")
                                        }
                                })
                                await storeFeedBackInDb(firstName, email, object, message, Date(Date.now()), userUid)
                            } else {
                                setErrorMessage("* Email invalide")
                            }
                        })
                } else {
                    setErrorMessage("* Veuillez remplir tous les champs")
                }
            })
    }

    /**
     * Verify that the email is in the correct format
     *
     * @param {str} email - email from the input
     * @returns {boolean} - true if email is correct
     *
     */
    function checkEmailRegEx(email){
        return new Promise(resolve => {
            if(!regEx.test(email)){
                resolve(false)
            } else {
                resolve(true)
            }
        })
    }

    /**
     * Check that no field is empty
     *
     * @param {Array} array -array containing all the states of the form
     * @returns {boolean} - true if at least one field is emtpy / false if no fields are empty
     *
     */
    function validateFieldNotEmpty(array){
        return new Promise(resolve => {
            let oneFieldIsEmpty = false
            for (const element of array) {
                if(element === ""){
                    oneFieldIsEmpty = true
                    break
                }
            }
            resolve(oneFieldIsEmpty)
        })
    }

    return (
        <div style={{backgroundColor: "white", width: "100vw", height: "100vh"}}>
            <div className="flex-align-top-inline" style={{textAlign: "center"}}>
                <h3 className="title">Aidez nous à <br/>améliorer Cocoparks !</h3>
            </div>
            <div className="flex-center-column" style={{margin: "20px", height: "75%"}}>
                <input 
                    onChange={(e)=>setFirstName(e.target.value)} 
                    value={firstName}                 
                    placeholder="Votre nom"
                    className="input-form"
                ></input>
                <input 
                    onChange={(e)=>setEmail(e.target.value)} 
                    value={email}                 
                    placeholder="Votre email"
                    className="input-form"
                ></input>
                <input 
                    onChange={(e)=>setObject(e.target.value)} 
                    value={object}                 
                    placeholder="Object"
                    className="input-form"
                ></input>
                <textarea 
                    onChange={(e)=>setMessage(e.target.value)} 
                    type="textarea"
                    rows={5}
                    cols={5}
                    value={message}                 
                    placeholder="Message"
                    className="input-form"
                ></textarea>
                <button className="green-action-button" style={{marginTop: "10px"}} 
                    onClick={()=>{sendFeedback(firstName, email, object, message); addActionToDbBasedOnElement("sendFeedbackForm", trackingIdAndAnonymousLocal, trackingSessionId)}}
                >
                    <span className="green-action-button-text">Envoyer</span>
                </button>
                <p className="error-message">{errorMessage}</p>
                <p className="validation-message"
                    dangerouslySetInnerHTML={{__html: validationMessage}}
                ></p>
            </div>
        </div>
    )
}

export default Feedback
