////////////////////////////// ALL FUNCTIONS USED IN USER MENU FEATURE ////////////////////////////////////

//UTILS
import { updateUserInDB , createUserInDB} from '../api/authApi';
import { getUserIp , updateIPInDB} from '../api/userApi'

/**
* updates user with new login time or log out time
* @param {string} id -
* @param {string} logInOut - logInTime or logOutTime
*/
export async function updateUserLogInOut(id, logInOut, isAnonymousLocal){
   let time = new Date(Date.now());
   let timejson = {};
   timejson[logInOut] = time;
   let data = {
       'type': 'connectHistory',
       'id': id,
       'data': timejson,
       'isAnonymousLocal': isAnonymousLocal,
   };
   await updateUserInDB(data);
};

/**
 * get anonymous user id from LS
 */
export function checkIfIsAnonymousUser(){
    return new Promise(resolve=>{
        var id = JSON.parse(localStorage.getItem('lsAnonymousId'));
        if(id){
            var result = id;
            resolve(result)
        }else{
            var resp = "none"
            resolve(resp)
        }
    });
}

/**
 * creates user: anonymous or not
 * id is empty is id does not exist
 */
export function createUser(id, profileData){
    return new Promise(async (resolve) =>{
        await createUserInDB(id, profileData)
        .then(async function(resp){
            var connectHistory_base = {
                'type': 'connectHistory',
                'id': resp,
                'data': {'logInTime': new Date(Date.now())},
                'isAnonymousLocal': profileData['isAnonymousLocal'],
            };
            updateUserInDB(connectHistory_base)
            // console.log("Profile of user created in Firestore!");
            let response = await getUserIp()
            if(response){
                let ip_address = response.ip
                // register ip address in user and in ipAdresses collection
                var user_ip_addresses = {
                    'type': 'setIPaddress',
                    'id': resp,
                    'ip_address' : ip_address,
                    'time': new Date(Date.now()),
                    'isAnonymousLocal': profileData['isAnonymousLocal'],
                };
                await updateUserInDB(user_ip_addresses)
                .then(function(){
                    // console.log('ip address saved');
                    var data = {
                        'type': 'setIPaddressToUser',
                        'ip_address': ip_address,
                        "userId": resp,
                        'isAnonymousLocal': profileData['isAnonymousLocal'],
                    };
                    updateIPInDB(data)
                });
            }
            resolve(resp);
        });
    })
};

/**
 * add anonymous user id to LS
 */
export function addAnonymousUserToLS(id){
    localStorage.setItem('lsAnonymousId', JSON.stringify(id));
}