const initialState = {path: [], style: "line"}

export const UPDATE_PATH_TO_DESTINATION = 'UPDATE_PATH_TO_DESTINATION'
export const RESET_PATH_TO_DESTINATION = 'RESET_PATH_TO_DESTINATION'

export function directionToDestinationReducer (state = initialState, action) {
    switch (action.type){
        case UPDATE_PATH_TO_DESTINATION:
            return action.payload
        case RESET_PATH_TO_DESTINATION:
            return initialState
        default: return state
    }
}