import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { InfoWindow } from '@react-google-maps/api'

//REDUX SELECTORS
import { onstreetDeliveryInfoWindowSelector } from './state/onstreetDeliveryInfoWindowSelector'

//REDUX ACTIONS
import { hideOnstreetDeliveryInfoWindow } from './state/onstreetDeliveryInfoWindowAction'

function OnstreetDeliveryInfoWindow() {

    const dispatch = useDispatch()
    
    const onstreetDeliveryInfoWindow = useSelector(onstreetDeliveryInfoWindowSelector)

    //to reset the component when user click on infowindow's close button 
    function closeInfoWindow(){
        dispatch(hideOnstreetDeliveryInfoWindow())
    }

    return (
        <div>
            <InfoWindow
                position={onstreetDeliveryInfoWindow.position}
                onCloseClick={closeInfoWindow}
            >
            <div style={{maxWidth: "180px", textAlign: "center"}}>
                <p className="no-margin" style={{fontSize: "14px", fontWeight: "bold", marginBottom: "5px"}}>Place de livraison</p>
                <p className="no-margin">Autorisé de 20h à 7h du matin en semaine, et tous les dimanches !</p>
            </div>
            </InfoWindow>
        </div>
    )
}

export default OnstreetDeliveryInfoWindow
