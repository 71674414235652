import { UPDATE_FREED_SPOT_MARKER_DATA, ADD_FREED_SPOT_MARKER_DATA } from "./freedSpotMarkerDataReducer"

export const updateFreedSlotMarkerData = (index) =>({
    type: UPDATE_FREED_SPOT_MARKER_DATA,
    payload: index
})


export const addFreedSlotMarkerData = (info)=>({
    type: ADD_FREED_SPOT_MARKER_DATA,
    payload: info
})