import { STORE_LVZ_INFO, RESET_LVZ_INFO } from "./lvzInfoReducer"

export const storeLvzInfo = (lvzExists, lvzDetails) => ({
    type: STORE_LVZ_INFO,
    payload: {
        lvzExists: lvzExists,
        lvzEta: lvzDetails.eta,
        lvzId: lvzDetails.lvz_id,
        lvzFreeSlots: lvzDetails.free_slots,
        lvzAvailability: lvzDetails.availability,
    }
})

export const resetLvzInfo = () => ({
    type: RESET_LVZ_INFO,
})