import React from 'react'
import { useSelector } from 'react-redux'
import { Marker } from '@react-google-maps/api'

//COMPONENTS
import LvzInfoWindow from './LvzInfoWindow'

//REDUX SELECTOR
import { lvzMarkerRealTimeSelector } from '../markers/state/lvzMarkerRealTimeSelector'
import { lvzInfoWindowSelector } from './state/lvzInfoWindowSelector'

//COMPONENTS
import LiveViewZoneSlotMarker from './LiveViewZoneSlotMarker'

//UTILS
import * as global from "../global/globalVariable"

export const WrapperLiveViewZoneSlots = React.memo(({visible}) => {

    const lvzMarkerRealTime = useSelector(lvzMarkerRealTimeSelector)
    const lvzInfoWindow = useSelector(lvzInfoWindowSelector)

    let allSlotsMarkers = Object.keys(lvzMarkerRealTime).map( zone =>{
        let allSlotsFromZone = lvzMarkerRealTime[zone].slots_data        
        return (
            Object.keys(allSlotsFromZone).map((slot, index) => {
                let isFree = allSlotsFromZone[slot].is_free
                let isActivated = allSlotsFromZone[slot].is_activated
                //to display grey circle for slot if lvz isn't active
                if(!lvzMarkerRealTime[zone].activated){
                    isFree = null
                }
                return <LiveViewZoneSlotMarker 
                            position={allSlotsFromZone[slot].location}
                            isFree={isFree}
                            isActivated={isActivated}
                            slotType={allSlotsFromZone[slot].slot_type}
                            legend={allSlotsFromZone[slot].regulation_text ? allSlotsFromZone[slot].regulation_text : global.slotTypeKey[allSlotsFromZone[slot].slot_type]}
                            key={index}
                            visible={visible}
                        />
            })
        )
    })
    return (
        <>
            {allSlotsMarkers}
            {lvzInfoWindow.visible ? 
                <LvzInfoWindow/>
            :null}
        </>
    )
})

export default WrapperLiveViewZoneSlots
