import { handleResponse } from "./utilsApi"
import * as url from './routes'

export function postCarType(uid, carType){
    let dataToSend = {'uid': uid, 'cartype': carType}
    let response = fetch(url.postCarType,
    {
        method: 'post',
        body: JSON.stringify(dataToSend)
    })
    .then(handleResponse)
    .then(data => {
        return data
    })
    .catch((error) => console.log('error: ' + error))
    return response
}

export function postFuelType(uid, fuelType){
    let dataToSend = {'uid': uid, 'fueltype': fuelType}
    let response = fetch(url.postFuelType,
    {
        method: 'post',
        body: JSON.stringify(dataToSend)
    })
    .then(handleResponse)
    .then(data => {
        return data
    })
    .catch((error) => console.log('error: ' + error))
    return response
}

export function postAddress(uid, name, address, addressId){
    let dataToSend = {'uid': uid, 'name': name, 'address': address, 'addressId': addressId}
    let response = fetch(url.postAddress,
    {
        method: 'post',
        body: JSON.stringify(dataToSend)
    })
    .then(handleResponse)
    .then(data => {
        return data
    })
    .catch((error) => console.log('error: ' + error))
    return response
}

export function updateAddress(uid, name, address, addressId){
    let dataToSend = {'uid': uid, 'name': name, 'address': address, 'addressId': addressId, 'update': 'update'}
    let response = fetch(url.postAddress,
    {
        method: 'post',
        body: JSON.stringify(dataToSend)
    })
    .then(handleResponse)
    .then(data => {
        return data
    })
    .catch((error) => console.log('error: ' + error))
    return response
}

export function deleteAddress(uid, name, addressId){
    let dataToSend = {'uid': uid, 'name': name, 'address': "", 'addressId': addressId, 'update': 'update'}
    let response = fetch(url.postAddress,
    {
        method: 'post',
        body: JSON.stringify(dataToSend)
    })
    .then(handleResponse)
    .then(data => {
        return data
    })
    .catch((error) => console.log('error: ' + error))
    return response
}

export function getHistory(uid){
    let dataToSend = { 'uid': uid }
    let response = fetch(url.readHistory,
    {
        method: 'post',
        body: JSON.stringify(dataToSend)
    })
    .then(handleResponse)
    .then(data => {
        return data
    })
    .catch((error) => console.log('error: ' + error))
    return response
}

export function getUserIp(){
    let response = fetch("https://api.ipify.org?format=json")
    .then(handleResponse)
    .then(data => {
        return data
    })
    .catch((error) => console.log('error: ' + error))
    return response
}

export function updateIPInDB(data){
    let response = fetch(url.updateIp, {
        method: 'post',
        body: JSON.stringify(data)
    })
    .then(handleResponse)
    .then(data => {
        return data
    })
    .catch((error) => console.log('error: ' + error))
    return response
}

export function addNewHistory(data){
    let response = fetch(url.newHist, {
        method: 'post',
        body: JSON.stringify(data)
    })
    .then(handleResponse)
    .then(data => {
        return data
    })
    .catch((error) => console.log('error: ' + error))
    return response
}

export function updateParkingHistory(data){
    let response = fetch(url.updateHist, {
        method: 'post',
        body: JSON.stringify(data)
    })
    .then(handleResponse)
    .then(data => {
        return data
    })
    .catch((error) => console.log('error: ' + error))
    return response
}

export function updateUserVersion(data, userId){
    let response = fetch(url.updateUserField + userId, {
        method: 'post',
        body: JSON.stringify(data)
    })
    .then(handleResponse)
    .then(data => {
        console.log("data", data)
        return data
    })
    .catch((error) => console.log('error: ' + error))
    return response
}