import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

//REDUX SELECTOR
import { displayModificationContainerSelector } from './state/displayModificationContainerSelector';
import { userUidSelector } from '../global/state/userUidSelector';
import { userInfoJsonSelector } from './state/userInfoJsonSelector';

//REDUX ACTIONS
import { toggleModificationContainer } from './state/displayModificationContainerAction'
import { toggleAddAddressPopup } from '../popup/state/displayAddAddressPopupAction';
import { updateUserInfoJson } from '../userMenu/state/userInfoJsonAction';

//UTILS
import { deleteAddress } from '../api/userApi';
import { getUser } from '../api/authApi';

//STYLE
import * as IoIcon from 'react-icons/io5';

function ModificationContainer() {

    const dispatch = useDispatch()

    const displayModificationContainer = useSelector(displayModificationContainerSelector)
    const userUid = useSelector(userUidSelector)
    const userInfoJson = useSelector(userInfoJsonSelector)

    function hideModificationContainer(){
        dispatch(toggleModificationContainer({value: false}))
    }

    async function deleteAddresses(){
        let addressId = displayModificationContainer.addressId
        let name = userInfoJson.savedAddresses[addressId].name
        await deleteAddress(userUid, name, addressId)
        let userFromDb = await getUser(userUid)
        dispatch(updateUserInfoJson({...userFromDb, uid: userUid}))
        hideModificationContainer()
    }

    function modifyAddresses(){
        dispatch(toggleAddAddressPopup({value: true, addressId: displayModificationContainer.addressId, update: true}))
        hideModificationContainer()
    }


    return (
        <div className="freeze-background-time-to-park time-to-park-div flex-center-column">
            <IoIcon.IoClose 
                className='menu-bars' 
                style={{position: "absolute", top: "15px", right: "15px"}}
                onClick={()=> hideModificationContainer()}
            />
            <div className="modification-div flex-center-column">
                 <button className={"fav-rec-Addresses-button selected-button"} onClick={()=> modifyAddresses()}>
                     Modifier
                 </button>
                 <button className={"fav-rec-Addresses-button selected-button"} onClick={()=>  deleteAddresses()}>
                     Supprimer
                 </button>
            </div>
        </div>
    )
}

export default ModificationContainer
