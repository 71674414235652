const initialeState = { visible: false }

export const HIDE_ZONE_WITH_REGULATIONS_BUTTON = 'HIDE_ZONE_WITH_REGULATIONS_BUTTON'
export const SHOW_ZONE_WITH_REGULATIONS_BUTTON = 'SHOW_ZONE_WITH_REGULATIONS_BUTTON'

export function displayZoneWithRegulationsButtonReducer (state = initialeState, action) {
    switch (action.type){
        case HIDE_ZONE_WITH_REGULATIONS_BUTTON:
            return initialeState
        case SHOW_ZONE_WITH_REGULATIONS_BUTTON:
            let newState = { visible: true, title: action.payload.title, pathToRegulation: action.payload.pathToRegulation}
            return newState
        default: return state
    }
}