import React from 'react'
import { useSelector } from 'react-redux'

//COMPONENTS
import OnstreetDeliveryMarker from './OnstreetDeliveryMarker'
import OnstreetDeliveryInfoWindow from './OnstreetDeliveryInfoWindow'

//REDUX SELECTORS
import { onstreetDeliveryMarkerDataSelector } from './state/onstreetDeliveryMarkerDataSelector'
import { onstreetDeliveryInfoWindowSelector } from './state/onstreetDeliveryInfoWindowSelector'

//UTILS
import { generateKey } from '../global/MapUtils'


const WrapperOnstreetDeliveryMarker = React.memo((props) => {

    let allOnstreetDeliveryMarker = []

    const onstreetDeliveryMarkerData = useSelector(onstreetDeliveryMarkerDataSelector)
    const onstreetDeliveryInfoWindow = useSelector(onstreetDeliveryInfoWindowSelector) 
    
    //mapping through data to render all OnstreetMarker. Passing their properties using props.
    Object.keys(onstreetDeliveryMarkerData.data).forEach((zoneId) => {
        onstreetDeliveryMarkerData.data[zoneId].forEach((marker, index)=>{
            allOnstreetDeliveryMarker.push(
                <OnstreetDeliveryMarker 
                    key={generateKey(zoneId, index)}
                    
                    info={{
                        path: marker.polygon,
                        zoneId: zoneId,
                        location: {lat: marker.lat, lng: marker.lng}
                    }}
                    
                />
            )
        })
    });


    return (
        <div>
            {allOnstreetDeliveryMarker}
            {onstreetDeliveryInfoWindow.visible ? 
                <OnstreetDeliveryInfoWindow/>
            :null}
        </div>
    )
})

export default WrapperOnstreetDeliveryMarker
