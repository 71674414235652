import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { InfoWindow } from '@react-google-maps/api'

//REDUX SELECTORS
import { evhInfoWindowSelector } from './state/evhInfoWindowSelector'

//REDUX ACTIONS
import { hideEvhInfoWindow } from './state/evhInfoWindowAction'

function EvhInfoWindow() {

    let infoWindowContent = "Stationnement pour véhicule électrique ne comportant pas de recharge."

    const dispatch = useDispatch()
    
    const evhInfoWindow = useSelector(evhInfoWindowSelector)
    
    //to update infowWindow content based on evh type charge/sans recharge
    if(evhInfoWindow.type === "avec recharge"){
        infoWindowContent = "Stationnement pour véhicule électrique avec recharge."
    }

    //to reset the component when user click on infowindow's close button 
    function closeInfoWindow(){
        dispatch(hideEvhInfoWindow())
    }

    return (
        <div>
            <InfoWindow
                position={evhInfoWindow.position}
                onCloseClick={closeInfoWindow}
            >
            <div style={{maxWidth: "180px", textAlign: "center"}}>
                <p className="no-margin">{infoWindowContent}</p>
            </div>
            </InfoWindow>
        </div>
    )
}

export default EvhInfoWindow
