import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

//REDUX SELECTORS
import { displayGetBackToRecentTorchAddressSelector } from './state/displayGetBackToRecentTorchAddressSelector';
import { userLocationSelector } from '../markers/state/userLocationSelector'
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'

//REDUX ACTIONS
import { resetGetBackToRecentTorchAddress } from './state/displayGetBackToRecentTorchAddressAction'
import { updateTorchJson } from '../itinerary/state/torchJsonAction'
import { toggleItineraryMode } from '../global/state/itineraryModeAction';
import { hideDetailPanelContainer } from '../detailsPanel/state/displayDetailsPanelContainerAction';
import { toggleTorchNotAvailableScreen } from './state/displayTorchNotAvailableScreenAction'

//UTILS
import { getRevealSlots } from '../api/itineraryApi';
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//STYLE
import * as IoIcon from 'react-icons/io5';

function GetBackToRecentTorchAddress(props) {

    const dispatch = useDispatch()

    const displayGetBackToRecentTorchAddress = useSelector(displayGetBackToRecentTorchAddressSelector)
    const userLocation = useSelector(userLocationSelector)
    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)

    function closeGetBackToRecentTorchAddress(){
        dispatch(resetGetBackToRecentTorchAddress())
    }

    //to display torch if there is data on available slot on user's location
    async function launchUpdateRevealSlotsFromAddress(){
        //to get last data from LS
        var workingData = JSON.parse(localStorage.getItem('workingData'));
        var lsTorchData = workingData.lastTorchData;
        var lastAddress = lsTorchData.torchAddress;
        
        //to update map center based on last research position
        props.map.panTo({lat: lastAddress.lat, lng: lastAddress.lng})

        await getRevealSlots({lat: lastAddress.lat, lng: lastAddress.lng})
        .then(function(resp){
            if(resp.results !== -1 || resp.results.total_number_of_spots > 0){
                startTorch(resp.results)
            }
        })
    }


    async function launchUpdateRevealSlotsFromAroundMe(){
        //to display torch if there is data on available slot on user's location
        await getRevealSlots(userLocation)
        .then(function(resp){
            if(resp.results !== -1 || resp.results.total_number_of_spots > 0){
                startTorch(resp.results)
            } else {
                dispatch(toggleTorchNotAvailableScreen(true))
                dispatch(resetGetBackToRecentTorchAddress())
            }
        })
    }

    function startTorch(data){
        //to update torchJson based on last research position
        dispatch(updateTorchJson(data))
        //to hide current component
        dispatch(resetGetBackToRecentTorchAddress())
        //to hide details panel because it's suppose to be displayed by default when opening the app
        dispatch(hideDetailPanelContainer())
        //to update itinerary mode 
        dispatch(toggleItineraryMode(true))
    }

    return (
        <div className="freeze-background-time-to-park flex-flex-end-column" >
            <IoIcon.IoClose 
                className='menu-bars' 
                style={{position: "absolute", top: "15px", right: "15px", color: "white"}}
                onClick={()=> closeGetBackToRecentTorchAddress()}
            />
            <div className="time-park-button-div">
                <button className="green-button-time-to-park" onClick={()=> {launchUpdateRevealSlotsFromAddress(); addActionToDbBasedOnElement("getBackToRecentTorchAddressScreenBtnFrom", trackingIdAndAnonymousLocal, trackingSessionId)}}><p className="no-margin">A PARTIR DE : {displayGetBackToRecentTorchAddress.address}</p></button>
            </div>
            <div className="time-park-button-div">
                <button className="green-button-time-to-park"><p className="no-margin" onClick={()=> {launchUpdateRevealSlotsFromAroundMe(); addActionToDbBasedOnElement("getBackToRecentTorchAddressScreenBtnAroundMe", trackingIdAndAnonymousLocal, trackingSessionId)}}>AUTOUR DE MOI</p></button>
            </div>
        </div>
    )
}

export default GetBackToRecentTorchAddress
