const initialState = 0

export const UPDATE_FRONT_STYLE_PMR = 'UPDATE_FRONT_STYLE_PMR'

export function frontStylePmrReducer (state = initialState, action) {
    switch (action.type){
        case UPDATE_FRONT_STYLE_PMR:
            return action.payload
        default: return state
    }
}