import React from 'react'

//COMPONENTS
import LiveViewZoneMarkerWithArrow from './LiveViewZoneMarkerWithArrow'
import LiveViewZoneMarkerWithNumber from './LiveViewZoneMarkerWithNumber'
import LiveViweZoneMarkerPolygon from './LiveViweZoneMarkerPolygon'
import LiveViewZoneCircle from './LiveViewZoneCircle'
import WrapperLiveViewZoneSlots from './WrapperLiveViewZoneSlots'

function LiveViewZoneMarker({position, availability, freeSlot, path, zoneId, map, entityType, parkingEntranceLocation, parkingExitLocation, activated}) {

    if(!activated){
        availability = "deactivate"
    }

        return(
            <>
                <LiveViewZoneMarkerWithArrow
                    position={position}
                    parkingEntranceLocation={parkingEntranceLocation}
                    parkingExitLocation={parkingExitLocation}
                    freeSlot={freeSlot}
                    map={map}
                    availability={availability}
                    zoneId={zoneId}
                />

                <WrapperLiveViewZoneSlots
                />

                <LiveViewZoneMarkerWithNumber
                    position={position}
                    freeSlot={freeSlot}
                    zoneId={zoneId}
                    map={map}
                    availability={availability}
                />

                <LiveViweZoneMarkerPolygon
                    availability={availability}
                    position={position}
                    path={path}
                    zoneId={zoneId}
                    map={map}
                />

                <LiveViewZoneCircle
                    position={position}
                    availability={availability}
                    zoneId={zoneId}
                    map={map}
                />

            </>
        )
}

export default LiveViewZoneMarker
