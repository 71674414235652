import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector} from 'react-redux'

//REDUX SELECTOR
import { displayInteractiveBoxSelector } from './state/displayInteractiveBoxSelector';
import { torchJsonSelector } from '../itinerary/state/torchJsonSelector';
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'

//REDUX ACTIONS
import { toggleInteractiveBox } from './state/displayInteractiveBoxAction';

//UTILS
import interactiveMsgParking from './interactiveMsgParking.json' // List of the different Interactive Boxes possible for each conditions (for parking search)
import interactiveMsgTorch from './interactiveMsgTorch.json' // List of the different Interactive Boxes possible for each conditions (for torch mode)
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//STYLE
import './InteractiveBox.css'
import * as IoIcon from 'react-icons/io5';

function InteractiveBox() {

    const dispatch = useDispatch()
    
    const displayInteractiveBox = useSelector(displayInteractiveBoxSelector)
    const torchJson = useSelector(torchJsonSelector)
    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)

    const [title, setTitle] = useState("")
    const [msg, setMsg] = useState("")
    const [backgroundColor, setBackgroundColor] = useState("")
    const [imageSrc, setImageSrc] = useState("")
    const [animDuration, setAnimDuration ] = useState()
    const [displayProgressBar, setDisplayProgressBar] = useState(false)


    let animationStyle = {
        animationDuration: animDuration
    }


    //to hide the actual component
    function closeInteractiveBox(){
        dispatch(toggleInteractiveBox({value: false, messageType: ""}))
    }


    function updateInteractiveBox(title, msg, coco, color, timer){
        setTitle(title)
        setMsg(msg)
        setBackgroundColor(color)
        setImageSrc(coco)
        setAnimDuration(timer + "ms")
        setDisplayProgressBar(true)
        const boxTimer = setTimeout(function(){
            closeInteractiveBox()
        }, timer)
        return () => clearTimeout(boxTimer)
    }


    function findParkingMessageTypeToDisplay(nbStreetSpots, nbWithin600){
        let messageType = "none"
        // If 700+ street spots & 0 parking in 600m radius
        if(nbStreetSpots > 700 && nbWithin600 === 0){ 
            messageType = "greater700street0parking";
        }
        // If 100 < x <= 700 street spots & 0 parking in 600m radius
        if(nbStreetSpots > 100 && nbStreetSpots <= 700 && nbWithin600 === 0){ 
            messageType = "between100and700street0parking";
        }
    
        // If -100 street spots & 0 parking in 600m radius
        if(nbStreetSpots <= 100 && nbWithin600 === 0){ 
            messageType = "less100street0parking";
        }
    
        // If 700+ street spots & 1 parking in 600m radius
        if(nbStreetSpots > 700 && nbWithin600 === 1){
            messageType = "greater700street1parking";
        }
    
        // If 100 < x <= 700 street spots & 1 parking in 600m radius
        if(nbStreetSpots > 100 && nbStreetSpots <= 700 && nbWithin600 === 1){
            messageType = "between100and700street1parking";
        }
    
        // If -100 street spots & 1 parking in 600m radius
        if(nbStreetSpots <= 100 && nbWithin600 === 1){
            messageType = "less100street1parking";
        }
    
        // If -100 street spots & more than 2 parking in 600m radius
        if(nbStreetSpots <= 100 && nbWithin600 >= 2){ 
            messageType = "less100streetGE2parking";
        }

        return messageType
    }


    useEffect(() => {
        let randomMsg
        if(displayInteractiveBox.category === "torch"){
            //to find out with type of message we want to display
            //based on which component dispatch toggleInteractiveBox({value: true/false, messageType: "loading"/start})
            randomMsg = interactiveMsgTorch[displayInteractiveBox.messageType]
        } else {
            let messageType = findParkingMessageTypeToDisplay(torchJson.total_number_of_spots, displayInteractiveBox.nbWithin600)
            if(messageType !== "none"){
                randomMsg = interactiveMsgParking[messageType]
            }
        }
        if(randomMsg){
            // Choose a random number between 0 and the length of the message type object
            let chosenMsgId = Math.floor(Math.random() * ((Object.keys(randomMsg).length))); 
            updateInteractiveBox(randomMsg[chosenMsgId][0], randomMsg[chosenMsgId][1], randomMsg[chosenMsgId][2], randomMsg[chosenMsgId][3], randomMsg[chosenMsgId][4], randomMsg[chosenMsgId][5])
        } else {
            closeInteractiveBox()
        }
    }, [])


    return (
        <div className="interactive-box-container flex-center-column" style={{backgroundColor}}>
            <div style={{position: "absolute", top:"8px", right: "8px"}} className="flex-flex-end-column">                
                <IoIcon.IoClose 
                    className='menu-bars' 
                    style={{color: "white"}}
                    onClick={()=> {closeInteractiveBox(); addActionToDbBasedOnElement("interactiveBoxClose", trackingIdAndAnonymousLocal, trackingSessionId)}}
                />
            </div>
            <div className="flex-center-inline" style={{margin: "10px 0 10px 0"}}>
                <img src={imageSrc} alt="Logo" style={{width: "50px", height: "50px"}}/>
                <div style={{marginLeft: "5%"}}>
                    <p className="interactive-box-text no-margin" style={{color: "white"}}>
                        {title}
                    </p>
                    <p className="interactive-box-text no-margin" style={{color: "white", opacity: "0.5", fontSize: "14px"}}>
                        {msg}
                    </p>
                </div>
            </div>
            {displayProgressBar ?             
                <div className="progressbar">
                    <div className="inner" style={animationStyle}></div>
                </div>
            :null}
        </div>
    )
}

export default InteractiveBox
