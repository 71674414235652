import React from 'react'
import {useSelector} from 'react-redux'

//COMPONENTS
import FreedSlotMarker from './FreedSpotMarker'

//REDUX SELECTORS
import { freedSpotMarkerDataSelector } from './state/freedSpotMarkerDataSelector'


export const WrapperFreedSpotMarker = React.memo(() => {
    
    const freedSpotMarkerData = useSelector(freedSpotMarkerDataSelector)

    return (
        <div>
            {freedSpotMarkerData.length >=1 ? freedSpotMarkerData.map((spot, index)=>{
                if(spot.visible){
                    return(
                        <FreedSlotMarker position={spot.position} key={index} start={spot.start} index={index}/>
                    )
                } else {
                    return(null)
                }
            }):null}
        </div>
    )
})

export default WrapperFreedSpotMarker
