const initialState = [false, false, false]

export const UPDATE_COCOLOADER_STATE = 'UPDATE_COCOLOADER_STATE'

export function cocoLoaderStateReducer (state = initialState, action) {
    switch (action.type){
        case UPDATE_COCOLOADER_STATE:
            let newState = [...state]
            for(const [index, element] of newState.entries()){
                if(!element){
                    newState[index] = true
                    break
                }
            }
            return newState
        default: return state
    }
}