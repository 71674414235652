import React from 'react'
import { useDispatch, useSelector} from 'react-redux';

//REDUX SELECTOR
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'

//REDUX ACTIONS
import { toggleDestinationNotReachYet } from './state/displayDestinationNotReachYetAction'

//UTILS
import { goToParking } from '../global/MapUtils'
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'


//STYLE
import '../global/Global.css'
import './DestinationNotReachYet.css'
import * as IoIcon from 'react-icons/io5';

function DestinationNotReachYet(props) {

    const dispatch = useDispatch()

    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)

    function hideDestinationNotReachYet(){
        dispatch(toggleDestinationNotReachYet(false))
    }

    function clickOnGoToParking(deviceSystem){
        let ls = JSON.parse(localStorage.getItem("workingData"))
        let torchAddress = ls.lastTorchData.torchAddress
        goToParking(deviceSystem, torchAddress)
    }
        

    return (
        <div className="freeze-background-time-to-park time-to-park-div flex-center-column" >
            <IoIcon.IoClose 
                className='menu-bars' 
                style={{position: "absolute", top: "15px", right: "15px"}}
                onClick={()=> {hideDestinationNotReachYet(); addActionToDbBasedOnElement("destinationNotYetReached_close", trackingIdAndAnonymousLocal, trackingSessionId)}}
            />
            <p className="time-to-park-text">Vous êtes à plus de 500m de votre destination.</p><br/>
            <p className="time-to-park-text">Une fois sur place, lancez l'application Cocoparks.</p><br/>
            <p className="time-to-park-text" style={{margin: "0px"}}>Y aller avec :</p>
            <div className="destination-not-yet-reached-buttons-div">
                <button id="gmapsDestinationNotYetReachedBtn" className=" destination-not-yet-reached-main-buttons" onClick={()=> {clickOnGoToParking("google"); addActionToDbBasedOnElement("gmapsDestinationNotYetReachedBtn", trackingIdAndAnonymousLocal, trackingSessionId)}}>
                    <img src="https://img.icons8.com/color/96/000000/google-maps.png" alt=""/>
                </button>
                <button id="wazeDestinationNotYetReachedBtn" className=" destination-not-yet-reached-main-buttons" onClick={()=> {clickOnGoToParking("waze"); addActionToDbBasedOnElement("wazeDestinationNotYetReachedBtn", trackingIdAndAnonymousLocal, trackingSessionId)}}>
                    <img src="https://img.icons8.com/color/96/000000/waze.png" alt=""/>
                </button>
            </div>
            <div className="destination-not-yet-reached-revenir">
                <button id="destinationNotYetReached_backToMap" className="intro-user-path-main-buttons" onClick={()=> {hideDestinationNotReachYet(); addActionToDbBasedOnElement("destinationNotYetReached_backToMap", trackingIdAndAnonymousLocal, trackingSessionId)}}>REVENIR SUR LA CARTE</button>
            </div>
        </div>
    )
}

export default DestinationNotReachYet
