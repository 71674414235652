import React from 'react'
import { useDispatch} from 'react-redux'

//COMPONENTS
import LiveIndic from './LiveIndic'

//REDUX ACTIONS
import { toggleLvzSpecificDetailPanel } from './state/displayLvzSpecificDetailPanelAction'
import { hideDetailPanelContainer } from '../detailsPanel/state/displayDetailsPanelContainerAction'
import { toggleParkingSpecificDetailPanel } from '../detailsPanel/state/displayParkingSpecificDetailPanelAction'

//UTILS
import * as global from '../global/globalVariable'

//STYLE
import './ExpandedDetailsPanel.css'

function LvzCard({lvzName, lvzId, freeSlots, timeToLvz, price1h, price3h, price24h, availability}) {

    const dispatch = useDispatch()

    function clickOnLvzCard(){
        dispatch(toggleLvzSpecificDetailPanel({value: true, lvzId: lvzId}))
        dispatch(hideDetailPanelContainer())
        dispatch(toggleParkingSpecificDetailPanel(false))
    }

    return (
        <div className="card-lvz" onClick={()=> {clickOnLvzCard()}}>
            <div className="card-image" style={{backgroundColor: `${global.lvzColors[availability].cssColorName}`}}>{lvzName}</div>
            <div className="card-title" style={{color: `${global.lvzColors[availability].cssColorName}`}}>{freeSlots > 1 ? freeSlots + " places" : freeSlots + " place"}</div>
            <div className="flex-center-column" style={{marginTop: "0px"}}>
                <div className="card-description" style={{position: "relative"}}>
                    <div className="compareColumn-content" style={{paddingLeft: "10px", paddingTop: "5px", paddingBottom: "5px"}}>
                        <LiveIndic boundsChanging={false}/>
                        <span id="streetParkingTime_inCard" className="detailsPanelTime">&nbsp; à {timeToLvz ? timeToLvz : "-"}</span>
                        <span style={{fontSize: "9px"}}>min</span>
                    </div>
                </div>
                <div className="grid-container-parking-card">
                    <div className="grid-item-parking-card">
                        <span className="card-time">1h</span>
                    </div>
                    <div className="grid-item-parking-card">
                        <span className="euroPrice detailsPanelText">{price1h ? price1h : "-"}€</span>
                    </div>
                    <div className="grid-item-parking-card">
                        <span className="card-time">3h</span>
                    </div>
                    <div className="grid-item-parking-card">
                        <span className="euroPrice detailsPanelText">{price3h ? price3h : "-"}€</span>
                    </div>
                    <div className="grid-item-parking-card">
                        <span className="card-time">24h</span>
                    </div>
                    <div className="grid-item-parking-card">
                        <span className="euroPrice detailsPanelText">{price24h ? price24h : "-"}€</span>
                    </div>
                </div>
                <div style={{height: "10px"}}></div>
            </div>
        </div>
    )
}

export default LvzCard
