import React from 'react'
import { useDispatch } from 'react-redux'

//REDUX ACTIONS
import { toggleModificationContainer } from './state/displayModificationContainerAction'

//STYLE
import * as BiIcon from 'react-icons/bi';
import './ModifyAddressButton.css'

function ModifyAdressButton({addressId}) {

    const dispatch = useDispatch()
    
    function displayModificationContainer(){
        dispatch(toggleModificationContainer({value: true, addressId: addressId}))
    }

    return (
        <>
            <div onClick={()=> displayModificationContainer()} style={{position: "relative"}}>
                <BiIcon.BiDotsVerticalRounded 
                    size={25}
                    style={{color: "var(--cocoparksGreen)"}}
                />
            </div> 
        </>

    )
}

export default ModifyAdressButton
