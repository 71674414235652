import './GreenButton.css'

function GreenButton(props) {

    var IconComponent = props.icon

    return (
        <div>
            <button className="green-button flex-center-inline">
                {IconComponent}
            </button>
        </div>
    )
}

export default GreenButton
