export const TOGGLE_BACK_TO_RECENT_TORCH_ADDRESS = 'TOGGLE_BACK_TO_RECENT_TORCH_ADDRESS'
export const RESET_BACK_TO_RECENT_TORCH_ADDRESS = 'RESET_BACK_TO_RECENT_TORCH_ADDRESS'

export function displayGetBackToRecentTorchAddressReducer(state = false, action) {
    switch (action.type){
        case TOGGLE_BACK_TO_RECENT_TORCH_ADDRESS:
            return action.payload
        case RESET_BACK_TO_RECENT_TORCH_ADDRESS:
            return false
        default: return state
    }
}