import React, { useEffect, useState} from 'react'
import { useSelector } from 'react-redux'

//REDUX SELECTOR
import { sparkedMarkerSelector } from '../markers/state/sparkedMarkerSelector'

//UTILS
import { getCity } from '../api/mapApi';
import * as global from '../global/globalVariable'


//STYLE
import './ButtonGoPopup.css'
import './OpenPayPopup.css'


//component to display paying app as an item of the list includes in OpenPayPopup
function PayAppItem({name, url}){
    let imagePath = "./images/payAppIcon/" + name + "Icon.png"
    return (
        <tr className="appTableLine" style={{cursor: "pointer"}} onClick={()=> window.open(url, "_blank")}>
            <td style={{textAlign: "center"}}><img style={{cursor: "pointer"}} src={imagePath} alt=""/></td>
            <td><p className="nameApp">{name}</p></td>
        </tr>
    )
}


export default function ButtonGoPopup(props) {

    let existingPaymentApps = global.existingPaymentApps
    let cityToPaymentApps = global.cityToPaymentApps
    let existingPaymentAppsURLs = global.existingPaymentAppsURLs

    const [availableAppsInCity, setAvailableAppsInCity] = useState([])
    
    const sparkedMarker = useSelector(sparkedMarkerSelector)

    useEffect(() => {
        //to find out if city has paying app option
        const getAppToPayInCity = async () => {
            let resp = await getCity(sparkedMarker.position)
            if(resp !== null){
                let city = resp.toLowerCase()
                //to store the app available in the city
                let availableAppsInCity = []
                if(cityToPaymentApps[city] != null){
                    let availableAppsInCityIndex = cityToPaymentApps[city];
                    //update the array with the name and url to display in the list
                    availableAppsInCityIndex.forEach(item => {
                        availableAppsInCity.push({
                            name: existingPaymentApps[item],
                            url: existingPaymentAppsURLs[item]
                        });
                    });
                    setAvailableAppsInCity(availableAppsInCity)
                };
            }
        };
        getAppToPayInCity()
    }, [])

    return (
        <div className="popup" onClick={()=> props.setTrigger(false)}>
        <div className="popup-inner" style={{width: "60%"}}> 
            <div className="appSelectPanel">
                    <div className="appSelectPanelTitle"><p style={{margin: "3px"}}><strong>Ouvrir avec :</strong></p></div>
                    <table className="appTable">
                        <tbody>
                            {availableAppsInCity.map((app, index) => <PayAppItem name={app.name} url={app.url} key={index}/>)}
                        </tbody>
                    </table>
                    {availableAppsInCity.length === 0 ?
                        <div>
                            <p className="openpay-subtitle">Pas d'option de paiement où vous êtes garés</p>
                        </div>
                    :null}
                    <div className="closeAppSelectPanel" style={{cursor: "pointer"}}><p style={{margin: "2px"}}>Annuler</p></div>
            </div>
        </div>
    </div>
    )
}
