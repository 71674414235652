import React from 'react'
import { Polygon } from '@react-google-maps/api'

import saintDenisPriceArea from './saintDenisPriceArea.json'

function WrapperPriceArea() {

    const orangePolygons = saintDenisPriceArea.zone_orange
    const greenPolygons = saintDenisPriceArea.zone_verte

    const optionsOrange = {
        fillColor: "#FFBD07",
        fillOpacity: 0.4,
        strokeColor: "#FFBD07",
        strokeOpacity: 0,
        strokeWeight: 2,
        draggable: false,
        zIndex: 1,
        visible: true
    }

    const optionsVert = {
        fillColor: "#1faa00",
        fillOpacity: 0.4,
        strokeColor: "#1faa00",
        strokeOpacity: 0,
        strokeWeight: 2,
        draggable: false,
        zIndex: 1,
        visible: true
    }
    
    return(
        <>
            {orangePolygons.map((polygon, index)=>{
                return(
                    <Polygon
                        paths={polygon}
                        options={optionsOrange}
                        key={index}
                    />
                )
            })}

            {greenPolygons.map((polygon, index)=>{
                return(
                    <Polygon
                        paths={polygon}
                        options={optionsVert}
                        key={index}
                    />
                )
            })}
        </>
    )
}

export default WrapperPriceArea
