const initialState = {lat: 48.8566, lng: 2.3522}

export const UPDATE_MAP_CENTER = 'UPDATE_MAP_CENTER'

export function mapCenterReducer (state = initialState, action) {
    switch (action.type){
        case UPDATE_MAP_CENTER:
            return action.payload
        default: return state
    }
}