import { useState, useRef, useEffect} from 'react'
import { useDispatch, useSelector} from 'react-redux'
import { Autocomplete} from "@react-google-maps/api";

//COMPONENTS
import NavMenuIcon from './NavMenuIcon';
import FavoriteAndRecentAdresses from './FavoriteAndRecentAdresses';

//REDUX SELECTORS
import { researchModeSelector } from '../global/state/researchModeSelector';
import { displayFavoriteAndRecentAddressesSelector } from './state/displayFavoriteAndRecentAddressesSelector';
import { parkingMarkerIdSelector } from '../markers/state/parkingMarkerIdSelector'
import { sparkedModeSelector } from '../global/state/sparkedModeSelector';
import { itineraryModeSelector } from '../global/state/itineraryModeSelector';
import { clickOnSearchBarSelector } from './state/clickOnSearchBarSelector';
import { userLocationSelector } from '../markers/state/userLocationSelector'

//REDUX ACTION
import { toggleExpandedDetailPanel } from '../detailsPanel/state/detailsPanelExpandedAction'
import { toggleResearchMode } from '../global/state/researchModeAction'
import { showCocoSearchMarker } from '../markers/state/cocoSearchMarkerAction'
import { hideFavoritesAddressesDiv, showFavoritesAddressesDiv } from './state/displayFavoriteAndRecentAddressesAction'
import { updateParkingMarkerData } from '../markers/state/parkingMarkerDataAction'
import { updateMarkerId } from "../markers/state/parkingMarkerIdAction";
import { storeLiveParkingsAvailabilities } from '../global/state/liveParkingAvailabilitiesAction'
import { resetPathToDestination } from '../markers/state/directionToDestinationAction'
import { resetClosestLvzPaths } from '../detailsPanel/state/closestLvzPathsAction'
import { showDetailPanelContainer } from '../detailsPanel/state/displayDetailsPanelContainerAction'
import { resetClosestLvzInfo } from '../detailsPanel/state/closestLvzInfoAction';
import { resetClosestParkingsInfo } from '../detailsPanel/state/closestParkingsInfoAction';

//UTILS
import { getParkingData, getParkingsSpotsAvailabilities } from '../api/mapApi';
import { saveSearchedAddressToLS } from '../global/SearchUtils'

//STYLE
import './SearchBar.css'


function SearchBar(props) {
    
    const [destination, setDestination] = useState("")
    const [displayFreezePanel, setDisplayFreezePanel] = useState(false)
    const [disabledResearchInput, setDisabledResearchInput] = useState({value: false, text: ""})

    const autocompleteRef = useRef(null) //to store the map
    const lastResearchPosition = useRef(null) //to store last research position 
    const searchInputRef = useRef(null)

    const researchMode = useSelector(researchModeSelector)
    const displayFavoriteAndRecentAddresses = useSelector(displayFavoriteAndRecentAddressesSelector)
    const parkingMarkerId = useSelector(parkingMarkerIdSelector)
    const sparkedMode = useSelector(sparkedModeSelector)
    const itineraryMode = useSelector(itineraryModeSelector)
    const clickOnSearchBar = useSelector(clickOnSearchBarSelector)
    const userLocation = useSelector(userLocationSelector)

    const options = {
        componentRestrictions: {country: ['fr', 're']}
    };

    const dispatch = useDispatch()


    //to hide favorite and recent adress when a destination is enter
    useEffect(() => {
        if(destination.length > 0){
            dispatch(hideFavoritesAddressesDiv())
        }
    }, [destination, dispatch])

    useEffect(() => {
        setDisplayFreezePanel(displayFavoriteAndRecentAddresses)
    }, [displayFavoriteAndRecentAddresses])


    //to disable research input and display the correct message based on which mode is active (sparkedMode or itinerary)
    useEffect(() => {
        if(sparkedMode.value){
            setDisabledResearchInput({value: true, text: "Vous êtes garés"})
        }else if(itineraryMode){
            setDisabledResearchInput({value: true, text: "Suivons la torche !"})
        } else if(props.geolocationActive === false){
            setDisabledResearchInput({value: true, text: "Géolocalisation désactivée"})
        } else {
            setDisabledResearchInput({value: false, text: ""})
        }
    }, [sparkedMode, itineraryMode])


    //when clicking on freeze panel, coming back to last research and hide favorite Addresses div
    function backToResearch(){
        dispatch(hideFavoritesAddressesDiv())
        setDisplayFreezePanel(false)
        if(researchMode.value){
            props.map.panTo(researchMode.location)
            dispatch(toggleExpandedDetailPanel(true))
            dispatch(resetPathToDestination())
        }
    }

    //to activate click on searchBar when clickOnSearchBar is changing
    useEffect(() => {
        //to avoid click simulation when component is initialize
        if(clickOnSearchBar){
            clickOnSearchBarInput()
            searchInputRef.current.focus()
        }
    }, [clickOnSearchBar])


    function clickOnSearchBarInput(){
        if(destination.length === 0){
            dispatch(toggleExpandedDetailPanel(false))
            //if there isn't any destination filled display favorite div and hide expanded detail panel
            dispatch(showFavoritesAddressesDiv())
        }
    }


    async function displayPlaceOnExpandedDetailPanel(location){
        dispatch(resetClosestLvzInfo())
        dispatch(resetClosestLvzPaths())
        dispatch(resetClosestParkingsInfo())
        props.map.panTo(location)
        await fetchParkingData(location)
        dispatch(showDetailPanelContainer())
        dispatch(toggleResearchMode({value: true, location: location}))
        dispatch(toggleExpandedDetailPanel(true))
        dispatch(showCocoSearchMarker(location))
    }


    const fetchParkingData = async (center) => {
        await getParkingData(center)
        .then(async function(response){
            if(response !== undefined && response.results !== -1){
                //set markersIds with the id of all fetched parking
                await dispatch(updateParkingMarkerData(response.results.parkings))
                let array = parkingMarkerId
                response.results.parkings.forEach((parking)=>{
                    array.push(parking.index)
                })
                let uniqueArrayMarkersIds = array.filter(function(item, pos, self) {
                    return self.indexOf(item) === pos;
                })
                dispatch(updateMarkerId(uniqueArrayMarkersIds))
                await getParkingsSpotsAvailabilities(uniqueArrayMarkersIds)
                .then(function(response){
                    dispatch(storeLiveParkingsAvailabilities(response.results)) 
                })
            }
        })
    };
    

    //MAP RELATED FUNCTION (google-map-react-api)
    function onLoad (autocomplete) {
        autocompleteRef.current = autocomplete;
    }
    

    //MAP RELATED FUNCTION (google-map-react-api)
    function onPlaceChanged () {
        if (autocompleteRef.current !== null) {
            let place = autocompleteRef.current.getPlace()
            if(place.geometry){
                displayPlaceOnExpandedDetailPanel(place.geometry.location.toJSON())
                saveSearchedAddressToLS(place.formatted_address, place.geometry.location.toJSON())
                setDestination("")
                //to keep track of the position of this search if the next one is not completed by the user
                lastResearchPosition.current = place.geometry.location.toJSON()
          }
        } else {
          console.log('Autocomplete is not loaded yet!')
        }
    }


    return (
        <>
            <div className="search-bar">
                <NavMenuIcon/>
                {disabledResearchInput.value ? 
                    <div className="deactivated-search-bar">{disabledResearchInput.text}</div> : 
                    <div style={{width: "90%"}}>
                        <Autocomplete
                            onLoad={onLoad}
                            onPlaceChanged={onPlaceChanged}
                            options={options}
                        >
                            <input 
                                ref={searchInputRef}
                                onChange={(e)=> setDestination(e.target.value)} 
                                value={destination} 
                                placeholder="Quelle est votre destination ?"
                                className="input-destination"
                                onClick={()=> clickOnSearchBarInput()}
                            />
                        </Autocomplete>
                    </div>
                }
                {displayFavoriteAndRecentAddresses ? 
                    <div className="favorite-recent-addresses" style={{position: "absolute", top: "45px"}}>
                        <FavoriteAndRecentAdresses map={props.map}/>
                    </div> : null
                }
            </div>
            {displayFreezePanel ? 
                <div style={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed", top: '0px', left: "0px", bottom: "0px !important", zIndex: "-1", height: "100vh", width: "100vw"}}
                    onClick={()=> backToResearch()}
                >
                </div> : null
            }
        </>
    )
    
}

export default SearchBar

