import {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as IoIcon from 'react-icons/io5';


const useStyles = makeStyles(() => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
    },
    img: {
        maxWidth: 400,
        height: "auto",
        overflow: 'hidden',
        display: 'block',
        width: '100%',
        marginBottom: "10px"
    },
}));

export default function Tutorial(props) {
  
    const tutorialSteps = props.content
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = tutorialSteps.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <div className={classes.root}>
            <Paper square elevation={0} className={classes.header}>
                <Typography style={{fontWeight: "bold", marginBottom: "10px"}}>
                    {tutorialSteps[activeStep].label}
                </Typography>
            </Paper>
            <img
                className={classes.img}
                src={tutorialSteps[activeStep].imgPath}
                alt={tutorialSteps[activeStep].label}
            />
            <Typography className={classes.header} style={{marginBottom: "10px"}}>
                {tutorialSteps[activeStep].legend}
                </Typography>
            <MobileStepper
                steps={maxSteps}
                position="static"
                variant="text"
                activeStep={activeStep}
                nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        {activeStep !== maxSteps - 1 ? 
                            <IoIcon.IoChevronForward style={{color: "black", height: "25px", width: "25px"}} /> 
                            : null
                        }
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {activeStep !== 0 ? 
                            <IoIcon.IoChevronBack style={{color: "black", height: "25px", width: "25px"}} /> 
                            : null
                        }
                    </Button>
                }
            />
        </div>
  );
}