import React from 'react'
import { Polyline } from '@react-google-maps/api'

function DottedDirectionPolyline({path, strokeColor,  fillColor, zIndex}) {
    
    const lineSymbol = {
        path: window.google.maps.SymbolPath.CIRCLE,
        strokeColor: strokeColor,
        strokeOpacity: 0.7,
        strokeWeight: 3,
        fillColor: fillColor,
        fillOpacity: 0.4,
        scale: 5,
    };

    const options =  {
        strokeOpacity: 0,
        icons: [{
            icon: lineSymbol,
            offset: '0', 
            repeat: '17px'
        }],
        zIndex: zIndex
    };

    return (
        <div>
            <Polyline
                path={path}
                options={options}
            />      
        </div>
    )
}

export default DottedDirectionPolyline