import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

//COMPONENTS
import LvzCard from './LvzCard'

//REDUX SELECTORS
import { closestLvzInfoSelector } from './state/closestLvzInfoSelector'
import { lvzMarkerDataSelector } from '../markers/state/lvzMarkerDataSelector'

function WrapperLvzCard() {

    const closestLvzInfo = useSelector(closestLvzInfoSelector)
    const lvzMarkerData = useSelector(lvzMarkerDataSelector)

    const [allLvzCards, setAllLvzCards] = useState([])
    
    useEffect(() => {
        let lvzCardData = []
        closestLvzInfo.forEach((lvz, index)=>{
            lvzCardData.push(
                <LvzCard   
                    key={index}
                    lvzName={lvz.name}
                    lvzId={lvz.lvz_id}
                    freeSlots={lvz.free_slots ? lvz.free_slots : "-"}
                    timeToLvz={lvz.eta}
                    price1h={lvzMarkerData[lvz.lvz_id] ? lvzMarkerData[lvz.lvz_id].price_1_h : "-"}
                    price3h={lvzMarkerData[lvz.lvz_id] ? lvzMarkerData[lvz.lvz_id].price_3_h : "-"}
                    price24h={lvzMarkerData[lvz.lvz_id] ? lvzMarkerData[lvz.lvz_id].price_24_h : "-"}
                    availability={lvz.availability}
                />
            )
        })
        setAllLvzCards(lvzCardData)
    
        return () => {
            //RESET CLOSEST LVZ INFO
        }
    }, [closestLvzInfo])
    

    return (
        <>
            {allLvzCards}
        </>
    )
}

export default WrapperLvzCard
