import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'

//COMPONENTS
import OnstreetCard from './OnstreetCard'
import WrapperParkingCard from './WrapperParkingCard'
import PlaceholderWrapperCard from './PlaceholderWrapperCard'
import WrapperLvzCard from './WrapperLvzCard'

//REDUX SELECTORS
import { parkingMarkerDataSelector } from '../markers/state/parkingMarkerDataSelector'
import { closestParkingsInfoSelector } from '../detailsPanel/state/closestParkingsInfoSelector'
import { streetParkingCostAllTimeSelector } from './state/streetParkingCostAllTimeSelector'
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector'
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'
import { researchModeSelector } from '../global/state/researchModeSelector'
import { closestLvzInfoSelector } from './state/closestLvzInfoSelector'
import { aroundMeModeSelector } from '../global/state/aroundMeModeSelector'
import { userLocationSelector } from '../markers/state/userLocationSelector'
import { closestLvzPathsSelector } from './state/closestLvzPathsSelector'


//REDUX ACTIONS
import { addClosestParkingsInfo } from './state/closestParkingsInfoAction'
import { addClosestLvzInfo } from './state/closestLvzInfoAction'
import { addClosestLvzPaths } from './state/closestLvzPathsAction'
import { storeStreetParkingAllTimeInfo } from './state/streetParkingCostAllTimeAction'
import { updatePathToDestination } from '../markers/state/directionToDestinationAction'
import { resetClosestParkingsInfo } from './state/closestParkingsInfoAction';
import { resetClosestLvzInfo } from './state/closestLvzInfoAction'
import { resetClosestLvzPaths } from './state/closestLvzPathsAction'
import { showCocoSearchMarker } from '../markers/state/cocoSearchMarkerAction'

//UTILS
import { geocodeLatLng, getInsightsFromResearch, convertDistanceToTime, getDriveTimeAndPath} from '../global/MapUtils'
import { getLocationLiveInfo } from '../api/originalDetailsPanelApi';
import { addDataToUserSessionInDB } from '../api/userTrackingApi.js'
import { getClosestLvzInfo } from '../api/liveviewZoneApi'
import { getDistance } from '../global/MapUtils'
import * as global from '../global/globalVariable'

//STYLE
import './ExpandedDetailsPanel.css'


export const ExpandedDetailsPanel = React.memo((props) => {
    
    const [currentAddress, setCurrentAddress] = useState("")

    const parkingMarkerData = useSelector(parkingMarkerDataSelector)
    const closestParkingsInfo = useSelector(closestParkingsInfoSelector)
    const streetParkingCostAllTime = useSelector(streetParkingCostAllTimeSelector)
    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)
    const researchMode = useSelector(researchModeSelector)
    const closestLvzInfo = useSelector(closestLvzInfoSelector)
    const aroundMeMode = useSelector(aroundMeModeSelector)
    const userLocation = useSelector(userLocationSelector)
    const closestLvzPaths = useSelector(closestLvzPathsSelector)

    const dispatch = useDispatch()

    function fetchLvzDataFromMap(){
        if(props.map){
            let center = props.map.getCenter().toJSON()
            props.fetchLvz(center)
        }
    }

    async function fetchParkingData(numberOfCard, center){
        return new Promise (async (resolve)=>{
            if(props.map){
                await geocodeLatLng(center)
                .then(async function(response){
                    setCurrentAddress(response.fulladdress)
                    //to add informations about clicked marker in DB
                    let dataTracking = {
                        searchedAddress : response.fulladdress,
                    }
                    addDataToUserSessionInDB("actions", trackingIdAndAnonymousLocal[1], trackingIdAndAnonymousLocal[0], trackingSessionId, dataTracking)
                    await getInsightsFromResearch(center, parkingMarkerData)
                    .then(async function(researchInsights){
                        let resp = await updateResearchResultWithInsights(researchInsights, numberOfCard)
                        //if there isn't any parking close to location
                        //hide wrapper placeholder
                        if(resp[0] === -1){
                            props.setPlaceholderWrapperCard(false)
                        }
                        dispatch(addClosestParkingsInfo(resp))
                        if(resp[0] !== -1){
                            //to display path to closest parking when expanded detail panel opens
                            await displayPathToDirection(resp[0], center)
                        }
                        resolve("DONE")
                    }); 
                });
                let timeString = "-"
                let streetParkingCostAllTime = "-"
                const resp = await getLocationLiveInfo(center)
                if(resp !== undefined && resp.results !== -1){
                    let possibleValues = [0,1,2,3,4];
                    let associatedMeaning = ["<5", "5-10", "10-15", "15-20", ">20"];
                    if(resp.results.time || resp.results.time === 0){
                        timeString = associatedMeaning[possibleValues.indexOf(resp.results.time)];
                    }
                    if(resp.message !== "No information avalaible in this city"){
                        if(resp.results.price_info !== null && resp.results.price_info !== "-1"){
                            if(resp.results.price_info['is_free'] === false){
                                streetParkingCostAllTime = resp.results.price_info.prices;
                            }else{
                                streetParkingCostAllTime = {"60": "0", "180": "0", "360": "0"}
                            }
                        }
                    }
                }
                dispatch(storeStreetParkingAllTimeInfo(timeString, streetParkingCostAllTime))
            }
        })
    };



    //when a place is selected display the path to this place
    async function displayPathToDirection(closestParking, center){
        let destination = { lat: closestParking.location.lat, lng: closestParking.location.lng }
        let location = { lat: center.lat, lng: center.lng }
        let pathStyle = "dot"
        let drivingTimeAndPath = await getDriveTimeAndPath(location, destination)
        dispatch(updatePathToDestination({path: drivingTimeAndPath.drivePath, style: pathStyle}))
    }  
    
    
    /**
     * It returns a promise that resolves to true if all the elements in the array are within the perimeter
     * radius of the center position
     * @param arrayOfElement - an array of objects with a latitude and longitude property
     * @param center - the center of the perimeter
     * @param perimeterRadius - the radius of the perimeter around the center
     * @returns A promise that resolves to a boolean value.
     */
    function areElementsIncludedInAPerimeterAroundPosition(arrayOfElement, center, perimeterRadius){
        return new Promise((resolve)=>{
            for(const element of arrayOfElement){
                const distance = getDistance(center, element)
                if(distance > perimeterRadius){
                    resolve(false) 
                }
                
            }
            resolve(true)
        })
    }

    useEffect(() => {

        
        if(closestParkingsInfo.length === 0 && closestLvzInfo.length === 0 && closestLvzPaths.length === 0){
            let lvzData = []
            let center = props.map.getCenter().toJSON()
            if(researchMode.value){
                center = researchMode.location
            } else if(aroundMeMode){
                center = userLocation
            }
            
            //to display cocoSearchMarker if user isn't in aroundMeMode
            if(!aroundMeMode){
                dispatch(showCocoSearchMarker(center))
            }
            
            //get data about lvz nearby
            getClosestLvzInfo(center)
            .then(closestLvzData=>{
                if(closestLvzData !== "error"){
                    dispatch(addClosestLvzInfo(closestLvzData.results))
                    lvzData = [...closestLvzData.results]
                }
                
                getItineraryToEachLvz(lvzData, center)
                .then(async ()=>{
                    //fetch parking data to display parking card
                    //Display x card based on the number of LVZ cards. 
                    //The maximum number of cards to display is stored in the variable maxNbOfCardInExpandedDetailPanel
                    setTimeout(()=>{
                        fetchParkingData(global.maxNbOfCardInExpandedDetailPanel - lvzData.length, center)
                        .then(()=>{
                            props.setPlaceholderWrapperCard(false)
                            let lvzLocation = []
                            
                            for(const lvz of lvzData){
                                lvzLocation.push(lvz.location)
                            }
    
                            areElementsIncludedInAPerimeterAroundPosition(lvzLocation, center, 600)
                            .then(resp=>{
    
                                const areLvzWithin600m = resp
                                
                                //update map bounds on lvz location (lvz card)
                                //if they all are within 600m
                                //if we just opened the expanded details panel 
                                //(not if we clicked on back to results)
                                //if there are existing lvz
                                if(areLvzWithin600m && !researchMode.value && lvzLocation.length > 0){
                                    let bounds = new window.google.maps.LatLngBounds()
                                    bounds.extend(center)
                                    for(const lvz of lvzData){
                                        bounds.extend(lvz.location)
                                    }
                                    props.map.fitBounds(bounds)
                                    const boundsZoom = props.map.getZoom()
                                    if(boundsZoom > 18){
                                        props.map.setZoom(18)
                                    }
                                }
                            })
    
                        })
                    }, 1000)
                })
            })
            fetchLvzDataFromMap()
        } else {
            props.setPlaceholderWrapperCard(false)
        }
        return ()=>{
            props.setPlaceholderWrapperCard(true)
        }

    }, [])


    function getItineraryToEachLvz(data, origin){
        return new Promise((resolve)=>{
            if(data.length > 0){
                const tmpData = [...data]
                const directionsService = new window.google.maps.DirectionsService();
                dispatch(resetClosestLvzPaths())
                recursion();
                function recursion() {
                    const lvzItems = tmpData.shift();
                    let stepCoords = []
                    directionsService.route({
                        origin: origin,
                        destination: lvzItems.location,
                        travelMode: 'DRIVING'
                    }, function(res, status){
                        if(status == 'OK'){
                            let path = res.routes[0].legs[0]
                            path.steps.forEach(step=>{
                                step.lat_lngs.forEach(coord=>{
                                    stepCoords.push(coord)
                                })
                            })
                            dispatch(addClosestLvzPaths({
                                availability: lvzItems.availability,
                                path: stepCoords,
                                lvzId: lvzItems.lvz_id
                            }))
                        }
                        if (tmpData.length) {
                            setTimeout(recursion, 1000);
                        } else {
                            resolve("DONE");
                        }
                    });
                }
            } else {
                resolve("DONE")
            }
        })

    }

    
    function updateResearchResultWithInsights(data, nbCard){
        return new Promise(async (resolve)=>{
            // THEN TREAT PARKING CARDS
            //create parking card only for parking located 
            //less than 6000m from position
            let maxDistance = 6000
            // do not exceed number of parking
            let nbOfCards = Math.min(nbCard, data.ranksDists.length);
            let array = []
            // calculate time to reach parkings related to cards
            for(let x = 1; x<=nbOfCards ; x++){
                let index = data.ranksDists.indexOf(x);
                //in case of two parking location at the exact same distance from position
                if(index === -1){
                    index = data.ranksDists.indexOf(x-1)
                    if(data.ranksDists.indexOf(x-1, index+1) !== -1){
                        let newIndex = data.ranksDists.indexOf(x-1, index+1);
                        let time = 0
                        time = await convertDistanceToTime(data.distsArray[newIndex]);
                        if(data.distsArray[newIndex] < maxDistance){
                            array.push({ ...parkingMarkerData[newIndex], timeToReachParking: time, index: newIndex})
                        }
                    }
                } else {
                    let time = 0
                    time = await convertDistanceToTime(data.distsArray[index]);
                    if(data.distsArray[index] < maxDistance){
                        array.push({ ...parkingMarkerData[index], timeToReachParking: time, index: index})
                    }
                }
            }
            if(array.length === 0){
                array.push(-1)
            }
            resolve(array);
        })
    }

    useEffect(() => {
        if(closestParkingsInfo.length !== 0 && streetParkingCostAllTime.streetParkingTime !== null && streetParkingCostAllTime.streetParkingCostAllTime !== null){
            props.setPlaceholderWrapperCard(false)
        } else if(!props.placeholderWrapperCard){
            //if placeholderWrapperCard has already been 
            //set to false, ensure that it stay false
            props.setPlaceholderWrapperCard(false)
        } else {
            props.setPlaceholderWrapperCard(true)
        }
    }, [closestParkingsInfo, streetParkingCostAllTime, props.placeholderWrapperCard])


    return (
        <div className="flex-center-column" style={{width: "100%"}}>
            <p className="researchAddress">{currentAddress}</p>
            <div className="containerCards" >
            {props.placeholderWrapperCard ?
                <PlaceholderWrapperCard/>
                :<>
                    <OnstreetCard/>  
                    <WrapperLvzCard/>
                    <WrapperParkingCard 
                        map={props.map}
                    />
                </>
            }
            </div>
        </div>
    )
})

export default ExpandedDetailsPanel
