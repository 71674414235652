import { SELECT_PARKING_MARKER, RESET_PARKING_MARKER } from "./selectedParkingMarkerReducer"

export const selectParkingMarker = (status, key) => ({
    type: SELECT_PARKING_MARKER,
    payload: {status: status, key: key}
})

export const resetSelectedParkingMarker = () => ({
    type: RESET_PARKING_MARKER,
})

