
import React from 'react'
import {useSelector} from 'react-redux'

//COMPONENTS
import OnstreetCommonMarker from './OnstreetCommonMarker'

//REDUX SELECTORS
import { onstreetCommonMarkerDataSelector } from './state/onstreetCommonMarkerDataSelector'

//UTILS
import { generateKey } from '../global/MapUtils'


const WrapperOnstreetCommonMarker = React.memo((props) => {

    let allOnstreetCommonMarker = []

    const onstreetCommonMarkerData = useSelector(onstreetCommonMarkerDataSelector)

    
    //mapping through data to render all OnstreetMarker. Passing their properties using props.
    Object.keys(onstreetCommonMarkerData.data).forEach((zoneId) => {
        onstreetCommonMarkerData.data[zoneId].forEach((marker, index)=>{

            allOnstreetCommonMarker.push(
                <OnstreetCommonMarker 
                    key={generateKey(zoneId, index)}
                    
                    info={{
                        path: marker.polygon,
                        zoneId: zoneId
                    }}
                    
                />
            )
        })
    });



    return (
        <div>
            {allOnstreetCommonMarker}
        </div>
    )
})

export default WrapperOnstreetCommonMarker
