import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

//COMPONENTS
import EvhMarker from './EvhMarker'
import EvhInfoWindow from './EvhInfoWindow'

//REDUX SELECTORS
import { evhMarkerDataSelector } from './state/evhMarkerDataSelector'
import { evhInfoWindowSelector } from './state/evhInfoWindowSelector'
import { itineraryModeSelector } from '../global/state/itineraryModeSelector'

//REDUX ACTIONS
import { hideEvhInfoWindow } from './state/evhInfoWindowAction'

//UTILS
import { generateKey } from '../global/MapUtils'


function WrapperEvhMarker(){

    const dispatch = useDispatch()

    let allEvhMarkers = []

    const evhMarkerData = useSelector(evhMarkerDataSelector)
    const evhInfoWindow = useSelector(evhInfoWindowSelector)
    const itineraryMode = useSelector(itineraryModeSelector)

    useEffect(() => {
        //to hide infoWindow if torch is active
        if(itineraryMode){
            dispatch(hideEvhInfoWindow())
        }
    }, [itineraryMode])


    //mapping through data to render all EvhMarkers. Passing their properties using props.
    Object.keys(evhMarkerData.data).forEach((zoneId) => {
        evhMarkerData.data[zoneId].forEach((marker, index)=>{
            allEvhMarkers.push(
                <EvhMarker 
                    key={generateKey(zoneId, index)}
                    
                    location={{
                        lat: marker.lat,
                        lng: marker.lng
                    }}
                    
                    info={{
                        specificity: marker.specificity,
                        surroundingCityZone: evhMarkerData.surroundingCityZone,
                        zoneId: zoneId
                    }}
                />
            )
        })
    });
    
    return (
        <div>
            {allEvhMarkers}
            {evhInfoWindow.visible ? 
                <EvhInfoWindow/>
            :null}
        </div>
    )
}

export default WrapperEvhMarker
