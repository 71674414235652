import React from 'react'

//UTILS
import { formatDateDDMMYYY, formatHoursHHhMM } from '../global/GlobalUtils';

//STYLE
import * as RiIcon from 'react-icons/ri';
import './HistoryItem.css'

export default function HistoryItem({start, end , address, ttp, ptype, pcompany, pname}) {

    let colorBlue = {color: "var(--dark-blue)"}
    let colorLightBlue = {backgroundColor: "var(--light-blue)"}
    let colorGreen = {color: "var(--dark-green)"}
    let colorLightGreen = {backgroundColor: "var(--light-green)"}
    let typeString = "Voirie"
    
    let darkColor = colorGreen
    let lightColor = colorLightGreen

    if(ptype !== "onstreet"){
        darkColor = colorBlue
        lightColor = colorLightBlue  
        typeString = "Parking"
    }

    return (
        <div className="item-container flex-flex-start-column">
            <div className="type-title-div" style={lightColor}>
                <p className="no-margin" style={darkColor}>{typeString}</p>
            </div>
            <div className="flex-space-between-inline" style={{width: "100%"}}>
                    {ttp ? 
                        <div className="flex-center-column" style={{width: "40%", padding: "10px 28px"}}>
                            <p className="no-margin time-text" >{ttp}</p>
                            <p className="no-margin time-text-min">min de recherche</p>
                        </div>
                    :null}
                <div className="flex-center-column date-container" style={{width: "100%"}}>
                    {end !== "0" ?
                    <>
                        <p className="no-margin date-text" style={{color: "#2C2F33"}}><span className="no-margin date-text-subtitle">Du </span>{formatDateDDMMYYY(start)}</p>  
                        <p className="no-margin hours-text" style={{color: "#2C2F33"}}>{formatHoursHHhMM(start)}</p>  
                        <div style={{height: "15px", width: "100%"}}></div>
                        <p className="no-margin date-text" style={{color: "#2C2F33"}}><span className="no-margin date-text-subtitle">Au </span>{formatDateDDMMYYY(end)}</p>  
                        <p className="no-margin hours-text" style={{color: "#2C2F33"}}>{formatHoursHHhMM(end)}</p>  
                    </>
                    
                    :
                    <>
                        <p className="no-margin date-text" style={{color: "#2C2F33"}}><span className="no-margin date-text-subtitle">Le </span>{formatDateDDMMYYY(start)}</p>  
                        <p className="no-margin hours-text" style={{color: "#2C2F33"}}>{formatHoursHHhMM(start)}</p>  
                    </>}
                </div>
            </div>
            <div className="flex-flex-start-inline address-div" style={lightColor}>
                <RiIcon.RiMapPinLine   
                    style={darkColor}
                    size={"20px"}
                />
                {address !== "" ? <p className="address-text" style={darkColor}>{address}</p> : 
                    <div className="address-text" style={darkColor}>
                        <p className="no-margin">Parking : {pname}</p>
                        <p className="no-margin">Exploitants : {pcompany}</p>                
                    </div>
                }
            </div>
        </div>
    )
}
