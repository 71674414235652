const initialeState = {value: false, messageType: ""}

export const TOGGLE_INTERACTIVE_BOX = 'TOGGLE_INTERACTIVE_BOX'

export function displayInteractiveBoxReducer(state = initialeState, action) {
    switch (action.type){
        case TOGGLE_INTERACTIVE_BOX:
            return action.payload
        default: return state
    }
}