import React from 'react'
import { useSelector, useDispatch } from 'react-redux';

//COMPONENT
import ModifyAdressButton from './ModifyAddressButton';

//REDUX SELECTOR
import { userInfoJsonSelector } from '../userMenu/state/userInfoJsonSelector';
import { parkingMarkerIdSelector } from '../markers/state/parkingMarkerIdSelector'
import { userUidSelector } from '../global/state/userUidSelector';
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'

//REDUX ACTIONS
import { toggleExpandedDetailPanel } from '../detailsPanel/state/detailsPanelExpandedAction'
import { toggleResearchMode } from '../global/state/researchModeAction'
import { showCocoSearchMarker } from '../markers/state/cocoSearchMarkerAction'
import { hideFavoritesAddressesDiv } from '../navBar/state/displayFavoriteAndRecentAddressesAction'
import { updateParkingMarkerData } from '../markers/state/parkingMarkerDataAction'
import { updateMarkerId } from "../markers/state/parkingMarkerIdAction";
import { storeLiveParkingsAvailabilities } from '../global/state/liveParkingAvailabilitiesAction'
import { toggleUserMenu } from './state/displayUserMenuAction ';
import { toggleAddAddressPopup } from '../popup/state/displayAddAddressPopupAction';
import { toggleMustLoginScreen } from './state/displayMustLoginScreenAction';


//UTILS
import { getParkingData, getParkingsSpotsAvailabilities } from '../api/mapApi';
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//STYLE
import * as IoIcon from 'react-icons/io5';
import * as RiIcon from 'react-icons/ri';


function UserAddressesContainer(props) {
    
    const dispatch = useDispatch()

    let iconSize = 25
    let positionArray = []
    let numberOfPositionDisplayed = 3
    let homeAddress = "Ajouter votre domicile"
    let modifyButtonHome = false
    let exisitingHomeAddress = false
    let workAddress = "Ajouter votre travail"
    let modifyButtonWork = false
    let exisitingWorkAddress = false
    let otherAddressTitle
    let otherAddressSubtitle
    let otherFavoriteName = ["first", "second", "third"]

    const userInfoJson = useSelector(userInfoJsonSelector)
    const parkingMarkerId = useSelector(parkingMarkerIdSelector)
    const userUid = useSelector(userUidSelector)
    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)

    for(let i=0; i<numberOfPositionDisplayed; i++){
        let number = otherFavoriteName[i]
        let displayModifyIcon = false
        let address = ""
        let exisitingAddress = false
        if(userInfoJson.savedAddresses && userInfoJson.savedAddresses[number]){
            if(userInfoJson.savedAddresses[number]["address"]){                
                otherAddressTitle = userInfoJson.savedAddresses[number].name
                otherAddressSubtitle = userInfoJson.savedAddresses[number].address
                address = userInfoJson.savedAddresses[number].address
                if(props.allowModification){
                    displayModifyIcon = true
                }
                exisitingAddress = true
            } else {
                otherAddressTitle = "Ajouter un lieu"
                otherAddressSubtitle = "..."
                address = ""
            }
        } else {
            otherAddressTitle = "Ajouter un lieu"
            otherAddressSubtitle = "..."
            address = ""
        }
        positionArray.push(
        <div className="flex-space-between-inline" style={{width: "90%"}} key={i} onClick={()=> updateDb()}>
            <div className="flex-space-between-inline" style={{width: "90%"}} 
                onClick={()=>{
                    //if an address exists then 
                    //the onClick will launch a research from this address
                    //else the onClick will display a popup to add a new address
                    if(exisitingAddress){
                        checkResearchAddress(address)
                    } else {
                        addAnAddress(number)
                    }
                }}
            >
                <RiIcon.RiMapPinLine 
                    size={iconSize}
                    style={{color: "var(--cocoparksGreen)", margin: "15px"}}
                />
                <div style={{flex: "2", width: "60%"}}>
                    <p className="no-margin address-container-address-title" >{otherAddressTitle}</p>
                    <p className="no-margin address-container-address-text">{otherAddressSubtitle}</p>
                </div>
            </div>
            {displayModifyIcon ?
                <ModifyAdressButton addressId={userInfoJson.savedAddresses[number].addressId}/>
            :null}
        </div>)
    }

    if(userInfoJson.savedAddresses && userInfoJson.savedAddresses["home"]){
        if(userInfoJson.savedAddresses["home"]["address"]){
            homeAddress = userInfoJson.savedAddresses["home"]["address"]
            exisitingHomeAddress = true
            if(props.allowModification){
                modifyButtonHome = true
            }
        }
    }
    if(userInfoJson.savedAddresses && userInfoJson.savedAddresses["work"]){
        if(userInfoJson.savedAddresses["work"]["address"]){
            workAddress = userInfoJson.savedAddresses["work"]["address"]
            exisitingWorkAddress = true
            if(props.allowModification){
                modifyButtonWork = true
            }
        }
    }

    function addAnAddress(addressId){
        //to add an address only if the user is logged in
        if(userUid !== null){
            dispatch(toggleAddAddressPopup({value: true, addressId: addressId, update: false}))
        } else {
            dispatch(toggleMustLoginScreen(true))
            dispatch(hideFavoritesAddressesDiv())
        }
    }

    const fetchParkingData = async (center) => {
        await getParkingData(center)
        .then(async function(response){
            if(response !== undefined && response.results !== -1){
                //set markersIds with the id of all fetched parking
                await dispatch(updateParkingMarkerData(response.results.parkings))
                let array = parkingMarkerId
                response.results.parkings.forEach((parking)=>{
                    array.push(parking.index)
                })
                let uniqueArrayMarkersIds = array.filter(function(item, pos, self) {
                    return self.indexOf(item) === pos;
                })
                dispatch(updateMarkerId(uniqueArrayMarkersIds))
                await getParkingsSpotsAvailabilities(uniqueArrayMarkersIds)
                .then(function(response){
                    dispatch(storeLiveParkingsAvailabilities(response.results)) 
                })
            }
        })
    };

    //to update expanded detail panel based on item clicked
    async function updateExpandedDetailsPanel(location){
        //to hide usermenu
        await fetchParkingData(location)
        let destination = { lat: location.lat, lng: location.lng }
        props.map.panTo(destination)
        dispatch(toggleResearchMode({value: true, location: location}))
        dispatch(toggleExpandedDetailPanel(true))
        dispatch(showCocoSearchMarker(location))
        dispatch(hideFavoritesAddressesDiv())
        setTimeout(function(){
            dispatch(toggleUserMenu(false))
        }, 1000)
    }

    function checkResearchAddress(address){
        if( address !== undefined && address !== ""){
            launchResearchFromAddress(address)
        }
    }

    function launchResearchFromAddress(address){
        var request = {
            query: address,
            fields: ['name', 'geometry'],
        };
        var service = new window.google.maps.places.PlacesService(props.map);
        service.findPlaceFromQuery(request, function(results, status) {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                let location = {lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()}
                updateExpandedDetailsPanel(location)
            }
        });
    } 

    function updateDb(){
        addActionToDbBasedOnElement("addressContainerContent", trackingIdAndAnonymousLocal, trackingSessionId)
    }

    return (
        <>
            {/* ADDRESS HOME */}
            <div className="flex-space-between-inline" style={{width: "90%"}} onClick={()=> updateDb()}>
                <div className="flex-space-between-inline" style={{width: "90%"}} 
                    onClick={()=>{
                        //if an address exists for "home" then 
                        //the onClick will launch a research from this address
                        //else the onClick will display a popup to add a new address
                        if(exisitingHomeAddress){
                            checkResearchAddress(homeAddress)
                        } else {
                            addAnAddress("home")
                        }
                    }}
                >
                    <IoIcon.IoHomeOutline 
                        size={iconSize}
                        style={{color: "var(--cocoparksGreen)", margin: "15px"}}
                    />
                    <div style={{flex: "2", width: "60%"}} >
                        <p className="no-margin address-container-address-title" >Domicile</p>
                        <p className="no-margin address-container-address-text">
                            {homeAddress}
                        </p>
                    </div>
                </div>
                {modifyButtonHome ?
                    <ModifyAdressButton addressId={"home"}/>
                :null}
            </div>

            {/* ADDRESS WORK */}
            <div className="flex-space-between-inline" style={{width: "90%"}} onClick={()=> updateDb()}>
                <div className="flex-space-between-inline" style={{width: "90%"}} 
                    onClick={()=>{
                        //if an address exists for "work" then 
                        //the onClick will launch a research from this address
                        //else the onClick will display a popup to add a new address
                        if(exisitingWorkAddress){
                            checkResearchAddress(workAddress)
                        } else {
                            addAnAddress("work")
                        }
                    }}
                >
                    <IoIcon.IoBriefcaseOutline 
                        size={iconSize}
                        style={{color: "var(--cocoparksGreen)", margin: "15px"}}
                    />
                    <div style={{flex: "2", width: "60%"}}>
                        <p className="no-margin address-container-address-title" >Travail</p>
                        <p className="no-margin address-container-address-text">{workAddress}</p>
                    </div>
                </div>
                {modifyButtonWork ?
                    <ModifyAdressButton addressId={"work"}/>
                :null}
            </div>

            {/* ADDRESS POSITION */}
            {positionArray}
        </>
    )
}

export default UserAddressesContainer
