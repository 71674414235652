import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import firebase from '../firebaseConfig';

//REDUX SELECTOR
import { displayZoneWithRegulationsButtonSelector } from './state/displayZoneWithRegulationsButtonSelector';

//UTILS
import liveviewZoneRegulationLegend from "./liveviewZoneRegulationLegend.json"

//STYLE
import './ZoneWithRegulationsButton.css'
import * as IoIconStandard from 'react-icons/io';
import * as IoIcon from 'react-icons/io5';

function ZoneWithRegulationsButton() {

    const displayZoneWithRegulationsButton = useSelector(displayZoneWithRegulationsButtonSelector)
    const [displayLegendContainer, setDisplayLegendContainer] = useState(false)
    const [legend, setLegend] = useState([])
    const [zoneTypeName, setZoneTypeName] = useState("")

    const storage = firebase.storage();
    let gsReference = storage.refFromURL('gs://database-dev-fcdce.appspot.com')

    useEffect(() => {
        //TO BE DELETE WHEN FETCH TO FIREBASE WILL WORK
        let pathToRegulation = displayZoneWithRegulationsButton.pathToRegulation
        //to remove regulations/ from path to regulation from back
        let zoneRegulations = pathToRegulation.split('regulations/')[1]
        //to remove .json from path to regulation from back
        zoneRegulations = zoneRegulations.replace(".json", "")        
        
        setZoneTypeName(liveviewZoneRegulationLegend[zoneRegulations].zoneTypeTitle)
        setLegend(liveviewZoneRegulationLegend[zoneRegulations].caption)
        
        //TO BE UNCOMMENT WHEN FETCH TO FIREBASE WILL WORK       
        //to fetch regulation json from firebase
        // gsReference.child(displayZoneWithRegulationsButton.pathToRegulation).getDownloadURL().then(function(url){
        //     fetch(url).then(response => {
        //         return response.json();
        //     }).then(data => {
        //         setZoneTypeName(data.zoneTypeTitle)
        //         setLegend(data.caption)
        //     }).catch(err => {
        //         console.log(err)
        //     });
        // })   
    }, [])
    function hideLegendContainer(){
        setDisplayLegendContainer(false)
    }

    function showLegendContainer(){
        setDisplayLegendContainer(true)
    }

    function toggleLegendContainer(){
        if(displayLegendContainer){
            hideLegendContainer()
        }else{
            showLegendContainer()
        }
    }

    return (
        <div className="zone-with-regulations-container flex-flex-start-center-column">
            <div className="flex-space-between-inline zone-with-regulations-button" onClick={()=> toggleLegendContainer()}>
                <p className="text-zone-with-regulations no-margin">{displayZoneWithRegulationsButton.title} - {zoneTypeName}</p>
                {displayLegendContainer ?
                    <IoIconStandard.IoIosRemove className="icon-zone-with-regulations"/>
                    :<IoIconStandard.IoIosAdd className="icon-zone-with-regulations"/>
                }
            </div>
            {displayLegendContainer ?
                <div className="zone-with-regulations-legend-container">
                    {legend.map((item, index)=>{
                        return (
                            <div key={index}>
                                <p className="title-zone-with-regulations-legend">{item.title}</p>
                                <p className="text-zone-with-regulations-legend">{item.subtitle}</p>
                            </div>
                        )
                    })}
                    <IoIcon.IoClose 
                        style={{color: "var(--blue)", fontSize: "1.5em", position: "absolute", top: "4px", right: "4px"}}
                        onClick={()=> hideLegendContainer()}
                    />
                </div>
            :null}
        </div>
    )
}

export default ZoneWithRegulationsButton
