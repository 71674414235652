import { createUserSessionInDB, addDataToUserSessionInDB } from '../api/userTrackingApi'

/**
* Create a new User Tracking Session and return the sessionName
* @returns {String} - equals to the sessionName
*/
export async function createUserTrackingSession(trackingIdAndAnonymousLocal){
    let cocoparksVersionLS = localStorage.getItem('cocoparksVersion')
    let trackingSession = await createUserSessionInDB(trackingIdAndAnonymousLocal[1], trackingIdAndAnonymousLocal[0], cocoparksVersionLS);
    // // Used to track parking loading speed when launching the app
    // addDataToUserSessionInDB("actions", trackingIdAndAnonymousLocal[1], trackingIdAndAnonymousLocal[0], trackingSession, trackingParkingTimeToLoad);
    // //Track if geolocation is activated or not
    // if(geoLocActivate == true || geoLocActivate == false){
    //     let dataTracking = {geoLocActivate:geoLocActivate}
    //     addDataToUserSessionInDB("actions", trackingIdAndAnonymousLocal[1], trackingIdAndAnonymousLocal[0], trackingSession, dataTracking);
    // }
    return trackingSession;
}

/**
  * Add the data made by the user to the database. (User Tracking)
  * @param {number} id - Id of the element the user clicked on
  * @param {Array} trackingIdAndAnonymousLocal - True : the user is anonymous / False : the user is authentified
  * @param {string} trackingSession - Id of the actual user's session
  */
export function addActionToDbBasedOnElement(id, trackingIdAndAnonymousLocal, trackingSession){
    addDataToUserSessionInDB("actions", trackingIdAndAnonymousLocal[1], trackingIdAndAnonymousLocal[0], trackingSession, id);
}