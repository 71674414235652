import React from 'react'
import { useSelector } from 'react-redux'

//REDUX SELECTOR
import { sparkedCocoMarkersDataSelector } from '../socketio/state/sparkedCocoMarkersDataSelector'

//COMPONENTS
import SparkedCocoMarker from './SparkedCocoMarker'

function WrapperSparkedCocoMarkers(){

    const  sparkedCocoMarkersData = useSelector( sparkedCocoMarkersDataSelector)

    return (
        <div>
            { sparkedCocoMarkersData.length > 0 ?  sparkedCocoMarkersData.map((marker, index)=>{
                return (
                    <SparkedCocoMarker 
                        key={index}
                        
                        location={{
                            lat: marker.position.lat,
                            lng: marker.position.lng
                        }}

                        info = {{
                            ttp: marker.ttp,
                            start: marker.start,
                            constrSite: marker.constrSite,
                        }}
                
                    />
                )
            }):null}
        </div>
    )
}

export default WrapperSparkedCocoMarkers
