import React from "react";
import { useSelector } from "react-redux";

// UTILS
import { isCurrentTimeInParkingOpenHours } from "../utils";

//COMPONENTS
import ParkingCard from "./ParkingCard";

//REDUX SELECTORS
import { parkingMarkerDataSelector } from "../markers/state/parkingMarkerDataSelector";
import { liveParkingAvailabilitiesSelector } from "../global/state/liveParkingAvailabilitiesSelector";
import { closestParkingsInfoSelector } from "./state/closestParkingsInfoSelector";

function WrapperParkingCard(props) {
  let allParkingCard = [];
  let cardTitle = "Ouvert";
  let cardSubtitle = null;
  let cardTitleColor = "rgba(0,69, 149,1)";
  let liveParkingsThreshold = [0, 0.9, 0.98, 1.01];

  const parkingMarkerData = useSelector(parkingMarkerDataSelector);
  const liveParkingAvailabilities = useSelector(
    liveParkingAvailabilitiesSelector,
  );
  const closestParkingsInfo = useSelector(closestParkingsInfoSelector);

  //mapping through data to render all OnstreetMarker. Passing their properties using props.
  closestParkingsInfo.forEach((parking, index) => {
    cardSubtitle = null;
    if (closestParkingsInfo[0] !== -1) {
      //to get from parkingMarkerData array the index (used in back) of the parking
      let indexFromBack = parkingMarkerData[parking.index].index;
      let fillingRatio = null;
      // update sub title of the card
      if (parking.reservation_only === true) {
        cardTitle = "Sur réservation";
        cardTitleColor = "red";
      } else {
        const openHours =
          typeof parking.open_hours === "object" ? parking.open_hours : {};
        const isOpenNow = isCurrentTimeInParkingOpenHours(
          openHours,
          parking.timezone,
        );
        if (
          parking.specific_message !== null &&
          parking.specific_message.is_active === true
        ) {
          const countMsg = parking.specific_message.messages.length;
          cardTitle = countMsg > 0 ? parking.specific_message.messages[0] : "";
          cardSubtitle =
            countMsg > 1 ? parking.specific_message.messages[1] : null;
          cardTitleColor = "var(--cocoparksGrey)";
        } else if (isOpenNow) {
          if (liveParkingAvailabilities[indexFromBack]) {
            fillingRatio =
              liveParkingAvailabilities[indexFromBack].filling_ratio;
            if (fillingRatio >= liveParkingsThreshold[2]) {
              cardTitle = "Ouvert - Complet";
              cardTitleColor = "#FA0000";
            } else {
              cardTitle = "Ouvert - Dispo";
              cardTitleColor = "rgba(0,69, 149,1)";
            }
          } else {
            cardTitle = "Ouvert";
            cardTitleColor = "rgba(0,69, 149,1)";
          }
        } else {
          cardTitle = "Fermé";
          cardTitleColor = "#FA0000";
        }
      }
      allParkingCard.push(
        <ParkingCard
          key={index}
          info={{
            name: parking.name,
            operator: parking.operator,
            timeToReachParking: parking.timeToReachParking,
            price_1_h: parking.price_1_h,
            price_3_h: parking.price_3_h,
            price_24_h: parking.price_24_h,
            title: cardTitle,
            subtitle: cardSubtitle,
            color: cardTitleColor,
            index: parking.index,
            fillingRatio: fillingRatio,
            isOpenNow: isCurrentTimeInParkingOpenHours(
              parking.open_hours,
              parking.timezone,
            ),
            id: "RR" + (index + 1),
          }}
          map={props.map}
        />,
      );
    }
  });

  return <>{allParkingCard}</>;
}

export default WrapperParkingCard;
