import { UPDATE_PATH_TO_DESTINATION, RESET_PATH_TO_DESTINATION } from "./directionToDestinationReducer"

export const updatePathToDestination = (object) => ({
    type: UPDATE_PATH_TO_DESTINATION,
    payload: object
})


export const resetPathToDestination = () => ({
    type: RESET_PATH_TO_DESTINATION,
})