import { handleResponse } from "./utilsApi"
import * as url from './routes'


export function getCustomerConfig(customer){
    let response = fetch(url.getCustomerConfig + customer)
    .then(handleResponse)
    .then(data => {
        return data
    })
    .catch((error) => console.log('error: ' + error))
    return response
}
