import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { OverlayView } from '@react-google-maps/api'

//REDUX SELECTOR
import { frontStyleEvhSelector } from './state/frontStyleEvhSelector';
import { itineraryModeSelector } from '../global/state/itineraryModeSelector'

//REDUX ACTIONS
import { showEvhInfoWindow } from './state/evhInfoWindowAction';

//JSON
import jsonZoomUIStatuses from '../zoom/jsonZoomUIStatuses.json'

function EvhMarker(props){

    const dispatch = useDispatch()

    const frontStyleEvh = useSelector(frontStyleEvhSelector)
    const itineraryMode = useSelector(itineraryModeSelector)

    let displayMarker = "hidden"
    let evhType

    //to display evhMarker only if itineraryMode (torch) isn't active
    if(!itineraryMode){
        //to display marker if there are in the surroundingCityZone
        if(props.info.surroundingCityZone.find(id => id === props.info.zoneId)){
            displayMarker = "visible"
        }
    }

    //allocated the right image path and className to marker based on jsonZoomUIStatuses.json
    let imgPath = jsonZoomUIStatuses["evh"].icons[frontStyleEvh].imgSrc
    let className = jsonZoomUIStatuses["evh"].icons[frontStyleEvh].className

    //to allocated the right icon to the marker based on its properties (either evh slot with charging station or not)
    if(props.info.specificity.includes("sans recharge") === false){
        imgPath = imgPath[0]
        evhType = "avec recharge"
    } else {
        imgPath = imgPath[1]
        evhType = "sans recharge"
    }

    function displayEvhMarkerInfoWindow(location){
        if(frontStyleEvh === 2){
            dispatch(showEvhInfoWindow({location: location, type: evhType}))
        }
    }

    return (
        <div onClick={()=> displayEvhMarkerInfoWindow(props.location)}>
            <OverlayView 
                position={{lat : props.location.lat, lng : props.location.lng}}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={(width, height) => ({
                    x: -(width / 2),
                    y: -(height / 2)
                })}
            >
                <img style={{visibility: displayMarker}} className={className} src={imgPath} alt="Logo"/>                    
            </OverlayView> 
        </div>
    )
}

export default EvhMarker
