import React, { useState} from 'react'

//COMPONENTS
import HelpActivateGeolocPopup from '../popup/HelpActivateGeolocPopup';

//STYLE
import * as IoIcon from 'react-icons/io5';
import './GeolocationActivationBanner.css'


function GeolocationActivationBanner() {

    //to hide/show geoloc legend popup
    const [showPopupGeolocationActivate, setShowPopupGeolocationActivate] = useState(false)

    return (
        <>
            <div className="warning-container"
                onClick={()=>setShowPopupGeolocationActivate(true)}
            >
                <IoIcon.IoWarning
                style={{
                    fontSize: "50px",
                    color: "var(--filling-orange)"
                }}
                />
                <div className="text-container">
                    <h2 className="warning-title">Attention - Géolocalisation désactivée</h2>
                    <p className="warning-text">Vous devez activer la géolocalisation pour pouvoir utiliser Cocoparks </p>
                </div>
            </div>
            {showPopupGeolocationActivate ? <HelpActivateGeolocPopup setTrigger={setShowPopupGeolocationActivate}/> : null}

        </>
    )
}

export default GeolocationActivationBanner
