import React from 'react'
import { InfoWindow, Marker} from '@react-google-maps/api'
import { useSelector } from 'react-redux'

//COMPONENTS
import WalkingCircleMarker from './WalkingCircleMarker';

//REDUX SELECTORS
import { cocoSearchMarkerSelector } from './state/cocoSearchMarkerSelector';

function CocoSearchMarker() {

    const cocoSearchMarker = useSelector(cocoSearchMarkerSelector)

    if(cocoSearchMarker.visible === true){
        let position = {lat : cocoSearchMarker.position.lat, lng : cocoSearchMarker.position.lng}
        let image = {
            url: "images/markers/FreedSpot-mkr.png",
            scaledSize: new window.google.maps.Size(35, 45), // scaled size
        };
    
        const infowindowStyle = {
            fontWeight: "bold",
            color: "var(--cocoparksGreen)",
        }
    
        return (
            <>
                <Marker 
                    position={position}
                    icon={image}
                    zIndex={20}
                ></Marker>
                <InfoWindow
                    position={position}
                    options={{pixelOffset: new window.google.maps.Size(0,-50)}}
                    >
                    <div style={infowindowStyle}>
                        <p style={{margin: "0px"}}>Votre destination</p>
                    </div>
                </InfoWindow>
                <WalkingCircleMarker
                    info={{
                        position: position,
                        label: "5min",
                        radius: 300
                    }}
                />
                <WalkingCircleMarker
                    info={{
                        position: position,
                        label: "10min",
                        radius: 600
                    }}
                />
            </>
        )
    } else {
        return <></>
    }
    
}

export default CocoSearchMarker
