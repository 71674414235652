export const ADD_CLOSEST_PARKINGS_INFO = 'ADD_CLOSEST_PARKINGS_INFO'
export const RESET_CLOSEST_PARKINGS_INFO = 'RESET_CLOSEST_PARKINGS_INFO'

export function closestParkingsInfoReducer (state = [], action) {
    switch (action.type){
        case ADD_CLOSEST_PARKINGS_INFO:
            return action.payload
        case RESET_CLOSEST_PARKINGS_INFO: 
            return []
        default: return state
    }
}