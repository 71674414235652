import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

//REDUX SELECTORS
import { userLocationSelector } from '../markers/state/userLocationSelector';
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'

//REDUX ACTIONS
import { toggleParkingResearchFromIntro } from './state/displayParkingResearchFromIntroAction';
import { toggleExpandedDetailPanel } from '../detailsPanel/state/detailsPanelExpandedAction';
import { toggleResearchMode } from '../global/state/researchModeAction';
import { showCocoSearchMarker } from '../markers/state/cocoSearchMarkerAction';
import { activateClickOnSearchBar } from '../navBar/state/clickOnSearchBarAction'

//UTILS
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//STYLE
import * as IoIcon from 'react-icons/io5';


function ParkingResearchFromIntroduction() {

    const dispatch = useDispatch()

    const userLocation = useSelector(userLocationSelector)
    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)

    //to hide the actual component
    function closeParkingResearchFromIntro(){
        dispatch(toggleParkingResearchFromIntro(false))
    }

    //to display research parking from userLocation
    function launchResearchAroundMe(location){
        //to open expandedDetailsPanel and display the closest parking
        dispatch(toggleExpandedDetailPanel(true))
        dispatch(toggleResearchMode({value: true, location: location}))
        dispatch(showCocoSearchMarker(location))
    }

    function launchParkingResearch(){
        //to start the research based on user actual location
        launchResearchAroundMe(userLocation)
        //to hide the actual component
        dispatch(toggleParkingResearchFromIntro(false))
    }

    function simulateClickOnSearchBar(){
        //to update state and activate useEffect in searchBar component
        dispatch(activateClickOnSearchBar(new Date()))
        closeParkingResearchFromIntro()
    }

    return (
        <div className="freeze-background-time-to-park time-to-park-div flex-center-column">
        <IoIcon.IoClose 
            className='menu-bars' 
            style={{position: "absolute", top: "15px", right: "15px"}}
            onClick={()=> closeParkingResearchFromIntro()}
        />
        <div className="time-park-button-div">
            <button className="green-button-time-to-park" onClick={()=>{launchParkingResearch(); addActionToDbBasedOnElement("introUserTripPreparation_aroundMe", trackingIdAndAnonymousLocal, trackingSessionId)}}><p className="no-margin">AUTOUR DE MOI</p></button>
        </div>
        <div className="time-park-button-div">
            <button className="green-button-time-to-park" onClick={()=> {simulateClickOnSearchBar(); addActionToDbBasedOnElement("introUserTripChooseDestBtn", trackingIdAndAnonymousLocal, trackingSessionId)}}><p className="no-margin">AUTRE DESTINATION</p></button>
        </div>
    </div>
    )
}

export default ParkingResearchFromIntroduction
