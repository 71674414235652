import React from 'react'

//STYLE
import './ParkingRealTimeLegendPopup.css'
import * as IoIcon from 'react-icons/io5';

function LvzCaptionPopup(props) {
    return (
        <div className="popup" onClick={()=> props.setTrigger(false)}>
            <div className="popup-inner"> 
                <div>
                    <div className="flex-space-between-inline alertDiv-title">
                        <p className="white-title">Détails sur cette option de stationnement</p>
                        <IoIcon.IoClose onClick={()=> props.setTrigger(false)}
                            style={{color: "white", width: "20px", height: "20px"}}
                        />
                    </div>
                    <div className="alertDiv-content">
                        <div>
                            <p>Poche de stationnement en surface.</p>
                            <p>L’occupation des places est actualisée en temps réel.</p>
                        </div>
                        <div className="flex-center-inline pinfo_container" style={{width: "100%", marginTop: "10px", padding: "30px"}}>
                            <img src={"../images/legend/onstreet-parking-icon.png"} alt="onstreet icon"  style={{width: "50px"}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LvzCaptionPopup
