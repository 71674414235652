import React from 'react'
import { useSelector} from 'react-redux'


//REDUX SELECTORS
import { specificParkingDataSelector } from '../detailsPanel/state/specificParkingDataSelector';
import { specificLvzDataSelector } from '../detailsPanel/state/specificLvzDataSelector';


//STYLE
import './SpecificDetailsPanelCaptionPopup.css'
import * as IoIcon from 'react-icons/io5';
import * as MdIcon from 'react-icons/md';


function TimeItem({time, pictoType}){
    
    let icon

    if(pictoType === "walkingTime"){
        icon = <MdIcon.MdDirectionsWalk
            style={{color: "var(--grey)", width: "25px", height: "25px"}}
        />
    } else if (pictoType === "drivingTime"){
        icon = <MdIcon.MdDriveEta 
        style={{color: "var(--grey)", width: "25px", height: "25px"}}
        />
    }

    return(
        <div style={{display: 'flex', flexDirection: "inline"}}>
            {icon}
            <span className="blue-text">{time}min</span>
        </div>
    )
}

function SpecificDetailsPanelCaptionPopup(props) {

    const specificParkingData = useSelector(specificParkingDataSelector)
    const specificLvzData = useSelector(specificLvzDataSelector)

    
    let infoContainer
    let infoContainerClassname
    let arrayIcons = ["openHours", "booking", "evh", "wc", "carwash", "pmr"];
    let arrayPriceTime = ["1h:", "3h:", "24h:"]
    let arrayTime = ["drivingTime", "walkingTime"]
    let entityData 
    
    if(props.entityType === "lvz"){
        entityData = specificLvzData
    } else {
        entityData = specificParkingData
    }
    
    //to display the right 
    if(props.type === "features"){
        infoContainerClassname = "featuresDiv-popup" 
        infoContainer = entityData.data.parkingInfo.map((feature, index)=>{
            let imgPath
            if(feature === false || feature === null){
                imgPath= "images/parkingFeatures/" + arrayIcons[index] + ".png"
            }else{
                imgPath = "images/parkingFeatures/" + arrayIcons[index] + "-blue.png"
            }
            return <img src={imgPath} style={{width: "30px", margin: "5px"}} alt="Logo" key={index}/>
        })

    } else if(props.type === "price" && entityData.data){
        infoContainer =  Object.keys(entityData.data.price).map((price, index)=>{
            return( 
                <div className="flex-center-inline" key={index}>
                    <p className="grey-text">{arrayPriceTime[index]}</p>
                    <p className="blue-text">&nbsp;{entityData.data.price[price] ? entityData.data.price[price] : "-"}€</p>
                </div>
            )
        })
    } else if(props.type === "time"){
        infoContainerClassname = "featuresDiv-popup" 
        infoContainer = arrayTime.map((time, index)=>{
            if(entityData[time]){
                return <TimeItem time={entityData[time]} pictoType={time} key={index}/>
            }
        })
    }

    return (
        <div className="popup" onClick={()=> props.setTrigger(false)}>
            <div className="popup-inner"> 
                <div>
                    <div className="flex-space-between-inline alertDiv-title">
                        <p className="white-title">{props.entityType === "parking" ? "Détails du parking" : "Détails sur cette option de stationnement"}</p>
                        <IoIcon.IoClose onClick={()=> props.setTrigger(false)}
                            style={{color: "white", width: "20px", height: "20px"}}
                        />
                    </div>
                    <div className="alertDiv-content">
                        <div>
                            <p>Retrouvez ici les informations détaillées sur votre sélection:</p>
                            <li>le temps d'accès</li>
                            <li>les prix</li>
                            <li>les services proposés</li>
                        </div>
                        <div className="flex-center-inline pinfo_container" style={{width: "100%", marginTop: "10px"}}>
                            <div className={infoContainerClassname}>
                                {infoContainer}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SpecificDetailsPanelCaptionPopup
