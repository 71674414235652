const initialeState = {
    maplive: false,
    history: false,
    help: false,
    feedback: false,
    share: false,
}

export const TOGGLE_NAVBAR_SECTION = 'TOGGLE_NAVBAR_SECTION'

export function navBarReducer (state = initialeState, action) {
    switch (action.type){
        case TOGGLE_NAVBAR_SECTION:
            let key = action.payload.key
            let value = action.payload.value
            let newState = { ...state, [key]: value }
            return newState
        default: return state
    }
}