////////////////////////////// ALL FUNCTIONS USED IN SPARKED MODE ////////////////////////////////////

//VARIABLES
let days = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
let months = ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"];

export function stringToHHhMM(str) {
    var sec_num = parseInt(str, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    //var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    //if (seconds < 10) {seconds = "0"+seconds;}
    return hours+'h'+minutes;
}

export function stringToMMmSS(str) {
    var sec_num = parseInt(str, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);


    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}

    var response;
    if(minutes < 1){
        response = seconds +'s';
    }else{
        response = minutes+'m'+seconds+'s';
    }
    return response;
}

/**
 * Used to transform timestamp into time and date to be used in sparked panel
 *
 * @param {time} start - absolute value Date.now()
 */
export function transformTimestampToDateAndTime(start){
    start = new Date(start)
    let minutes = start.getMinutes();
    if (start.getMinutes() < 10) {minutes = "0" + start.getMinutes();}
    let time = start.getHours() +"h" + minutes;
    let date = days[start.getDay()].substr(0,3) + ", " + start.getDate() + " " + months[start.getMonth()] + " " + start.getFullYear();
    return {time: time, date: date}
}

/**
 * Used to track and show time elapsed since user has parked to a parking or in the street
 *
 * @param {time} start - absolute value Date.now()
 */
export function updateDuration(start){
    let duration = (Date.now() - start)/1000;
    let str_duration = duration.toString();
    let str = stringToHHhMM(str_duration)
    return str
}


/**
 * Used to show time elapsed since a spot has been freed
 * disappears after 5 minutes
 *
 * @param {time} start - absolute value Date.now()
 */
export function updateDurationFreedSpot(start){
    //console.log("update freed spot duration");
    let duration = (Date.now() - new Date(start))/1000;
    let str_duration = duration.toString();
    let str = stringToMMmSS(str_duration);
    return str
}
