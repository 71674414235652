import store from '../store/index'

import { toggleLvzSpecificDetailPanel } from '../detailsPanel/state/displayLvzSpecificDetailPanelAction'
import { toggleParkingSpecificDetailPanel } from '../detailsPanel/state/displayParkingSpecificDetailPanelAction'
import { hideDetailPanelContainer } from '../detailsPanel/state/displayDetailsPanelContainerAction'


export function zoomOnClickedLvz(map, position){
    //to center map on zone location when user click on polygon
    map.panTo({lat: position.lat, lng: position.lng});
    map.setZoom(20)
}

export function displayLvzDetailPanel(zoneId){

    store.dispatch(toggleLvzSpecificDetailPanel({value: true, lvzId: zoneId}))
    store.dispatch(hideDetailPanelContainer())
    store.dispatch(toggleParkingSpecificDetailPanel(false))
}