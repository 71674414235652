import React, {useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'

//COMPONENTS
import FilterButton from './FilterButton'
import NavigationSideBar from '../navigation/NavigationSideBar';

//REDUX SELECTORS
import { mapFilterTrackersSelector } from './state/mapFilterTrackersSelector'
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'

//REDUX ACTIONS
import { toggleFilterMode } from './state/filterModeActiveAction'
import { resetFilter } from './state/mapFilterTrackersAction';

//UTILS
import * as global from '../global/globalVariable'
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//STYLE
import './FiltersContainer.css'
import * as IoIcon from 'react-icons/io5';

function FiltersContainer(props) {
    
    //to display button in this specific order
    let arrayIcons = ["pmr", "carwash", "booking", "evh", "delivery"];
    let filterTrackersIdentification = global.filterTrackersIdentification
    
    const dispatch = useDispatch()
    
    const mapFilterTrackers = useSelector(mapFilterTrackersSelector)
    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)

    const config = useSelector((state) => state.config)
    const arrayIconFiltred = arrayIcons.filter(e => config.filters[e] === true)

    const [showFilterFeature, setShowFilterFeature] = useState(false)


    function toggleFilterModeParent(){
        dispatch(toggleFilterMode())
        if(showFilterFeature){
            dispatch(resetFilter())
        }
    }

    return (
    <div className="filter-bar">
        {arrayIconFiltred.length > 0 ?
            <button className="hover-button button-filter" onClick={()=> {setShowFilterFeature(!showFilterFeature); toggleFilterModeParent(); addActionToDbBasedOnElement("mapFilters", trackingIdAndAnonymousLocal, trackingSessionId)}}>
                {showFilterFeature ?
                    <IoIcon.IoClose style={{color: "var(--cocoparksGreen)"}}/>
                :
                    <IoIcon.IoFilter style={{color: "grey"}}/>
                }
            </button>
        : null}
        {showFilterFeature ? arrayIconFiltred.map((feature, index)=>{
            let selected = false
            for(let i= 0; i<mapFilterTrackers.length; i++){              
                if(mapFilterTrackers[i]){
                    if(filterTrackersIdentification[i] === feature){
                        selected = true
                    }
                }
            }
            return (
                <FilterButton 
                    key={index}
                    info={{
                        feature: feature, 
                        selected: selected,
                        map: props.map
                    }}
                />
            )
        }) : <NavigationSideBar/>}
    </div>
    )
}

export default FiltersContainer
