const initialState = 0

export const UPDATE_FRONT_STYLE_ONSTREET_DELIVERY = 'UPDATE_FRONT_STYLE_ONSTREET_DELIVERY'

export function frontStyleOnstreetDeliveryReducer (state = initialState, action) {
    switch (action.type){
        case UPDATE_FRONT_STYLE_ONSTREET_DELIVERY:
            return action.payload
        default: return state
    }
}