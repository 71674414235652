export const UPDATE_SPECIFIC_PARKING_DATA = 'UPDATE_SPECIFIC_PARKING_DATA'
export const RESET_SPECIFIC_PARKING_DATA = 'RESET_SPECIFIC_PARKING_DATA'

export function specificParkingDataReducer (state = null, action) {
    switch (action.type){
        case UPDATE_SPECIFIC_PARKING_DATA:
            return action.payload
        case RESET_SPECIFIC_PARKING_DATA:
            return null
        default: return state
    }
}