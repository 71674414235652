import React from 'react'
import { useSelector } from 'react-redux'
import { Marker, OverlayView } from '@react-google-maps/api'
import { Polyline } from '@react-google-maps/api'

//REDUX SELECTOR
import { frontStyleLvzSelector } from './state/frontStyleLvzSelector'

//UTILS
import * as global from '../global/globalVariable'
import { zoomOnClickedLvz, displayLvzDetailPanel} from '../global/LvzUtils';

function LiveViewZoneMarkerWithArrow({ position, availability, freeSlot, parkingEntranceLocation, parkingExitLocation, map, zoneId}) {
   
    const frontStyleLvz = useSelector(frontStyleLvzSelector)

    let visible = true
   
    //arrow icon for polyline
    const lineSymbol = {
        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    };

    
    let image = {
        url: `images/lvzMarker/lvz-marker-${global.lvzColors[availability].pictureKey}.png`,
        scaledSize: new window.google.maps.Size(30, 40)
    };
    
    let textColor = global.lvzColors[availability].fillColor
    if(availability === "deactivate"){
        textColor = "transparent"
    }
    
    if(frontStyleLvz > 3){
        visible = true
    } else {
        visible = false
    }

    let optionsEntrance =  {
        strokeColor: "#1faa00",
        strokeOpacity: 1.0,
        strokeWeight: 3,
        icons: [{
            icon: lineSymbol,
            offset: '100%', 
        }],
        visible: visible
    };

    let optionsExit =  {
        strokeColor: "#E32C2C",
        strokeOpacity: 1.0,
        strokeWeight: 3,
        icons: [{
            icon: lineSymbol,
            offset: '100%', 
        }],
        visible: visible
    };

    let textStyle ={
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "16px",
        textAlign: "center",
        color: textColor
    }

    function clickOnLvzMarker(){
        zoomOnClickedLvz(map, position)
        displayLvzDetailPanel(zoneId)
    }

    return (
        <div>
            <Marker
                position= {position}
                icon={image}
                visible={visible}
                zIndex={100}
                onClick={clickOnLvzMarker}
            />
            <OverlayView
                position={position}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={(width, height) => ({
                    x: -(width / 2),
                    y: -32
                })}
            >
                <div style={{height: "40px", width: "30px", visibility: visible ? "visible" : "hidden"}}>
                    <p style={textStyle}>{freeSlot}</p>
                </div>
            </OverlayView>
            {parkingEntranceLocation ? parkingEntranceLocation.map((item, index)=>{
                return(
                    <Polyline
                        path={
                            [{lat: item.bottom.lat, lng: item.bottom.lng},
                            {lat: item.head.lat, lng: item.head.lng}]
                        }
                        options={optionsEntrance}
                        key={index}
                    />  
                )
            }):null}
            {parkingExitLocation ? parkingExitLocation.map((item, index)=>{
                return(
                    <Polyline
                        path={
                            [{lat: item.bottom.lat, lng: item.bottom.lng},
                            {lat: item.head.lat, lng: item.head.lng}]
                        }
                        options={optionsExit}
                        key={index}
                    />  
                )
            }):null}
        </div>
    )
}

export default LiveViewZoneMarkerWithArrow
