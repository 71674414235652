const initialState = 1

export const UPDATE_FRONT_STYLE_SPARKED_COCO = 'UPDATE_FRONT_STYLE_SPARKED_COCO'

export function frontStyleSparkedCocoReducer (state = initialState, action) {
    switch (action.type){
        case UPDATE_FRONT_STYLE_SPARKED_COCO:
            return action.payload
        default: return state
    }
}