import React from 'react'

//STYLE
import * as IoIcon from 'react-icons/io5';
import './ParkingRealTimeLegendPopup.css'


function BadgeUserMenuPopup(props) {

    return (
        <div className="popup">
            <div className="popup-inner"> 
                <div>
                    <div className="flex-space-between-inline alertDiv-title-realtime">
                        <p className="white-title">Badge de pionnier</p>
                        <IoIcon.IoClose 
                            className='menu-bars' 
                            style={{color: "white"}}
                            onClick={()=> props.setTrigger(false)}
                        />
                    </div>
                    <div className="alertDiv-content">
                        <p style={{color: "var(--dark-grey)", fontWeight: "bold"}}>Vous comptez parmi les fondateurs de la communauté Cocoparks. A ce titre, ce badge de PIONNIER vous est attribué.</p>
                        <p style={{color: "var(--dark-grey)", fontWeight: "bold"}}>Merci de faire partie de notre communauté et d'aider des milliers de Coco à gagner du temps et réduire la pollution dans leur ville chaque jour !</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BadgeUserMenuPopup
