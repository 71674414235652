import React, { useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';

//REDUX SELECTORS
import { userLocationSelector } from '../markers/state/userLocationSelector';
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'
import { displaySparkedOnStreetSuccessSelector } from '../itinerary/state/displaySparkedOnStreetSuccessSelector'
import { lvzInfoSelector } from '../detailsPanel/state/lvzInfoSelector'

//REDUX ACTIONS
import { toggleTimeToParkContainer } from './state/displayTimeToParkContainerAction'
import { toggleSparkedMode } from '../global/state/sparkedModeAction';
import { showSparkedMarker } from '../markers/state/sparkedMarkerAction'
import { addTtpInfo } from './state/ttpInfoAction'
import { toggleExpandedDetailPanel } from '../detailsPanel/state/detailsPanelExpandedAction'
import { toggleSparkedOnStreetSuccess } from '../itinerary/state/displaySparkedOnStreetSuccessAction'

//UTILS
import { sendTTP } from '../global/SocketIoUtils'
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'
import { addDataToUserSessionInDB } from '../api/userTrackingApi.js'

//STYLE
import * as IoIcon from 'react-icons/io5';
import * as MdIcon from 'react-icons/md';

function TimeToParkContainer() {

    const dispatch = useDispatch()

    const userLocation = useSelector(userLocationSelector)
    const displaySparkedOnStreetSuccess = useSelector( displaySparkedOnStreetSuccessSelector)
    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)
    const lvzInfo = useSelector(lvzInfoSelector)
    const config = useSelector((state) => state.config)
    const IsAMPCustomer = config.customer == 'amp'

    const [thankPopup, setThankPopup] = useState(false)

    function closeTimeToParkContainer(){
        dispatch(toggleTimeToParkContainer(false))
    }

    function displayThankPopUp(){
        //to display thank pop up during 3s before closing everything
        setThankPopup(true)
        setTimeout(function(){
            closeTimeToParkContainer()
            setThankPopup(false)
        }, 3000)
    }
    
    function sendTimeToPark(value){
        let sparkedTime = Date.now()
        let TTPinfo = {
            "position": userLocation,
            "ttp": value,
            "start": sparkedTime,
        };

        //Avoid displaying a sparked marker if the user is near an LVZ
        if(!lvzInfo.lvzExists){
            //SOCKET IO
            sendTTP(TTPinfo);
        }
        startSparkedMode()
        dispatch(addTtpInfo(TTPinfo))
        if(displaySparkedOnStreetSuccess.activate){
            dispatch(toggleSparkedOnStreetSuccess({visible: true, activate: true}))
            closeTimeToParkContainer()
        } else {
            displayThankPopUp()
        }
        //to add informations about onstreet sparked position in DB
        let dataTracking = {
            lat : userLocation.lat,
            lng : userLocation.lng,
            ptype : "onstreet",
        }
        addDataToUserSessionInDB("actions", trackingIdAndAnonymousLocal[1], trackingIdAndAnonymousLocal[0], trackingSessionId, dataTracking)
    }

    //to switch the app to sparked mode (+ display sparker marker)
    function startSparkedMode(){
        dispatch(toggleSparkedMode(true, "onstreet"))
        dispatch(showSparkedMarker(userLocation))
        dispatch(toggleExpandedDetailPanel(false))
    }

    const checkText = IsAMPCustomer
        ? "Votre place de stationnement est enregistrée"
        : "Cette information est envoyée à d'autres Cocos qui se garent dans ce quartier"
    
    if(thankPopup){
        return (
            <div className="freeze-background-time-to-park time-to-park-div flex-center-column" >
                <p className="time-to-park-text">{ checkText }</p>
                <div className="time-park-button-div">
                    <MdIcon.MdCheck
                        style={{color: "var(--cocoparksGreen)", width: "50px", height: "50px"}}
                    />
                </div>
            </div>
        )
    } else {
        return (
            <div className="freeze-background-time-to-park time-to-park-div flex-center-column" >
                <IoIcon.IoClose 
                    className='menu-bars' 
                    style={{position: "absolute", top: "15px", right: "15px"}}
                    onClick={()=> closeTimeToParkContainer()}
                />
                <p className="time-to-park-text">Super, vous avez trouvé une place.</p>
                {IsAMPCustomer ? null : <p className="time-to-park-text">Venez en aide aux Coco :-)<br/></p>}
                <p className="time-to-park-text">En combien de temps avez-vous trouvé votre place ?</p>
                <div className="time-park-button-div">
                    <button className="green-button-time-to-park" onClick={() => {sendTimeToPark("5"); addActionToDbBasedOnElement("ttp5", trackingIdAndAnonymousLocal, trackingSessionId)}}><p className="no-margin">- de 5<span className="ttp-text-min"> min</span></p></button>
                </div>
                <div className="time-park-button-div">
                    <button className="green-button-time-to-park" onClick={() => {sendTimeToPark("10"); addActionToDbBasedOnElement("ttp10", trackingIdAndAnonymousLocal, trackingSessionId)}}><p className="no-margin">5 - 15<span className="ttp-text-min"> min</span></p></button>
                </div>
                <div className="time-park-button-div">
                    <button className="green-button-time-to-park" onClick={() => {sendTimeToPark("15"); addActionToDbBasedOnElement("ttp15", trackingIdAndAnonymousLocal, trackingSessionId)}}><p className="no-margin">+ de 15<span className="ttp-text-min"> min</span></p></button>
                </div>
            </div>
        )
    }
}

export default TimeToParkContainer
