const initialState = {visible: false, position: {lat: 0, lng: 0}}

export const SHOW_SPARKED_MARKER = 'SHOW_SPARKED_MARKER'
export const HIDE_SPARKED_MARKER = 'HIDE_SPARKED_MARKER'

export function sparkedMarkerReducer (state = initialState, action) {
    switch (action.type){
        case SHOW_SPARKED_MARKER:
            let displayMarkerInfo = {
                visible: true,
                position: {
                    lat: action.payload.lat,
                    lng: action.payload.lng
                }
            }
            return displayMarkerInfo
        case HIDE_SPARKED_MARKER:
            let hideMarkerInfo = {
                visible: false,
                position: {
                    lat: 0,
                    lng: 0
                }
            }
            return hideMarkerInfo
        default: return state
    }
}