const initialState = {
    streetParkingTime: "-",
    streetParkingCost: "-",
}

export const STORE_STREET_PARKING_INFO = 'STORE_STREET_PARKING_INFO'

export function streetParkingInfoReducer (state = initialState, action) {
    switch (action.type){
        case STORE_STREET_PARKING_INFO:
            return action.payload
        default: return state
    }
}