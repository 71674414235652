import React from 'react'
import { useDispatch } from 'react-redux'

//REDUX ACTIONS
import { toggleInstallationLegendIphone } from './state/displayInstallationLegendIphoneAction'

//STYLE
import './InstallationLegendIphone.css'
import * as IoIcon from 'react-icons/io5';
import logo from '../images/logo.png'

function InstallationLegendIphone() {

    const dispatch = useDispatch()

    function closeInstallLegend(){
        dispatch(toggleInstallationLegendIphone(false))
    }

    return (
        <div className="installation-legend-iphone-background">
            <div className="installation-legend-iphone">
                <div style={{width: "100%"}} className="flex-flex-end-column">                
                    <IoIcon.IoClose 
                        className='menu-bars' 
                        onClick={()=> {closeInstallLegend()}}
                    />
                </div>
                <div className="flex-center-inline">                    
                    <img src={logo} alt="Logo" style={{width: "10vh", marginBottom: "20px"}}/> 
                </div>
                <div className="flex-center-inline">
                    <p className="time-to-park-text" style={{textAlign: "center"}}>Installer Cocoparks</p>
                </div>
                <div className="flex-center-inline" style={{marginBottom: "5px"}}>
                    <p className="no-margin">Cliquez sur le bouton</p>
                    <IoIcon.IoShareOutline 
                        style={{color: "var(--cocoparksGreen", fontSize: "30px"}}
                    />
                </div>
                <div className="flex-center-inline">
                    <p>Puis <span style={{color: "var(--cocoparksGreen)", fontWeight: "bold"}}>"sur l'écran d'accueil"</span></p>
                </div>
            </div>
        </div>
    )
}

export default InstallationLegendIphone
