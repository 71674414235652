import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

//REDUX SELECTORS
import { lastTorchGeolocationSelector } from './state/lastTorchGeolocationSelector'
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'

//REDUX ACTION
import { toggleExpandedDetailPanel } from '../detailsPanel/state/detailsPanelExpandedAction'
import { toggleSparkedMode } from '../global/state/sparkedModeAction';
import { showSparkedMarker } from '../markers/state/sparkedMarkerAction'
import { resetSparkedOnStreetSuccess} from '../itinerary/state/displaySparkedOnStreetSuccessAction'

//UTILS
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//STYLE
import * as MdIcon from 'react-icons/md';
import * as IoIcon from 'react-icons/io5';

function SparkedOnStreetSuccessScreen() {

    const dispatch = useDispatch()

    const lastTorchGeolocation = useSelector(lastTorchGeolocationSelector)
    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)

    const [thankComponent, setThankComponent] = useState(true)
    const [thankMessage, setThankMessage] = useState(false)
    
    setTimeout(function(){
        setThankComponent(false)
    }, 3000)
    
    function sparkedOnStreetUserContributed(){
        setThankMessage(true)
        startSparkedMode()
        setTimeout(function(){
            dispatch(resetSparkedOnStreetSuccess())
        }, 3000)
    }

    //to switch the app to sparked mode (+ display sparker marker)
    function startSparkedMode(){
        dispatch(toggleSparkedMode(true, "onstreet"))
        dispatch(showSparkedMarker(lastTorchGeolocation))
        dispatch(toggleExpandedDetailPanel(false))
    }

    if(thankComponent){
        return (
            <div className="freeze-background-time-to-park time-to-park-div flex-center-column">
                <p className="time-to-park-text">Super, vous avez trouvé une place !</p><br/>
                <p className="time-to-park-text">Cette information est envoyée à d'autres Cocos qui se garent dans ce quartier</p>
                <div className="time-park-button-div">
                    <MdIcon.MdCheck
                        style={{color: "var(--cocoparksGreen)", width: "50px", height: "50px"}}
                    />
                </div>
            </div>
        )
    } else {
        return (
            <div className="freeze-background-time-to-park time-to-park-div flex-center-column">
                {!thankMessage ?
                <>
                    <IoIcon.IoClose 
                        style={{position: "absolute", top: "15px", right: "15px", color: "white"}}
                        onClick={()=> {dispatch(resetSparkedOnStreetSuccess()); addActionToDbBasedOnElement("sparkedOnStreetContributeForCocosScreen_close", trackingIdAndAnonymousLocal, trackingSessionId)}}
                    />
                    <p className="time-to-park-text">Aides les Cocos, à votre tour</p><br/>
                    <p className="time-to-park-text">Autour de moi...</p>
                    <div className="time-park-button-div">
                        <button className="green-button-time-to-park"><p className="no-margin" onClick={()=> {sparkedOnStreetUserContributed(); addActionToDbBasedOnElement("sparkedOnStreetContributeForCocosScreen_spotsAvailable", trackingIdAndAnonymousLocal, trackingSessionId)}}>IL Y A D'AUTRES PLACES</p></button>
                    </div>
                    <div className="time-park-button-div">
                        <button className="green-button-time-to-park" style={{backgroundColor: "var(--red)"}}><p className="no-margin" onClick={()=> {sparkedOnStreetUserContributed(); addActionToDbBasedOnElement("sparkedOnStreetContributeForCocosScreen_spotsUnavailable", trackingIdAndAnonymousLocal, trackingSessionId)}}>PAS D'AUTRE PLACE</p></button>
                    </div>
                </>:
                <p className="time-to-park-text">MERCI !</p>
                }
            </div>
        )
    }
    
}

export default SparkedOnStreetSuccessScreen
