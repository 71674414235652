import { Fragment} from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconContext } from 'react-icons';
import Drawer from '@material-ui/core/Drawer';

//REDUX SELECTOR
import { navBarSelector } from '../navBar/state/navBarSelector'
import { showNavMenuSelector } from './state/showNavMenuSelector'
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'

//REDUX ACTION
import { toggleSectionDrawer } from '../navBar/state/navBarAction'
import { hideNavMenu } from './state/showNavMenuAction'

//UTILS
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'
//to contains all mavbar data (name, icon, component)
import  { SidebarData }  from './NavMenuItem';
import * as global from '../global/globalVariable'

//STYLE
import * as IoIcon from 'react-icons/io5';
import * as AiIcon from 'react-icons/ai';

import './NavMenu.css';


const NavBar = () => {

    const dispatch = useDispatch()

    const navBarState = useSelector(navBarSelector)
    const showNavMenu = useSelector(showNavMenuSelector)
    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)

    let cocoparksVersionNumber = global.cocoparksVersionNumber 

    function openUrlInNewTab(url){
        window.open(url, "_blank")
    }

    return(
        <>
            <IconContext.Provider value={{ color: '#fff' }}>
                <nav className={showNavMenu ? 'nav-menu active' : 'nav-menu'}>
                    <ul className='nav-menu-items' onClick={()=>{dispatch(hideNavMenu())}}>
                        <li className='navbar-toggle'>
                            <IoIcon.IoClose 
                                onClick={()=> addActionToDbBasedOnElement("closeNav", trackingIdAndAnonymousLocal, trackingSessionId)}
                                className='menu-bars flex-center-inline icon' 
                                style={{margin: "5px"}}/>
                        </li>
                        {/* to display all sections of the navbar */}
                        {SidebarData.map((item) => {
                            let anchor = "right"
                            if(item.key === "share"){
                                anchor= "bottom"
                            }
                            if(item.key === "maplive"){
                                return (
                                    <div key={item.key}>
                                        <li className="nav-section" onClick={()=>dispatch(hideNavMenu())}>
                                            {item.icon}
                                            <span className="nav-text">{item.title}</span>
                                        </li>
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={item.key}>
        
                                        <li className="nav-section" onClick={()=> {dispatch(toggleSectionDrawer(item.key, true)); addActionToDbBasedOnElement( item.key + "Open", trackingIdAndAnonymousLocal, trackingSessionId)}}>
                                            {item.icon}
                                            <span className="nav-text">{item.title}</span>
                                        </li>
                                        {/* to create the right side panel to display component of the menu */}
                                        <Fragment key={item.key}>
                                            <Drawer 
                                                anchor={anchor} 
                                                open={navBarState[item.key]} 
                                                onClose={() => dispatch(toggleSectionDrawer(item.key, false))}
                                            >
                                            <button style={{borderStyle : "none", background: "none", padding: "0px", position: "absolute", top: "10px", left: "10px", height: "40px", width: "40px"}}>
                                                <IoIcon.IoChevronBack 
                                                    style={{color: "black", height: "30px", width: "30px"}} 
                                                    onClick={()=> {dispatch(toggleSectionDrawer(item.key, false)); addActionToDbBasedOnElement( item.key + "Close", trackingIdAndAnonymousLocal, trackingSessionId)}}
                                                />
                                            </button>
                                                {item.component}
                                            </Drawer>
                                        </Fragment>
                                    </div>
                                );
                            }
                        })}
                        <div className="flex-flex-start-inline" style={{position: "absolute", bottom: "0px", paddingBottom: "40px"}}>
                            <IoIcon.IoLogoLinkedin 
                                onClick={()=> {openUrlInNewTab("https://www.linkedin.com/company/cocoparks/"); addActionToDbBasedOnElement("openLinkedin", trackingIdAndAnonymousLocal, trackingSessionId)}}
                                style={{fontSize: "30px", marginRight: "20px"}}
                            />
                            <IoIcon.IoLogoFacebook 
                                onClick={()=> {openUrlInNewTab("https://www.facebook.com/Cocoparks-112246583617951/"); addActionToDbBasedOnElement("openFacebook", trackingIdAndAnonymousLocal, trackingSessionId)}}
                                style={{fontSize: "30px", marginRight: "20px"}}
                            />
                            <IoIcon.IoLogoTwitter 
                                onClick={()=> {openUrlInNewTab("https://twitter.com/cocoparks3"); addActionToDbBasedOnElement("openTwitter", trackingIdAndAnonymousLocal, trackingSessionId)}}
                                style={{fontSize: "30px", marginRight: "20px"}}
                            />
                            <AiIcon.AiFillYoutube 
                                onClick={()=> {openUrlInNewTab("https://www.youtube.com/channel/UCo62d7aW0gXCBeuppaXO-_A"); addActionToDbBasedOnElement("openYoutube", trackingIdAndAnonymousLocal, trackingSessionId)}}
                                style={{fontSize: "30px", marginRight: "20px"}}
                            />
                        </div>
                        <div style={{position: "absolute", bottom: "0px", paddingBottom: "10px", fontSize: "9px", color: "white", fontWeight: "bold"}}>
                            v.{cocoparksVersionNumber}
                        </div>
                    </ul>
                </nav>
            </IconContext.Provider>
            {showNavMenu ? <div className="freeze-background active freeze-background-menu" onClick={()=>{dispatch(hideNavMenu()); addActionToDbBasedOnElement("freezePanelNav", trackingIdAndAnonymousLocal, trackingSessionId)}}></div> : <div className="freeze-background"></div>} 
        </>
    )
}

export default NavBar;