import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

//REDUX ACTIONS
import { toggleTorchErrorPopup } from './state/displayTorchErrorPopupAction';
import { toggleItineraryMode } from '../global/state/itineraryModeAction';
import { showDetailPanelContainer } from '../detailsPanel/state/displayDetailsPanelContainerAction';

//STYLE
import './ParkingRealTimeLegendPopup.css'


function TorchErrorPopup() {

    const dispatch = useDispatch()

    function closeTorchErrorPopup(){
        dispatch(toggleTorchErrorPopup(false))
    }

    useEffect(() => {
        setTimeout(function(){
            dispatch(toggleItineraryMode(false))
            dispatch(showDetailPanelContainer())
        }, 2000)
    }, [])

    return (
        <div className="popup">
            <div className="popup-inner"> 
                <div>
                    <div className="flex-space-between-inline alertDiv-title-realtime">
                        <p className="white-title">Détail itinéraire non disponible</p>
                    </div>
                    <div className="alertDiv-content">
                        <p style={{color: "var(--dark-grey)"}}>La torche ne parvient à fournir un itinéraire optimisé...</p>
                        <p style={{color: "var(--dark-grey)"}}>Tentez de vous rendre dans la zone verte indiquée</p>
                        <div style={{width: "100%"}} className="flex-center-inline">
                            <button className="green-action-button" onClick={()=> closeTorchErrorPopup()}>
                                <span className="green-action-button-text">Fermer</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TorchErrorPopup
