import React from "react";
import { Marker } from "@react-google-maps/api";
import { useSelector, useDispatch } from "react-redux";

//REDUX SELECTORS
import { parkingMarkerDataSelector } from "./state/parkingMarkerDataSelector";
import { researchModeSelector } from "../global/state/researchModeSelector";
import { aroundMeModeSelector } from "../global/state/aroundMeModeSelector";
import { cocoSearchMarkerSelector } from "./state/cocoSearchMarkerSelector";
import { userLocationSelector } from "./state/userLocationSelector";
import { displayParkingSpecificDetailPanelSelector } from "../detailsPanel/state/displayParkingSpecificDetailPanelSelector";
import { displayLvzSpecificDetailPanelSelector } from "../detailsPanel/state/displayLvzSpecificDetailPanelSelector";
import { frontStyleParkingSelector } from "./state/frontStyleParkingSelector";
import { selectedParkingMarkerSelector } from "./state/selectedParkingMarkerSelector";
import { filterModeActiveSelector } from "../filters/state/filterModeActiveSelector";
import { mapFilterTrackersSelector } from "../filters/state/mapFilterTrackersSelector";
import { sparkedModeSelector } from "../global/state/sparkedModeSelector";
import { itineraryModeSelector } from "../global/state/itineraryModeSelector";
import { trackingIdAndAnonymousLocalSelector } from "../userTracking/state/trackingIdAndAnonymousLocalSelector";
import { trackingSessionIdSelector } from "../userTracking/state/trackingSessionIdSelector";

//REDUX ACTIONS
import { toggleParkingSpecificDetailPanel } from "../detailsPanel/state/displayParkingSpecificDetailPanelAction";
import { toggleLvzSpecificDetailPanel } from "../detailsPanel/state/displayLvzSpecificDetailPanelAction";
import { hideDetailPanelContainer } from "../detailsPanel/state/displayDetailsPanelContainerAction";
import { updateSpecificParkingData } from "../detailsPanel/state/specificParkingDataAction";
import { updatePathToDestination } from "./state/directionToDestinationAction";
import { selectParkingMarker } from "./state/selectedParkingMarkerAction";
import { displayParkingInProgressPopup } from "../popup/state/showParkingInProgressPopUpAction";

//UTILS
import * as global from "../global/globalVariable";
import {
  createObjectWithDataFromIndex,
  getDriveTimeAndPath,
  getWalkingTime,
} from "../global/MapUtils";
import { findColorBasedOnRatio } from "../global/GlobalUtils";
import { addDataToUserSessionInDB } from "../api/userTrackingApi.js";

//STYLE
import "./ParkingMarker.css";

//JSON
import jsonZoomUIStatuses from "../zoom/jsonZoomUIStatuses.json";

export const ParkingMarker = React.memo((props) => {
  let companyMarkers = global.companyMarkers;
  let liveParkingsColor = global.liveParkingsColor;
  let liveParkingsThreshold = global.liveParkingsThreshold;
  let visible = true;
  let featureId = ["", "booking", "evh", "", "carwash", "pmr"];
  let filterTrackersIdentification = global.filterTrackersIdentification;

  const dispatch = useDispatch();
  const parkingMarkerData = useSelector(parkingMarkerDataSelector);
  const researchMode = useSelector(researchModeSelector);
  const aroundMeMode = useSelector(aroundMeModeSelector);
  const cocoSearchMarker = useSelector(cocoSearchMarkerSelector);
  const userLocation = useSelector(userLocationSelector);
  const displayParkingSpecificDetailPanel = useSelector(
    displayParkingSpecificDetailPanelSelector,
  );
  const displayLvzSpecificDetailPanel = useSelector(
    displayLvzSpecificDetailPanelSelector,
  );
  const frontStyleParking = useSelector(frontStyleParkingSelector);
  const selectedParkingMarker = useSelector(selectedParkingMarkerSelector);
  const filterModeActive = useSelector(filterModeActiveSelector);
  const mapFilterTrackers = useSelector(mapFilterTrackersSelector);
  const sparkedMode = useSelector(sparkedModeSelector);
  const itineraryMode = useSelector(itineraryModeSelector);
  const trackingIdAndAnonymousLocal = useSelector(
    trackingIdAndAnonymousLocalSelector,
  );
  const trackingSessionId = useSelector(trackingSessionIdSelector);

  let markerWidth = eval(
    jsonZoomUIStatuses["parking"].icons[frontStyleParking].size.width,
  );
  let markerHeight = eval(
    jsonZoomUIStatuses["parking"].icons[frontStyleParking].size.height,
  );
  let imgPath = jsonZoomUIStatuses["parking"].icons[frontStyleParking].imgSrc;

  //to display the correct marker style based on zoom status
  if (frontStyleParking === 2) {
    if (props.info.operator != null) {
      //searching if the operator name is include in an array of companies names to display the correct icon on marker
      if (companyMarkers.indexOf(props.info.operator) >= 0) {
        //to add operator name at the end of imagePath
        imgPath = imgPath + "-" + props.info.operator;
      }
    }

    //if the parking has a filling ratio, we looking for the color to display
    if (props.info.isOpenNow) {
      if (props.info.fillingRatio !== null) {
        let color = findColorBasedOnRatio(
          props.info.fillingRatio,
          liveParkingsThreshold,
          liveParkingsColor,
          props.info.remainingSpots,
        );
        imgPath = imgPath + "-" + color;
      }
    } else {
      imgPath = imgPath + "-grey";
    }

    imgPath = imgPath + ".png";
  }

  if (props.info.key === selectedParkingMarker.key && frontStyleParking === 2) {
    markerHeight = 50;
    markerWidth = 37.5;
  }

  var image = {
    url: imgPath,
    scaledSize: new window.google.maps.Size(markerWidth, markerHeight),
  };

  if (filterModeActive) {
    for (let i = 0; i < mapFilterTrackers.length; i++) {
      if (mapFilterTrackers[i]) {
        let featureName = filterTrackersIdentification[i];
        //index of feature in parking info from WrapperParkingMarker
        //used to check if the marker has the feature in its parkingInfo props
        let index = featureId.indexOf(featureName);
        if (
          props.info.parkingInfo[index] === null ||
          (props.info.parkingInfo[index] === false) |
            (props.info.parkingInfo[index] === undefined)
        ) {
          visible = false;
          break;
        }
      }
    }
  }

  if (itineraryMode) {
    visible = false;
  }

  async function clickOnMarker(index) {
    if (sparkedMode.value) {
      //to display pop up saying that user is parked
      //can't get data about parking if sparkedMode is active
      dispatch(displayParkingInProgressPopup(true));
    } else {
      dispatch(selectParkingMarker(true, index));
      //to create an object with the correct properties to be displayed in parkingSpecificDetailPanel
      let resp = createObjectWithDataFromIndex(parkingMarkerData, index);
      let destination = {
        lat: resp.data.location.lat,
        lng: resp.data.location.lng,
      };
      props.map.panTo(destination);
      if (props.map.zoom < 15) {
        props.map.setZoom(15);
      }
      // dispatch(updateMapCenter(destination))
      let location;
      let pathStyle;
      let walkingTime;
      //to get the direction either from userlocation or from cocoSearchMarker depending on researchMode being active or or
      if (researchMode.value && !aroundMeMode) {
        location = {
          lat: cocoSearchMarker.position.lat,
          lng: cocoSearchMarker.position.lng,
        };
        pathStyle = "dot";
      } else {
        location = { lat: userLocation.lat, lng: userLocation.lng };
        pathStyle = "line";
      }
      if (!displayParkingSpecificDetailPanel) {
        dispatch(toggleParkingSpecificDetailPanel(true));
      }
      if (displayLvzSpecificDetailPanel.value) {
        dispatch(toggleLvzSpecificDetailPanel({ value: false, name: "" }));
      }
      dispatch(hideDetailPanelContainer());

      let drivingTimeAndPath = await getDriveTimeAndPath(location, destination);
      dispatch(
        updatePathToDestination({
          path: drivingTimeAndPath.drivePath,
          style: pathStyle,
        }),
      );
      setTimeout(async function () {
        walkingTime = await getWalkingTime(location, destination);
        dispatch(
          updateSpecificParkingData({
            ...resp,
            walkingTime: walkingTime,
            drivingTime: drivingTimeAndPath.driveTime,
          }),
        );
      }, 300);
      //to add informations about clicked marker in DB
      let dataTracking = {
        parkingName: resp.data.name,
        lat: resp.data.location.lat,
        lng: resp.data.location.lng,
      };
      addDataToUserSessionInDB(
        "actions",
        trackingIdAndAnonymousLocal[1],
        trackingIdAndAnonymousLocal[0],
        trackingSessionId,
        dataTracking,
      );
    }
  }

  return (
    <Marker
      position={{ lat: props.location.lat, lng: props.location.lng }}
      clusterer={props.cluster}
      icon={image}
      visible={visible}
      onClick={() => {
        clickOnMarker(props.info.key);
      }}
      zIndex={1}
    />
  );
});

export default ParkingMarker;
