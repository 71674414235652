export const ADD_CLOSEST_LVZ_PATHS = 'ADD_CLOSEST_LVZ_PATHS'
export const RESET_CLOSEST_LVZ_PATHS = 'RESET_CLOSEST_LVZ_PATHS'

export function closestLvzPathsReducer (state = [], action) {
    switch (action.type){
        case ADD_CLOSEST_LVZ_PATHS:
            return [...state, action.payload]
        case RESET_CLOSEST_LVZ_PATHS: 
            return []
        default: return state
    }
}