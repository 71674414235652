const initialState = {
    streetParkingTime: null,
    streetParkingCostAllTime: null,
}

export const STORE_STREET_PARKING_ALL_TIME_INFO = 'STORE_STREET_PARKING_ALL_TIME_INFO'
export const RESET_STREET_PARKING_ALL_TIME_INFO = 'RESET_STREET_PARKING_ALL_TIME_INFO'

export function streetParkingCostAllTimeReducer (state = initialState, action) {
    switch (action.type){
        case STORE_STREET_PARKING_ALL_TIME_INFO:
            return action.payload
        case RESET_STREET_PARKING_ALL_TIME_INFO:
            return initialState
        default: return state
    }
}