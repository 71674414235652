import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete} from "@react-google-maps/api";

//REDUX SELECTOR
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'

//REDUX ACTION
import { toggleTorchNotAvailableScreen } from './state/displayTorchNotAvailableScreenAction'
import { resetGetBackToRecentTorchAddress } from './state/displayGetBackToRecentTorchAddressAction';
import { updateTorchJson } from '../itinerary/state/torchJsonAction';
import { toggleItineraryMode } from '../global/state/itineraryModeAction';
import { hideDetailPanelContainer } from '../detailsPanel/state/displayDetailsPanelContainerAction';

//UTILS
import { getRevealSlots } from '../api/itineraryApi'
import cocoparksAvailableCities from '../global/cocoparksAvailableCities.json'
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//STYLE
import '../global/Global.css'
import './DestinationNotReachYet.css'
import './TorchNotAvailableScreen.css'
import * as IoIcon from 'react-icons/io5';

function TorchNotAvailableScreen(props) {

    const [newResearchInput, setNewResearchInput] = useState(false)
    const [destination, setDestination] = useState("")
    const [showErrorMessage, setErrorMessage] = useState(false)

    const autocompleteRef = useRef(null) //to store the map

    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)

    const dispatch = useDispatch()

    const options = {
        componentRestrictions: {country: ['fr', 're']}
    };

    function closeTorchNotAvailableScreen(){
        dispatch(toggleTorchNotAvailableScreen(false))
    }

    function displayNewResearchInput(){
        setNewResearchInput(true)
    }

    //MAP RELATED FUNCTION (google-map-react-api)
    function onLoad (autocomplete) {
        autocompleteRef.current = autocomplete;
    }

    //MAP RELATED FUNCTION (google-map-react-api)
    async function onPlaceChanged () {
        if (autocompleteRef.current !== null) {
            let place = autocompleteRef.current.getPlace()
            if(place.geometry){
                let newresearchPosition = place.geometry.location.toJSON()
                props.map.panTo({lat: newresearchPosition.lat, lng: newresearchPosition.lng})
                //to update map center based on last research position
                await getRevealSlots({lat: newresearchPosition.lat, lng: newresearchPosition.lng})
                .then(function(resp){
                    if(resp.results !== -1 || resp.results.total_number_of_spots > 0){
                        //to update torchJson based on last research position
                        dispatch(updateTorchJson(resp.results))
                        //to hide <GetBackToRecentTorchAddress/>
                        dispatch(resetGetBackToRecentTorchAddress())
                        //to hide details panel because it's suppose to be displayed by default when opening the app
                        dispatch(hideDetailPanelContainer())
                        //to update itinerary mode 
                        dispatch(toggleItineraryMode(true))
                        //to hide current component
                        dispatch(toggleTorchNotAvailableScreen(false))
                    } else {
                        setDestination("")
                        setErrorMessage(true)
                    }
                })
          }
        } else {
          console.log('Autocomplete is not loaded yet!')
        }
    }

    return (
        <div className="freeze-background-time-to-park time-to-park-div flex-center-column" >
            <IoIcon.IoClose 
                className='menu-bars' 
                style={{position: "absolute", top: "15px", right: "15px"}}
                onClick={()=> {closeTorchNotAvailableScreen(); addActionToDbBasedOnElement("torchNotAvailableScreen_close", trackingIdAndAnonymousLocal, trackingSessionId)}}
            />
            {newResearchInput ? 
            <>
                <Autocomplete
                onLoad={onLoad}
                onPlaceChanged={onPlaceChanged}
                options={options}
                >
                    <input 
                        onChange={(e)=> setDestination(e.target.value)} 
                        value={destination} 
                        placeholder="Quelle est votre destination ?"
                        className="new-research-input-destination"
                    />
                </Autocomplete>
                {showErrorMessage ?
                    <p className="error-message">Cette ville n'est pas encore couverte par Cocoparks</p>
                :null}
            </>
            :<>
                <p className="time-to-park-text">Hey Coco, la recherche de places dans la rue n'est pas disponible autour de vous, pour le moment.</p><br/>
                <p className="time-to-park-text">Disponible à &nbsp;
                    <span style={{color:"gold"}}>
                        {cocoparksAvailableCities.map((city, index)=>{
                            if(index !== cocoparksAvailableCities.length -1){
                                return city + ", "
                            } else {
                                return city + "."
                            }
                        })}
                    </span>
                </p><br/>
                <div className="destination-not-yet-reached-revenir">
                    <button className="green-button-time-to-park"><p className="no-margin" onClick={()=> {displayNewResearchInput(); addActionToDbBasedOnElement("torchNotAvailableScreen_LookingForSpotInCity", trackingIdAndAnonymousLocal, trackingSessionId)}}>JE CHERCHE UNE PLACE DANS UNE DE CES VILLES</p></button>
                </div>
                <div className="destination-not-yet-reached-revenir">
                    <button className="green-button-time-to-park"><p className="no-margin" onClick={()=> {closeTorchNotAvailableScreen(); addActionToDbBasedOnElement("torchNotAvailableScreen_backToMap", trackingIdAndAnonymousLocal, trackingSessionId)}}>VOIR LA CARTE</p></button>
                </div>
            </>
            }
        </div>
    )

}

export default TorchNotAvailableScreen
