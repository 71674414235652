export const HIDE_NAV_MENU = 'HIDE_NAV_MENU'
export const SHOW_NAV_MENU = 'SHOW_NAV_MENU'

export function showNavMenuReducer (state = false, action) {
    switch (action.type){
        case HIDE_NAV_MENU:
            return false
        case SHOW_NAV_MENU:
            return true
        default: return state
    }
}