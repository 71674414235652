import { TOGGLE_DETAIL_PANEL_CONTAINER, HIDE_DETAIL_PANEL_CONTAINER,  SHOW_DETAIL_PANEL_CONTAINER} from "./displayDetailsPanelContainerReducer"

export const toggleDetailPanelContainer = () => ({
    type: TOGGLE_DETAIL_PANEL_CONTAINER,
})

export const hideDetailPanelContainer = () => ({
    type: HIDE_DETAIL_PANEL_CONTAINER,
})

export const showDetailPanelContainer = () => ({
    type: SHOW_DETAIL_PANEL_CONTAINER,
})