export const ADD_CLOSEST_LVZ_INFO = 'ADD_CLOSEST_LVZ_INFO'
export const RESET_CLOSEST_LVZ_INFO = 'RESET_CLOSEST_LVZ_INFO'

export function closestLvzInfoReducer (state = [], action) {
    switch (action.type){
        case ADD_CLOSEST_LVZ_INFO:
            return action.payload
        case RESET_CLOSEST_LVZ_INFO: 
            return []
        default: return state
    }
}