export const TOGGLE_DETAIL_PANEL_CONTAINER = 'TOGGLE_DETAIL_PANEL_CONTAINER'
export const HIDE_DETAIL_PANEL_CONTAINER = 'HIDE_DETAIL_PANEL_CONTAINER'
export const SHOW_DETAIL_PANEL_CONTAINER = 'SHOW_DETAIL_PANEL_CONTAINER'

export function displayDetailsPanelContainerReducer (state = true, action) {
    switch (action.type){
        case TOGGLE_DETAIL_PANEL_CONTAINER:
            return !state
        case HIDE_DETAIL_PANEL_CONTAINER:
            return false
        case SHOW_DETAIL_PANEL_CONTAINER:
            return true
        default: return state
    }
}