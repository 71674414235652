import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//REDUX SELECTOR
import { showPriceAreaSelector } from '../map/state/showPriceAreaSelector'

//REDUX  ACTION
import { toggleCityInformationDetailsPanel } from '../detailsPanel/state/displayCityInformationsDetailsPanelAction'
import { togglePriceAreaOnMap } from '../map/state/showPriceAreaAction'

//UTILS
import { getPostCode } from '../api/mapApi';

//STYLE
import './CityInformationsDetailsPanel.css'
import * as IoIcon from 'react-icons/io5';

//JSON
import citiesData from '../cityInformation/citiesData.json'


function CityInformationsDetailsPanel(props) {

    const [cityInformationPostCode, setCityInformationPostCode] = useState()
    
    const dispatch = useDispatch()

    const showPriceArea = useSelector(showPriceAreaSelector)

    useEffect(() => {
        const fetchData = async () => {
            if(props.map){
                let postCode = await getPostCode(props.map.getCenter().toJSON())
                setCityInformationPostCode(postCode)
            }
        };
        fetchData()
    }, [props.map])


    useEffect(() => {
        //TO DO - DISPLAY HARDCODED DATA FOR SAINT DENIS
        //5COLORED POLYGON BASED ON PRICE ZONE)
        //MAKE IT MORE SCALABLE
        if(cityInformationPostCode == 97){
            dispatch(togglePriceAreaOnMap(true))
        }
        return ()=>{
            dispatch(togglePriceAreaOnMap(false))
        }
    }, [cityInformationPostCode, showPriceArea])

    //key to display cities information from citiesData.json
    let key

    if(cityInformationPostCode !== undefined){
        //ex: cityPracticalInfo75
        key = "cityPracticalInfo" + cityInformationPostCode
        //to check if there is data about city on citiesData.json
        if (key in citiesData){
            key = "cityPracticalInfo" + cityInformationPostCode
        } else {
            key = "cityPracticalInfoNotFound"
        }
    } else {
        key = "cityPracticalInfoNotFound"
    }


    function togglePanel(){
        dispatch(toggleCityInformationDetailsPanel())
    }

    window.payFPSParis = function (){
        window.open("https://usager.tefps-paris.fr/#/fps");
    }

    window.payFPS = function (){
        window.open("https://www.stationnement.gouv.fr/fps");
    }

    window.openOfficialInstSite = function (){
        window.open("https://www.prefecturedepolice.interieur.gouv.fr/Vous-aider/Securite-routiere/Stationnement/Le-stationnement-sur-les-zones-de-livraisons");
    }

    window.fpsParisDetails = function (){
        window.open("https://www.paris.fr/pages/forfait-post-stationnement-et-recours-administratif-prealable-obligatoire-5260#le-forfait-post-stationnement-fps");    
    }

    const styleChevron = {
        height: "20px", 
        width: "20px",
    } 

    return (
        <div id="infoStreetPanelWrapper" className="infoStreetPanelWrapper" style={{bottom: "0px", transform: "none"}}>
            <div id="infoStreetPanel" className="infoStreetPanel infoStreetPanelBackground">
                <div id="closeInfoStreetPanelWrapper" className="closeInfoStreetPanelWrapper" style={{cursor: "pointer"}}>
                    <button style={{margin:"0px"}} onClick={()=> togglePanel()}>
                        <IoIcon.IoChevronDownOutline style={styleChevron}/>
                    </button>
                </div>
                <div style={{display: "block"}} 
                    dangerouslySetInnerHTML={{__html: citiesData[key]}}
                >
                </div>
            </div>
        </div>

    )
}

export default CityInformationsDetailsPanel
