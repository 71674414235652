import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Polyline } from '@react-google-maps/api'

//REDUX SELECTORS
import { frontStyleOnstreetDeliverySelector } from './state/frontStyleOnstreetDeliverySelector';
import { onstreetDeliveryMarkerDataSelector } from './state/onstreetDeliveryMarkerDataSelector';
import { itineraryModeSelector } from '../global/state/itineraryModeSelector'

//REDUX ACTIONS
import { showOnstreetDeliveryInfoWindow } from './state/onstreetDeliveryInfoWindowAction';

function OnstreetDeliveryMarker (props){

    const dispatch = useDispatch()

    const frontStyleOnstreetDelivery = useSelector(frontStyleOnstreetDeliverySelector)
    const onstreetDeliveryMarkerData = useSelector(onstreetDeliveryMarkerDataSelector)
    const itineraryMode = useSelector(itineraryModeSelector)


    let visible = false


    //to display markers only if itineraryMode isn't active (torch)
    if(!itineraryMode){
        //to show marker on map if the zoom level is the correct one
        //to display marker if there are in the surroundingCityZone
        if(onstreetDeliveryMarkerData.surroundingCityZone.find(id => id === props.info.zoneId) && frontStyleOnstreetDelivery === 1){
            visible = true
        } else {
            visible = false
        }
    }

    //all polylines options
    const options = {
        strokeColor: "#FF8C00",
        strokeOpacity: 1,
        strokeWeight: 2,
        clickable: true,
        draggable: false,
        editable: false,
        visible: visible,
    };

    function displayOnstreetDeliveryMarkerInfoWindow(){
        dispatch(showOnstreetDeliveryInfoWindow(props.info.location))
    }

    return (
        <div>
            <Polyline
                path={props.info.path}
                options={options}
                onClick={displayOnstreetDeliveryMarkerInfoWindow}
            />      
        </div>
    )
}

export default OnstreetDeliveryMarker
