import React from 'react'

//STYLE
import './ExpandedDetailsPanel.css'
import './PlaceholderCard.css'

function PlaceholderCard() {

    return (
        <div className="card-parking-placeholder loading">
        </div>
    )
}

export default PlaceholderCard
