import React from "react";
import { useSelector } from "react-redux";

// UTILS
import { isCurrentTimeInParkingOpenHours } from "../utils";

//COMPONENTS
import ParkingMarker from "./ParkingMarker";

//REDUX SELECTORS
import { parkingMarkerDataSelector } from "../markers/state/parkingMarkerDataSelector";
import { liveParkingAvailabilitiesSelector } from "../global/state/liveParkingAvailabilitiesSelector";

const WrapperParkingsMarkers = React.memo((props) => {
  const parkingMarkerData = useSelector(parkingMarkerDataSelector);
  const liveParkingAvailabilities = useSelector(
    liveParkingAvailabilitiesSelector,
  );

  return (
    <div>
      {/* mapping through data to render all ParkingMarker. Passing their properties using props. */}
      {parkingMarkerData.map((parking, index) => {
        let fillingRatio;
        let remainingSpots;
        if (liveParkingAvailabilities[parking.index]) {
          fillingRatio = liveParkingAvailabilities[parking.index].filling_ratio;
          remainingSpots =
            liveParkingAvailabilities[parking.index].remaining_spots;
        } else {
          fillingRatio = null;
          remainingSpots = null;
        }
        return (
          <ParkingMarker
            position={{
              lat: parking.location.lat,
              lng: parking.location.lng,
            }}
            key={index}
            location={{
              lat: parking.location.lat,
              lng: parking.location.lng,
            }}
            info={{
              isOpenNow: isCurrentTimeInParkingOpenHours(
                parking.open_hours,
                parking.timezone,
              ),
              operator: parking.operator,
              address: parking.address,
              name: parking.name,
              reservation: parking.reservation_only,
              price: {
                price1: parking.price_1_h,
                price3: parking.price_3_h,
                price24: parking.price_24_h,
              },
              parkingInfo: [
                parking.is_24_7,
                parking.reservation_link,
                parking.e_charge,
                parking.restroom,
                parking.carwash,
                parking.access_disabled,
              ],
              index: parking.index,
              key: index,
              fillingRatio: fillingRatio,
              remainingSpots: remainingSpots,
            }}
            map={props.map}
          />
        );
      })}
    </div>
  );
});

export default WrapperParkingsMarkers;
