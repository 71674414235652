const initialState = 2

export const UPDATE_FRONT_STYLE_PARKING = 'UPDATE_FRONT_STYLE_PARKING'

export function frontStyleParkingReducer (state = initialState, action) {
    switch (action.type){
        case UPDATE_FRONT_STYLE_PARKING:
            return action.payload
        default: return state
    }
}