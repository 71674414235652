const initialeState = {activate: false, visible: false}

export const TOGGLE_SPARKED_ONSTREET_SUCCESS = 'TOGGLE_SPARKED_ONSTREET_SUCCESS'
export const RESET_SPARKED_ONSTREET_SUCCESS = 'RESET_SPARKED_ONSTREET_SUCCESS'

export function displaySparkedOnStreetSuccessReducer(state = initialeState, action) {
    switch (action.type){
        case TOGGLE_SPARKED_ONSTREET_SUCCESS:
            return action.payload
        case RESET_SPARKED_ONSTREET_SUCCESS:
                return initialeState
        default: return state
    }
}