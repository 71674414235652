import React from "react";
import { useSelector } from 'react-redux'

//COMPONENTS
import InstallCocoparks from '../installation/InstallCocoparks';
import SearchBar from './SearchBar';
import UserMenuIcon from "../userMenu/UserMenuIcon";
import NavMenuIcon from './NavMenuIcon';

//REDUX SELECTORS
import { displayInstallCocoparksSelector } from '../installation/state/displayInstallCocoparksSelector'

//STYLE
import './NavBar.css';
import '../global/Global.css'

function NavBar(props) {

    const displayInstallCocoparks = useSelector(displayInstallCocoparksSelector)
    const config = useSelector((state) => state.config)
    
    return (
        <div className="flex-center-inline" style={{ position: "absolute", top: "0", left: "0", zIndex: "10", width: "100%", paddingTop: "1em"}}>
            <div className="navbar-div flex-space-between-inline">
                
                {config.geolocalisation_searchbar 
                    ? <SearchBar
                        map={props.map}
                        geolocationActive={props.geolocationActive}
                    />
                    : <div className="search-bar" style={{ background: "transparent" }}>
                        <div style={{ cursor: 'pointer'}}>
                            <NavMenuIcon />
                        </div>
                    </div>
                }
                
                {displayInstallCocoparks && config.app_install_push ?
                    <InstallCocoparks/>
                    :null}

                <UserMenuIcon/>
            </div>
        </div>
    )
    
}

export default NavBar
