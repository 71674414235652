import React from 'react'
import { useDispatch, useSelector} from 'react-redux'

//REDUX SELECTOR
import { displayItineraryOverviewSelector } from './state/displayItineraryOverviewSelector';
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector';

//REDUX ACTIONS
import { resetItineraryOverviewData } from './state/displayItineraryOverviewAction';
import { updateActualItinerarySegment } from './state/actualItineraryPathSegmentAction';
import { toggleInteractiveBox } from '../cocosupporter/state/displayInteractiveBoxAction';
import { toggleSparkedOnStreetSuccess } from '../itinerary/state/displaySparkedOnStreetSuccessAction';

//UTILS
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//STYLE
import './ItineraryOverviewToGuidanceScreen.css'
import './ItineraryPanel.css'
import * as IoIcon from 'react-icons/io5';


function ItineraryOverviewToGuidanceScreen(props) {

    const dispatch = useDispatch()

    const displayItineraryOverview = useSelector(displayItineraryOverviewSelector)
    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)

    function closeOverviewToGuidanceScreen(){
        dispatch(resetItineraryOverviewData())
    }

    function displayFirstStepItinerary(){
        //activate on street success screen if user leave itinerary
        dispatch(toggleSparkedOnStreetSuccess({activate: true, visible: false}))
        let researchCenterPosition = displayItineraryOverview.centerResearchMarker
        dispatch(resetItineraryOverviewData())
        dispatch(toggleInteractiveBox({value: true, category: "torch", messageType: "start"}))
        props.map.panTo(researchCenterPosition)
        dispatch(updateActualItinerarySegment(-1))
        props.map.setZoom(17)
        setTimeout(function(){            
            dispatch(updateActualItinerarySegment(2))
        }, 1000)
    }

    return (
        <div className="freeze-background-time-to-park flex-end-column" >
            <IoIcon.IoClose 
                className='menu-bars' 
                style={{position: "absolute", top: "15px", right: "15px", color: "white"}}
                onClick={()=> closeOverviewToGuidanceScreen()}
            />
            <div className="time-park-button-div">
                <button className="green-button-time-to-park"><p className="no-margin" onClick={()=> {displayFirstStepItinerary(); addActionToDbBasedOnElement("itineraryOverviewToGuidanceScreenBtn", trackingIdAndAnonymousLocal, trackingSessionId)}}>C'EST PARTI!</p></button>
            </div>
            <div className="yellow-text-div">
                <p className="title">Itinéraire optimum</p>
            </div>
            <div className="itinerary-overview">
                <div className="itinerary-panel-divs">
                    <div className="itinerary-panel-elapsed-time itinerary-panel-div">
                        <div className="ip-div-title">TEMPS ESTIME</div>
                        <div className="ip-div-main-content">{displayItineraryOverview.estimatedTime}</div>
                        <div className="ip-div-secondary-content">min</div>
                    </div>
                    <div className="itinerary-panel-past-slots itinerary-panel-div">
                        <div className="ip-div-title">#EMPLACEMENTS</div>
                        <div className="ip-div-main-content">{displayItineraryOverview.nbTotalOfSlots}</div>
                    </div>
                    <div className="itinerary-panel-parking itinerary-panel-div">
                        <div className="ip-div-title">PARKING</div>
                        <div className="ip-div-main-content">
                            <img src="images/itinerary/parking_arrow.png" alt=""/> 
                        </div>
                        <div className="ip-div-secondary-content"> </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ItineraryOverviewToGuidanceScreen
