export const UPDATE_FREED_SPOT_MARKER_DATA = 'UPDATE_FREED_SPOT_MARKER_DATA'
export const ADD_FREED_SPOT_MARKER_DATA = 'ADD_FREED_SPOT_MARKER_DATA'

export function freedSpotMarkerDataReducer (state = [], action) {
    switch (action.type){
        case UPDATE_FREED_SPOT_MARKER_DATA:
            let items = [...state]; 
            let item = {...items[action.payload]};
            item.visible = false;
            items[action.payload] = item;
            return items
        case ADD_FREED_SPOT_MARKER_DATA:
            action.payload.forEach(item=>{
                item.visible = true
            })
            let combineArray = [...state, ...action.payload]
            return combineArray
        default: return state
    }
}