import './PillBadge.css'

function PillBadge({backgroundColor, text}) {
    

    return (
        <div className='pill-badge' style={{backgroundColor: backgroundColor ? backgroundColor : "grey"}}>
            <p>{text}</p>
        </div>
    )
}

export default PillBadge
