import React from 'react'
import { useSelector } from 'react-redux'
import { Polyline } from '@react-google-maps/api'

//REDUX SELECTOR
import { itineraryPathSelector } from './state/itineraryPathSelector'
import { actualItineraryPathSegmentSelector } from './state/actualItineraryPathSegmentSelector'

function TorchMarkerSinglePolyline() {

    const itineraryPath = useSelector(itineraryPathSelector)
    const actualItineraryPathSegment = useSelector(actualItineraryPathSegmentSelector)

    let polylineColor = "blue"

    let itineraryPathSinglePolyline = []

    return (
        <div>
            {itineraryPath.data.map((item, index)=>{
                if(index > actualItineraryPathSegment){
                    polylineColor = "grey"
                }
                if((index+1)<itineraryPath.data.length){
                    itineraryPathSinglePolyline = [
                        {lat: item.lat, lng: item.lng},
                        {lat: itineraryPath.data[index + 1 ].lat, lng: itineraryPath.data[index + 1].lng},
                    ]
                }
                    return (
                        <Polyline
                            path={itineraryPathSinglePolyline}
                            options={{
                                strokeColor: polylineColor,
                                strokeOpacity: 0.5,
                                strokeWeight: 10,
                            }}
                            key={index}
                        />
                    )
            })}
        </div>
    )
}

export default TorchMarkerSinglePolyline
