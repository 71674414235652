const initialState = {
    lvzExists: false,
}

export const STORE_LVZ_INFO = 'STORE_LVZ_INFO'
export const RESET_LVZ_INFO = 'RESET_LVZ_INFO'

export function lvzInfoReducer (state = initialState, action) {
    switch (action.type){
        case STORE_LVZ_INFO:
            return action.payload
        case RESET_LVZ_INFO:
            return initialState
        default: return state
    }
}