import {useState} from 'react'
import { useSelector } from "react-redux";

//REDUX SELECTOR
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'

//UTILS
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//COMPONENTS
import Tutorial from './Tutorial';

//STYLE
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import * as IoIcon from 'react-icons/io5';

function AccordionHelp(props) {
    
    const [expanded, setExpanded] = useState(false);

    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    return (
        <div>
            <Accordion expanded={expanded === props.section} 
                onChange={handleChange(props.section)} 
                onClick={()=> addActionToDbBasedOnElement(props.id, trackingIdAndAnonymousLocal, trackingSessionId)}
            >
                <AccordionSummary
                expandIcon={<IoIcon.IoChevronDown style={{color: "grey", height: "15px", width: "15px"}}/>}
                aria-controls={props.section + 'bh-content'}
                id={props.section + 'bh-header'}
                >
                <Typography style={{fontWeight: "bold", fontSize: "1.2rem"}}>{props.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    { props.type === "text" ? props.content : <Tutorial content={props.content}/> }
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default AccordionHelp
