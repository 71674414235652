export const UPDATE_LVZ_MARKER_REAL_TIME = 'UPDATE_LVZ_MARKER_REAL_TIME'
export const ADD_LVZ_MARKER_REAL_TIME = 'ADD_LVZ_MARKER_REAL_TIME'

export function lvzMarkerRealTimeReducer (state = {}, action) {
    switch (action.type){
        case ADD_LVZ_MARKER_REAL_TIME:
            return action.payload
        case UPDATE_LVZ_MARKER_REAL_TIME:
            let updatedState = {...state, ...action.payload}
            return updatedState
        default: return state
    }
}