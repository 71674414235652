import React from 'react'


//STYLE
import * as IoIcon from 'react-icons/io5';
import './ParkingSpecificDetailsPanel.css'
import './PlaceholderParkingSpecificDetailsPanel.css'


function PlaceholderParkingSpecificDetailsPanel(){

    return (
        <>
            <div className='specific-detail-panel-container flex-center-column'>
                {/* div croix verte */}
                <div>
                    <IoIcon.IoClose 
                        style={{color: "var(--light-grey)", width: "20px", height: "20px"}}
                    />
                </div>
                {/* div parking title + real time */}
                <div className="flex-space-between-inline" style={{width: "100%"}}>
                    {/* div parking title + adress */}
                    <div>
                        <div className="blue-title-placeholder loading"></div>
                        <div className="grey-subtitle-placeholder loading"></div>
                    </div>
                    {/* div real time + operator */}
                    <div className="flex-flex-end-column" style={{position: "relative"}}>
                        <div className="loading" style={{backgroundColor: "var(--light-grey", height: "30px", padding: "10px", borderRadius: "15px", textAlign: "center", whiteSpace:"nowrap", width: "100px"}}>
                        </div>
                        <p className="grey-subtitle">-</p>
                    </div>
                </div>
                {/* div info parking (time, cost, option) */}
                <div className="flex-space-between-inline pinfo_parkings" style={{width: "100%", height: "15vh"}}>
                    {/* div time */}
                    <div className="flex-center-column pinfo_container_placeholder loading">
                    </div>
                    {/* div cost */}
                    <div className="flex-center-column pinfo_container_placeholder loading">
                    </div>
                    {/* div option */}
                    <div className="pinfo_container_placeholder featuresDiv loading">
                    </div>

                </div>
                {/* div button */}
                <div className="flex-space-around-inline pactions-parkings">
                    <button className="pactions-button_placeholder loading">
                        Go !
                    </button>
                    <button className="pactions-button_placeholder loading">Garé ici</button>
                </div>
            </div>            
        </>
    
    )
}

export default PlaceholderParkingSpecificDetailsPanel
