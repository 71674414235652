import * as global from '../../global/globalVariable'

//initalState feature correspondence ["carwash", "booking", "evh", "pmr", "delivery"]
const initalState = [false, false, false, false, false]
let filterTrackersIdentification = global.filterTrackersIdentification

export const UPDATE_FILTER = 'UPDATE_FILTER'
export const RESET_FILTER = 'RESET_FILTER'

export function mapFilterTrackersReducer (state = initalState, action) {
    switch (action.type){
        case UPDATE_FILTER:
            let indexOfFeature = filterTrackersIdentification.indexOf(action.payload)
            let tempArray = [...state]
            tempArray[indexOfFeature] = !tempArray[indexOfFeature]
            return tempArray
        case RESET_FILTER:
            return initalState
        default: return state
    }
}