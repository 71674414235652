import React from 'react'
import {useSelector} from 'react-redux'

//COMPONENTS
import PmrMarker from './PmrMarker'

//REDUX SELECTORS
import { pmrMarkerDataSelector } from './state/pmrMarkerDataSelector'

//UTILS
import { generateKey } from '../global/MapUtils'


export const WrapperPmrMarker = React.memo((props) => {

    let allPmrMarkers = []
    
    const pmrMarkerData = useSelector(pmrMarkerDataSelector)
    
    //mapping through data to render all PmrMarker. Passing their properties using props.
    Object.keys(pmrMarkerData.data).forEach((zoneId) => {
        pmrMarkerData.data[zoneId].forEach((marker, index)=>{
            allPmrMarkers.push(
                <PmrMarker 
                    key={generateKey(zoneId, index)}
                    
                    location={{
                        lat: marker.lat,
                        lng: marker.lng
                    }}

                    info={{
                        map: props.map,
                        zoneId: zoneId
                    }}
                />
            )
        })
    });


    return (
        <div>
            {allPmrMarkers}
        </div>
    )
})

export default WrapperPmrMarker
