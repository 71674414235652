const initialState = {visible: false, position: {lat: 0, lng: 0}}

export const SHOW_LVZ_INFOWINDOW = 'SHOW_LVZ_INFOWINDOW'
export const HIDE_LVZ_INFOWINDOW = 'HIDE_LVZ_INFOWINDOW'

export function lvzInfoWindowReducer (state = initialState, action) {
    switch (action.type){
        case SHOW_LVZ_INFOWINDOW:
            let displayLvzInfo = {
                visible: true,
                position: {
                    lat: action.payload.location.lat,
                    lng: action.payload.location.lng
                },
                legend: action.payload.legend
            }
            return displayLvzInfo
        case HIDE_LVZ_INFOWINDOW:
            let hideLvzInfo = {
                visible: false,
            }
            return hideLvzInfo
        default: return state
    }
}