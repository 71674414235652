import React, { useState, useRef, useEffect } from 'react'
import { Autocomplete} from "@react-google-maps/api";
import { useDispatch, useSelector } from 'react-redux'

//REDUX SELECTOR
import { displayAddAddressPopupSelector } from './state/displayAddAddressPopupSelector';
import { userUidSelector } from '../global/state/userUidSelector';

//REDUX ACTIONS
import { toggleAddAddressPopup } from '../popup/state/displayAddAddressPopupAction';
import { toggleModificationContainer } from '../userMenu/state/displayModificationContainerAction'
import { updateUserInfoJson } from '../userMenu/state/userInfoJsonAction'

//UTILS
import { postAddress, updateAddress } from '../api/userApi';
import { getUser } from '../api/authApi';

//STYLE
import './AddAddressPopup.css'

function AddAddressPopup() {

    const dispatch = useDispatch()

    const displayAddAddressPopup = useSelector(displayAddAddressPopupSelector)
    const userUid = useSelector(userUidSelector)

    const autocompleteRef = useRef(null) //to store the map

    const [title, setTitle] = useState("")
    const [destinationInputValue, setDestinationInputValue] = useState("")
    const [displayTitleInput, setDisplayTitleInput] = useState(true)
    const [valideAddress, setValideAddress] = useState(false)
    const [selectedAddressFromGeometry, setSelectedAddressFromGeometry ] = useState("")
    const [destinationInputClassname, setDestinationInputClassname ] = useState("input-destination-address")
    const [titleInputClassname, setTitleInputClassname ] = useState("input-destination-address")
    
    const options = {
        componentRestrictions: {country: ['fr', 're']}
    };

    function onLoad (autocomplete) {
        autocompleteRef.current = autocomplete;
    }

    async function onPlaceChanged () {
        if (autocompleteRef.current !== null) {
            let place = autocompleteRef.current.getPlace()
            if(place.geometry){
                setDestinationInputValue(place.formatted_address)
                setValideAddress(true)
                setSelectedAddressFromGeometry(place.formatted_address)
                setDestinationInputClassname("input-destination-address")
            }else {
                setValideAddress(false)
            }
        } else {
          console.log('Autocomplete is not loaded yet!')
        }
    }

    async function addAddress(){
        let valideTitle = false
        let name = ""
        if(valideAddress === true && destinationInputValue === selectedAddressFromGeometry && destinationInputValue !== ''){
            setDestinationInputClassname("input-destination-address")
        } else { 
            setDestinationInputValue("")
            setDestinationInputClassname("input-destination-address-invalide")
        }
        if(title === ""){
            setTitleInputClassname("input-destination-address-invalide")
        } else {
            setTitleInputClassname("input-destination-address")
            valideTitle = true
        }
        if(valideTitle && valideAddress){
            if(displayAddAddressPopup.addressId === "work" || displayAddAddressPopup.addressId === "home"){
                name = displayAddAddressPopup.addressId
            } else {
                name = title
            }
            if(displayAddAddressPopup.update){
                await updateAddress(userUid, name, selectedAddressFromGeometry, displayAddAddressPopup.addressId)
            } else {
                await postAddress(userUid, name, selectedAddressFromGeometry, displayAddAddressPopup.addressId)
            }
            await updateFrontWithUpdatedData(userUid)
            hideModifyPopup()
        }
    }

    async function updateFrontWithUpdatedData(uid){
        let userFromDb = await getUser(uid)
        dispatch(updateUserInfoJson({...userFromDb, uid: uid}))
    }

    function hideModifyPopup(){
        dispatch(toggleAddAddressPopup({value: false}))
        dispatch(toggleModificationContainer({value: false}))
    }

    useEffect(() => {
        if(displayAddAddressPopup.addressId === "home"){
            setDisplayTitleInput(false)
            setTitle("Votre domicile")
        } else if (displayAddAddressPopup.addressId === "work"){
            setDisplayTitleInput(false)
            setTitle("Votre travail")
        }
    }, [displayAddAddressPopup])

    return (
        <div className="popup">
            <div className="popup-inner"> 
                <div className="add-address-container flex-center-column">
                    <div className="input-destination-address-container">
                        {displayTitleInput ?
                        <>
                            <p className="add-address-text">Nom de l'adresse</p>
                            <input 
                                onChange={(e)=> setTitle(e.target.value)} 
                                value={title} 
                                placeholder="Nom de l'adresse"
                                className={titleInputClassname}
                            />
                            <p className="add-address-text">Adresse</p>
                        </>: 
                        <p className="add-address-text">{title}</p>
                        }
                        <Autocomplete
                        onLoad={onLoad}
                        onPlaceChanged={onPlaceChanged}
                        options={options}
                        >
                            <input 
                                onChange={(e)=> setDestinationInputValue(e.target.value)} 
                                value={destinationInputValue} 
                                placeholder="40 rue du Louvre"
                                className={destinationInputClassname}

                            />
                        </Autocomplete>
                    </div>
                    <div style={{width: "100%"}} className="flex-flex-end-inline">
                        <button className="action-button" onClick={()=> hideModifyPopup()}>
                            <span className="action-button-text">Annuler</span>
                        </button>
                        <button className="green-action-button" onClick={()=> addAddress()}>
                            <span className="green-action-button-text">Ajouter</span>
                        </button>
                    </div>
                </div>  
            </div>
        </div>
    )
}

export default AddAddressPopup
