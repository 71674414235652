export const UPDATE_PARKING_MARKER_DATA = 'UPDATE_PARKING_MARKER_DATA'

export function parkingMarkerDataReducer (state = [], action) {
    switch (action.type){
        case UPDATE_PARKING_MARKER_DATA:
            let combineArray = [...state, ...action.payload]
            let uniqueArray = combineArray.filter((parking, index, self) =>
                index === self.findIndex((item) => (
                    item.index === parking.index 
                ))
            )
            return uniqueArray
        default: return state
    }
}