import React from 'react'

//COMPONENTS
import PlaceholderCard from './PlaceholderCard'

function PlaceholderWrapperCard() {

    let allParkingCardPlaceholder = []
    let nbCardPlaceholder = 10

    for(let i =0; i<nbCardPlaceholder; i++){
        allParkingCardPlaceholder.push(
            <PlaceholderCard key={i}/>
            )
    }

    return (
        <>
            {allParkingCardPlaceholder}
        </>
    )
}

export default PlaceholderWrapperCard
