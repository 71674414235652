import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

//COMPONENT
import GreenButton from '../global/GreenButton';

//REDUX SELECTOR
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'

//REDUX ACTIONS
import { toggleExpandedDetailPanel } from '../detailsPanel/state/detailsPanelExpandedAction'

//UTILS
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//STYLE
import './TorchButton.css'

function TorchButton() {

    const dispatch = useDispatch()

    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)
    
    //open the expanded details panel when user clicks on torch button
    async function expandsDetailPanel(){
        dispatch(toggleExpandedDetailPanel(true))
    }

    return (
        <div className="torch-button" onClick={()=> expandsDetailPanel()}>
            <GreenButton 
                icon={
                    <img 
                        style={{width: "35px"}} 
                        src="images/itinerary/flashlight-white.png" 
                        alt="torch"
                        onClick={()=>addActionToDbBasedOnElement("revealSlotsButton", trackingIdAndAnonymousLocal, trackingSessionId)}
                    />
                }/> 
        </div>
    )
    
}

export default TorchButton
