const initialState = {visible: false, position: {lat: 0, lng: 0}}

export const SHOW_ONSTREET_DELIVERY_INFOWINDOW = 'SHOW_ONSTREET_DELIVERY_INFOWINDOW'
export const HIDE_ONSTREET_DELIVERY_INFOWINDOW = 'HIDE_ONSTREET_DELIVERY_INFOWINDOW'

export function onstreetDeliveryInfoWindowReducer (state = initialState, action) {
    switch (action.type){
        case SHOW_ONSTREET_DELIVERY_INFOWINDOW:
            let displayDeliveryInfo = {
                visible: true,
                position: {
                    lat: action.payload.lat,
                    lng: action.payload.lng
                },
            }
            return displayDeliveryInfo
        case HIDE_ONSTREET_DELIVERY_INFOWINDOW:
            let hideDeliveryInfo = {
                visible: false,
            }
            return hideDeliveryInfo
        default: return state
    }
}