export const UPDATE_ITINERARY_PATH = 'UPDATE_ITINERARY_PATH'
export const RESET_ITINERARY_PATH = "RESET_ITINERARY_PATH"
export const DONE_ITINERARY_PATH = "DONE_ITINERARY_PATH"

export function itineraryPathReducer(state = {data: [], loading: ""}, action) {
    switch (action.type){
        case UPDATE_ITINERARY_PATH:
            let newArray = [...state.data, ...action.payload]
            return {data: newArray, loading: ""}
        case DONE_ITINERARY_PATH:
            return {data: state.data, loading: "done"}
        case RESET_ITINERARY_PATH:
            return {data: [], loading: ""}
        default: return state
    }
}