const initalState = {data: "", surroundingCityZone: ""}

export const UPDATE_ONSTREET_DELIVERY_MARKER_DATA = 'UPDATE_ONSTREET_DELIVERY_MARKER_DATA'

export function onstreetDeliveryMarkerDataReducer (state = initalState, action) {
    switch (action.type){
        case UPDATE_ONSTREET_DELIVERY_MARKER_DATA:
            return action.payload
        default: return state
    }
}