import React, { useState} from 'react'
import { useDispatch } from 'react-redux'
import { useSwipeable } from 'react-swipeable';


//REDUX ACTION
import { toggleMustLoginScreen } from './state/displayMustLoginScreenAction';
import { toggleUserMenu } from './state/displayUserMenuAction ';

//STYLE
import logo from '../images/logo.png'
import './MustLoginScreen.css'

function MustLoginScreen() {

    const [containerClass, setContainerClass] = useState("container-login-screen flex-center-column shareApp_start")

    const dispatch = useDispatch()

    function hideMustLoginScreen(){
        setContainerClass("container-login-screen flex-center-column shareApp_end")
        setTimeout(function(){
            dispatch(toggleMustLoginScreen(false))
        }, 200)
    }

    function displayUserMenuOnRight(){
        dispatch(toggleUserMenu(true))
        hideMustLoginScreen()
    }

    //to swipe up and down details panel
    const handlers = useSwipeable(
        { 
        onSwipedDown: 
            () => {
                hideMustLoginScreen()
            } ,
        }
    )

    return (
        <div className="freeze-background-time-to-park flex-end-column" onClick={()=> hideMustLoginScreen()}>
            <div className={containerClass} {...handlers}>
                <div style={{backgroundColor:"#BFBFBF", height:"4px", width: "45px", borderRadius: "2px", marginTop: "5px"}}>
                </div>
                <p className="user-screen-text no-margin">Veuillez vous connecter pour <br/>accéder à toutes les informations <br/> et fonctionnalités</p>
                <img style={{width: "35%", margin: "10%"}} src={logo} alt="logo"/>
                <p className="user-screen-text-bold">Rejoignez la Cocommunauté Cocoparks maintenant</p>
                <button className="green-action-button" style={{marginTop: "10px"}} onClick={()=> displayUserMenuOnRight()}>
                    <span className="green-action-button-text">Inscription gratuite</span>
                </button>
                <p className="user-screen-text-green" onClick={()=> displayUserMenuOnRight()}>Déjà inscrit ? Identifiez-vous</p>
            </div>
        </div>
    )
}

export default MustLoginScreen
