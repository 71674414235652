export const ACTIVATE_LIVE_INDIC_ANIMATION= 'ACTIVATE_LIVE_INDIC_ANIMATION'
export const DEACTIVATE_LIVE_INDIC_ANIMATION= 'DEACTIVATE_LIVE_INDIC_ANIMATION'

export function liveIndicReducer (state = false, action) {
    switch (action.type){
        case ACTIVATE_LIVE_INDIC_ANIMATION:
            return true
        case DEACTIVATE_LIVE_INDIC_ANIMATION:
            return false
        default: return state
    }
}