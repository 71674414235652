import React from "react";
import { useSelector } from 'react-redux'
import { Marker } from '@react-google-maps/api'

//REDUX SELECTOR
import { frontStyleLvzSelector } from './state/frontStyleLvzSelector'

//UTILS
import * as global from '../global/globalVariable'
import { zoomOnClickedLvz, displayLvzDetailPanel} from '../global/LvzUtils';

function LiveViewZoneCircle ({position, availability, map, zoneId}){

    const frontStyleLvz = useSelector(frontStyleLvzSelector)

    let image = {
        url: `images/lvzMarker/lvz-marker-${global.lvzColors[availability].pictureKey}-full.png`,
        scaledSize: new window.google.maps.Size(15, 20)
    };
    let visible = true

    if(frontStyleLvz >= 3){
        visible = false
    } else {
        visible = true
    }

    function clickOnLvzMarker(){
        zoomOnClickedLvz(map, position)
        displayLvzDetailPanel(zoneId)
    }

    return(
        <Marker
            position={position}
            icon={image}
            clickable={true}
            visible={visible}
            onClick={clickOnLvzMarker}
        /> 
    )

}

export default LiveViewZoneCircle