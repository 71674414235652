import React from "react";
import { useSelector, useDispatch } from "react-redux";

//COMPONENTS
import LiveIndic from "./LiveIndic";

//REDUX SELECTORS
import { parkingMarkerDataSelector } from "../markers/state/parkingMarkerDataSelector";
import { researchModeSelector } from "../global/state/researchModeSelector";
import { aroundMeModeSelector } from "../global/state/aroundMeModeSelector";
import { cocoSearchMarkerSelector } from "../markers/state/cocoSearchMarkerSelector";
import { userLocationSelector } from "../markers/state/userLocationSelector";
import { trackingIdAndAnonymousLocalSelector } from "../userTracking/state/trackingIdAndAnonymousLocalSelector";
import { trackingSessionIdSelector } from "../userTracking/state/trackingSessionIdSelector";

//REDUX ACTIONS
import { toggleParkingSpecificDetailPanel } from "./state/displayParkingSpecificDetailPanelAction";
import { hideDetailPanelContainer } from "../detailsPanel/state/displayDetailsPanelContainerAction";
import { toggleLvzSpecificDetailPanel } from "../detailsPanel/state/displayLvzSpecificDetailPanelAction";
import { updateSpecificParkingData } from "../detailsPanel/state/specificParkingDataAction";
import { selectParkingMarker } from "../markers/state/selectedParkingMarkerAction";
import { updatePathToDestination } from "../markers/state/directionToDestinationAction";

//UTILS
import * as global from "../global/globalVariable";
import {
  createObjectWithDataFromIndex,
  getDriveTimeAndPath,
  getWalkingTime,
} from "../global/MapUtils";
import { addActionToDbBasedOnElement } from "../global/UserTrackingUtils";
import { addDataToUserSessionInDB } from "../api/userTrackingApi.js";

//STYLE
import "./ExpandedDetailsPanel.css";

//JSON
import jsonZoomUIStatuses from "../zoom/jsonZoomUIStatuses.json";

function ParkingCard(props) {
  let companyMarkers = global.companyMarkers;
  let liveParkingsColor = global.liveParkingsColor;
  let liveParkingsThreshold = global.liveParkingsThreshold;
  let imgPath = "";

  const dispatch = useDispatch();
  const parkingMarkerData = useSelector(parkingMarkerDataSelector);
  const researchMode = useSelector(researchModeSelector);
  const aroundMeMode = useSelector(aroundMeModeSelector);
  const cocoSearchMarker = useSelector(cocoSearchMarkerSelector);
  const userLocation = useSelector(userLocationSelector);
  const trackingIdAndAnonymousLocal = useSelector(
    trackingIdAndAnonymousLocalSelector,
  );
  const trackingSessionId = useSelector(trackingSessionIdSelector);

  //finding operator &&
  //searching if the operator name is include in an array of companies names to display the correct icon on marker
  if (
    props.info.operator != null &&
    companyMarkers.indexOf(props.info.operator) >= 0
  ) {
    //to add -operatorName if the operator name is include in companyMarkers
    imgPath =
      jsonZoomUIStatuses["parking"].icons[2].imgSrc + "-" + props.info.operator;
  } else {
    //else set imgPath to cocomrkr
    imgPath = jsonZoomUIStatuses["parking"].icons[2].imgSrc;
  }

  if (props.info.isOpenNow) {
    //to add -color.png to imagePath if filllingRatio exists
    if (props.info.fillingRatio != null) {
      // Adding red icon url
      if (props.info.fillingRatio >= liveParkingsThreshold[2]) {
        imgPath = imgPath + "-" + liveParkingsColor[2] + ".png";
        // Adding Orange icon url
      } else if (
        props.info.fillingRatio >= liveParkingsThreshold[1] &&
        props.info.fillingRatio < liveParkingsThreshold[2]
      ) {
        imgPath = imgPath + "-" + liveParkingsColor[1] + ".png";
        // Adding Green icon url
      } else {
        imgPath = imgPath + "-" + liveParkingsColor[0] + ".png";
      }
    } else {
      //else just add .png
      imgPath = imgPath + ".png";
    }
  } else {
    imgPath = imgPath + "-grey.png";
  }

  async function clickOnParkingCard(index) {
    dispatch(selectParkingMarker(true, index));
    //to create an object with the correct properties to be displayed in parkingSpecificDetailPanel
    let resp = createObjectWithDataFromIndex(parkingMarkerData, index);
    let destination = {
      lat: resp.data.location.lat,
      lng: resp.data.location.lng,
    };
    props.map.panTo(destination);
    if (props.map.zoom < 15) {
      props.map.setZoom(15);
    }
    let location;
    let pathStyle;
    let walkingTime;
    //to get the direction either from userlocation or from cocoSearchMarker depending on researchMode being active or or
    if (researchMode.value && !aroundMeMode) {
      location = {
        lat: cocoSearchMarker.position.lat,
        lng: cocoSearchMarker.position.lng,
      };
      pathStyle = "dot";
    } else {
      location = { lat: userLocation.lat, lng: userLocation.lng };
      pathStyle = "line";
    }
    dispatch(toggleParkingSpecificDetailPanel(true));
    dispatch(hideDetailPanelContainer());
    dispatch(toggleLvzSpecificDetailPanel({ value: false, name: "" }));

    let drivingTimeAndPath = await getDriveTimeAndPath(location, destination);
    dispatch(
      updatePathToDestination({
        path: drivingTimeAndPath.drivePath,
        style: pathStyle,
      }),
    );
    setTimeout(async function () {
      walkingTime = await getWalkingTime(location, destination);
      dispatch(
        updateSpecificParkingData({
          ...resp,
          walkingTime: walkingTime,
          drivingTime: drivingTimeAndPath.driveTime,
        }),
      );
    }, 1000);
    //to add informations about clicked marker in DB
    let dataTracking = {
      parkingName: resp.data.name,
      lat: resp.data.location.lat,
      lng: resp.data.location.lng,
    };
    addDataToUserSessionInDB(
      "actions",
      trackingIdAndAnonymousLocal[1],
      trackingIdAndAnonymousLocal[0],
      trackingSessionId,
      dataTracking,
    );
  }

  return (
    <div
      className="card-parking"
      onClick={() => {
        clickOnParkingCard(props.info.index);
        addActionToDbBasedOnElement(
          props.info.id,
          trackingIdAndAnonymousLocal,
          trackingSessionId,
        );
      }}
    >
      <div className="card-image">
        <img src={imgPath} alt="" />
        &nbsp;{props.info.name}
      </div>
      <div className="card-title" style={{ color: props.info.color }}>
        {props.info.title}
        <div>{props.info.subtitle}</div>
      </div>
      <div className="flex-center-column" style={{ marginTop: "0px" }}>
        <div className="card-description" style={{ position: "relative" }}>
          <div
            className="compareColumn-content"
            style={{
              paddingLeft: "10px",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
          >
            <LiveIndic boundsChanging={false} />
            <span id="streetParkingTime_inCard" className="detailsPanelTime">
              &nbsp; à {props.info.timeToReachParking}
            </span>
            <span style={{ fontSize: "9px" }}>min</span>
          </div>
        </div>
        <div className="grid-container-parking-card">
          <div className="grid-item-parking-card">
            <span className="card-time">1h</span>
          </div>
          <div className="grid-item-parking-card">
            <span className="euroPrice detailsPanelText">
              {props.info.price_1_h ? props.info.price_1_h : "-"}€
            </span>
          </div>
          <div className="grid-item-parking-card">
            <span className="card-time">3h</span>
          </div>
          <div className="grid-item-parking-card">
            <span className="euroPrice detailsPanelText">
              {props.info.price_3_h ? props.info.price_3_h : "-"}€
            </span>
          </div>
          <div className="grid-item-parking-card">
            <span className="card-time">24h</span>
          </div>
          <div className="grid-item-parking-card">
            <span className="euroPrice detailsPanelText">
              {props.info.price_24_h ? props.info.price_24_h : "-"}€
            </span>
          </div>
        </div>
        <div style={{ height: "10px" }}></div>
      </div>
    </div>
  );
}

export default ParkingCard;
