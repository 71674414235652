import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//COMPONENTS
import HistoryItem from './HistoryItem';

//REDUX SELECTOR
import { userUidSelector } from '../global/state/userUidSelector.js';

//REDUX ACTIONS
import { toggleUserMenu } from '../userMenu/state/displayUserMenuAction .js'
import { toggleSectionDrawer } from '../navBar/state/navBarAction';

//STYLE
import '../global/Global.css';
import './History.css'

//UTILS
import { getHistory } from '../api/userApi'

function History() {


    const dispatch = useDispatch()

    const [parkingHist, setParkingHist] = useState([])

    const userUid = useSelector(userUidSelector)

    function displayUserMenuOnRight(){
        dispatch(toggleSectionDrawer("history", false))
        dispatch(toggleUserMenu(true))
    }

    async function fetchingHistory(uid){
        let resp = await getHistory(uid)
        let response = JSON.parse(resp)
        setParkingHist(response.parkingHist)
    }

    useEffect(() => {
        //if userUid exist fetch last history
        if(userUid){
            fetchingHistory(userUid)
        }
    }, [])


    return (
        <div style={{backgroundColor: "transparent", width: "100vw", height: "100vh"}}>
            <div className="flex-align-top-inline" style={{backgroundColor: "white"}}>
                <h3 className="title">Mes stationnements</h3>
            </div>
            {!userUid ?
                <div className="flex-center-column" style={{height: "80%"}}>
                    <p style={{textAlign: "center", padding: "20px"}}>Pour accéder à votre historique, il vous faut entrer vos identifiants de coconnexion</p>
                    <button className="green-action-button" style={{marginTop: "10px"}} onClick={()=> displayUserMenuOnRight()}>
                        <span className="green-action-button-text">Login</span>
                    </button>
                </div>
            :
            <div className="all-items-history">
                {parkingHist.map((item, index)=>{
                    return (
                        <HistoryItem 
                            key={index}
                            start={item.start}
                            end={item.end}
                            address={item.address}
                            ttp={item.ttp}
                            ptype={item.ptype}
                            pcompany={item.pcompany}
                            pname={item.pname}
                        />
                    )
                })}
            </div>
            }
        </div>
    )
}

export default History




