import React from "react";
import { useDispatch, useSelector } from "react-redux";

//REDUX SELECTOR
import { trackingIdAndAnonymousLocalSelector } from '../userTracking/state/trackingIdAndAnonymousLocalSelector';
import { trackingSessionIdSelector } from '../userTracking/state/trackingSessionIdSelector'

//REDUX ACTION
import { showNavMenu } from "./state/showNavMenuAction";

//UTILS
import { addActionToDbBasedOnElement } from '../global/UserTrackingUtils'

//STYLE
import * as HiIcon from 'react-icons/hi';
import './NavMenu.css';

const NavMenuIcon = () => {

    const dispatch = useDispatch()

    const trackingIdAndAnonymousLocal = useSelector( trackingIdAndAnonymousLocalSelector)
    const trackingSessionId = useSelector(trackingSessionIdSelector)

    return(
        <div className='navbar'>
            <HiIcon.HiMenu 
                onClick={()=>{dispatch(showNavMenu()); addActionToDbBasedOnElement("openNav", trackingIdAndAnonymousLocal, trackingSessionId)}} 
                style={{color: "black", marginLeft: "10px", marginRight: "5px"}} 
                className='menu-bars flex-center-inline'/>
        </div>
    )
}

export default NavMenuIcon;