import React from "react";
import { useSelector } from 'react-redux'

//COMPONENTS
import LiveIndic from "./LiveIndic";
import PillBadge from "../global/PillBadge";

//REDUX SELECTORS
import { aroundMeModeSelector } from "../global/state/aroundMeModeSelector";
import { firstClosestParkingInfoSelector } from './state/firstClosestParkingInfoSelector'
import { streetParkingInfoSelector } from './state/streetParkingInfoSelector'
import { lvzInfoSelector } from './state/lvzInfoSelector'

//UTILS
import * as global from '../global/globalVariable'

//STYLE
import './OriginalDetailsPanel.css'
import logo from '../images/logo.png'

function OriginalDetailsPanel() {
    
    const aroundMeMode = useSelector(aroundMeModeSelector)
    const firstClosestParkingInfo = useSelector(firstClosestParkingInfoSelector)
    const streetParkingInfo = useSelector(streetParkingInfoSelector)
    const lvzInfo = useSelector(lvzInfoSelector)

    let positionClassName

    // to update the picto next to "se garer ici" when aroundMeModeActive is changing
    if(aroundMeMode){
        positionClassName = "infoCirclePosition"
    }else {  
        positionClassName = "infoCirclePositionImg"
    }


    return (
        <table className="original-details-panel-table" style={{cellspacing: "0px"}}>
            <thead>
                <tr>
                    <th className="flex-flex-start-inline leftColumn titleLeftColumn">
                        Se garer ici <div className={positionClassName}></div>
                    </th>
                    <th className="green-title">dans la rue
                        {lvzInfo.lvzExists ?
                            <img src={logo} alt="Logo" style={{width: "16px", marginLeft: "5px"}}/> 
                        :null}
                    </th>
                    <th className="green-title">parking</th>
                </tr>
            </thead>
            <tbody className="table-body">
                <tr>
                    <th className="leftColumn">Accès</th>
                    <td>
                        <div className="flex-center-inline" style={{position: "relative", padding: "8px"}}>
                            <div className="detailsPanelText no-margin flex-center-inline" style={{position: "relative"}}>
                                <div>
                                    &nbsp;&nbsp;{lvzInfo.lvzExists ? lvzInfo.lvzEta: streetParkingInfo.streetParkingTime}
                                    <span className="detailsPanelTextMin">
                                        &nbsp;min
                                    </span>
                                </div>
                                {lvzInfo.lvzExists ?
                                    <div style={{marginLeft: "5px"}}>
                                        <PillBadge
                                            backgroundColor={global.lvzColors[lvzInfo.lvzAvailability].cssColorName}
                                            text={lvzInfo.lvzFreeSlots ? lvzInfo.lvzFreeSlots + " places" : "- place"}
                                        />
                                    </div>
                                :null}
                                <div style={{position: "absolute", top: "-10px", left: "-10px"}}>
                                    <LiveIndic />
                                </div>
                            </div> 
                        </div>
                    </td>
                    <td>
                        <div className="flex-center-inline" style={{position: "relative", padding: "8px"}}>
                            <div className="detailsPanelText no-margin" style={{position: "relative"}}>
                                &nbsp;&nbsp;{firstClosestParkingInfo.parkingTime}
                                <span className="detailsPanelTextMin">
                                    &nbsp;min
                                </span>
                                <div style={{position: "absolute", top: "-10px", left: "-10px"}}>
                                    <LiveIndic />
                                </div>
                            </div> 
                        </div>
                    </td>
                </tr>
                <tr>
                    <th className="leftColumn">Coûts (1h)</th>
                    <td>
                        <div className="euroPrice detailsPanelText" style={{ whiteSpace: "nowrap"}}>
                            {streetParkingInfo.streetParkingCost} €
                        </div>
                    </td>
                    <td>
                        <div className="euroPrice detailsPanelText" style={{ whiteSpace: "nowrap"}}>
                            {firstClosestParkingInfo.parkingCost} €
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default OriginalDetailsPanel
