import { handleResponse, transfromObjectToQueryString } from "./utilsApi"
import * as url from './routes'

/**
  * to get data of the closest parking based on a position
  * @param {object} position - object containing lat and lng properties
  */
export async function getClosestParking(position){
    let dataToSend = transfromObjectToQueryString({
        lat: position.lat,
        lng: position.lng,
    });
    var response = fetch(url.closestParking + dataToSend)
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(error => console.log(error))
    return response
};

/**
  * to retrieve data on on-street parking from a position
  * @param {object} position - object containing lat and lng properties
  */
export async function getLocationLiveInfo(position){
    let dataToSend = transfromObjectToQueryString({
        lat: position.lat,
        lng: position.lng,
    });
    var response = fetch(url.locationLiveInfo + dataToSend)
        .then(handleResponse)
        .then(data => {
            return data
        })
        .catch(error => console.log(error))
    return response
};